import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { lineFour, lineOne, lineThree, lineTwo, pending, message, rejected } from "./assets";
import React from "react";
import { toast } from "react-toastify";
interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string,
  token: string
}
interface Account {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: string | null;
  full_name: string;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  is_local_admin: boolean;
  id_proof: string;
  service_type: string;
  country: string;
  photo_information: string | null;
  language: string;
}
interface Attributes {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  app_url: string | null;
  status : string | null;
  notification_type : string | null;
  is_read: boolean;
  read_at: string | null;
  created_at: string;
  updated_at: string;
  account: Account;
}
interface Notification {
  id: string;
  type: string;
  attributes: Attributes;
}
export type Status = "Withdraw" | "Deposit";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  i18n?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  totalProfitPopup: boolean;
  activeTab: number;
  activeTabForMonth:number;
  isDialogOpen: boolean;
  anchorEl : HTMLElement | null;
  selectedNotificationId: string | null;
  data: Notification[];
  isReadAll:boolean;
  totalInvest:any;
  totalInvestGrap:any;
  totalProfit:any;
  totalProfitGraph:any;
  totalReturn:any;
  totalReturnGraph:any;
  avgReturn:any;
  avgReturnGraph:any;
  profitList: {label: string, value: string}[];
  investmentData: {
    name: string,
    percentage: any,
    total: string,
    image: any,
    color: string
  }[];
  profitListValue:string,
  profitListLabel:string,
  monthValue:string,
  monthLabel:string,
  graphWidth: number;
  totalInvestWidth: number;
  transactionsActivity: {
    date: string,
    type: string,
    amount: string,
    balance: string,
    amountColor: string,
  }[];
  assestActivity: {
    class: string,
    current: string,
    target: string,
    balance: string,
    currentColor: string,
    amountColor: string,
  }[];
  startDate: string;
  endDate:string;
  activeCircle: number,
  inputRef: any;
  activeData: {
    first: number
  };
  currentBalance:number;
  activityDetails:any;
  dashboardDetails:any;
  dashboardDetailsGraph:any;
  tempDate: any;
  rangeWeekly:string;
  calendarOpen:boolean;
  selectedDate: any;
  monthWeekOption:boolean;
  ratingList:{
    label: string;
    value: string;
  }[];
  calendarBoolean: boolean;
  buttonBox:boolean,
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllNotificationsCallId : string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  markAllAsReadId: string = "";
  getTotalInvestId: string = "";
  getTotalProfitId:string = "";
  getTotalReturnId:string = "";
  getAvgReturnId:string = "";
  getCurrentBalanceId:string='';
  getActivitesId:string='';
  getDashboardId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      graphWidth: 1200,
      totalInvestWidth:300,
      totalProfit:[],
      totalProfitGraph:[],
      totalReturn:[],
      totalReturnGraph:[],
      avgReturn:[],
      avgReturnGraph:[],
      errorMsg: "",
      token: "",
      loading: false,
      startDate: "",
      endDate:"",
      activeTab: 0,
      activeTabForMonth: 0,
      buttonBox:false,
      totalProfitPopup: false,
      isDialogOpen : false,
      anchorEl:null,
      selectedNotificationId: null,
      data: [],
      isReadAll:false,
      totalInvest:[],
      totalInvestGrap: [],
      calendarBoolean: false,
      profitList: [
        {
          label: "Total Profit",
          value: "total_profit"
        },
        {
          label: "Avg Profit",
          value: "avg_profit"
        },
        {
          label: "Total Returns",
          value: "total_returns"
        },
        {
          label: "Avg in Returns",
          value: "avg_in_returns"
        },
      ],
      profitListValue:"total_profit",
      profitListLabel:"Total Profit",
      monthValue:"monthly",
      monthLabel:"This Month",
      inputRef: React.createRef(),
      activeData: {
        first: 0,
      },
      investmentData: [
        {
          name: "Total Investments",
          percentage: "-18%",
          total: "$357,000",
          image:  [],
          color: "#EF4444"
        },
        {
          name: "Total Profits",
          percentage: "+2.5%",
          total: "$400,000",
          image: [],
          color: "#F59E0B"
        },
        {
          name: "Total Returns",
          percentage: "+25%",
          total: "$390,000",
          image: [],
          color: "#10B981"
        },
        {
          name: "Avg in Return",
          percentage: "+50%",
          total: "$399,000",
          image: [],
          color: "#1B4FE4"
        },
      ],
      activeCircle: 0,
      transactionsActivity: [
        {
          date: "Jan 04, 2024",
          type: "Withdraw",
          amount: "-15000",
          balance: "$64900",
          amountColor: "#EF4444",
        },
        {
          date: "Jan 05, 2024",
          type: "Deposit",
          amount: "-21000",
          balance: "$85900",
          amountColor: "#EF4444",
        },
        {
          date: "Jan 05, 2024",
          type: "Withdraw",
          amount: "+20000",
          balance: "$74900",
          amountColor: "#10B981",
        },
        {
          date: "Jan 05, 2024",
          type: "Deposit",
          amount: "+20000",
          balance: "$84900",
          amountColor: "#10B981",
        },
      ],
      assestActivity: [
        {
          class: "Lorem",
          current: "10%",
          target: "15%",
          balance: "$75000",
          currentColor: "#EF4444",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#F59E0B",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#10B981",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#EF4444",
          amountColor: "#EF4444",
        },
      ],
      ratingList: [
        {
          label: "Monthy",
          value: "monthly",
        },
        {
          label: "Weeky",
          value: "weekly",
        },
      ],
      currentBalance:0,
      activityDetails:[],
      dashboardDetails:[],
      dashboardDetailsGraph:[],
      rangeWeekly:"weekly",
      calendarOpen:false,
      tempDate: [],
      selectedDate: null,
      monthWeekOption:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getAllNotificationsData();
    this.getTotalInvest();
    this.getTotalProfit();
    this.getTotalReturn();
    this.getAverageReturn();
    this.getCurrentBalance();
    this.getActivitesDetails();
    this.getDashboardGraphData();
    this.getGraphInvest();
    this.getChartWidth();
    window.addEventListener('resize', this.getChartWidth);
    window.addEventListener('resize', this.getGraphInvest);
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
      this.handleResponseTwo(webApiRequestCallId, message)
      this.handleRsponseOne(webApiRequestCallId,message)
    }
    // Customizable Area End
  }
  // Customizable Area Start


  handleRsponseOne = (webApiRequestCallId:any,message:Message) =>{
    const resp = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(webApiRequestCallId == this.getTotalProfitId){
      this.handleTotalProfit(resp)
    }
    if(webApiRequestCallId === this.getTotalReturnId){
      this.handleTotalReturn(message)
    }
    if(webApiRequestCallId === this.getAvgReturnId){
      this.handleAvgReturn(message)
    }
    if (webApiRequestCallId === this.getCurrentBalanceId) {
      this.handleCurrentBalance(message)
    }
    if (webApiRequestCallId === this.getActivitesId) {
      this.handleActivityDetails(message)
    }
    if (webApiRequestCallId === this.getDashboardId) {
      this.handleDashboardData(message)
    }
  }
  handleResponseTwo = (webApiRequestCallId:any, message: Message) => {
    if(this.getAllNotificationsCallId == webApiRequestCallId){
      this.handleResForGetAllNotification(message)
    }
    if(this.markAsReadCallId == webApiRequestCallId){
      this.handleResForMarksAllRead( message)
    }
    if(this.deleteCallId == webApiRequestCallId){
      this.handleResForDeleteNotification( message)
    }
    if(this.markAllAsReadId == webApiRequestCallId){
      this.handleResForMarkAllAsRead(message)
    }
    if(this.getTotalInvestId == webApiRequestCallId){
      this.handleTotalInvestment(message)
    }
  }
  updateAmount = (percentage: any, name: string, amount: any, chartData: any) => {
    const updatedData = this.state.investmentData.map((item: any) =>
      this.handleCondition(item.name === name, {  ...item, percentage: percentage, total: amount ,image: chartData}, item)
    );
    this.setState({investmentData: updatedData});
  };

  getStatusIcon =(status: string | null)=>{
    if (status === "pending") {
          return pending;
        } else if (status === "rejected") {
          return rejected;
        } else {
          return message;
        }
  }

  right = () => {
    this.setState((prevState) => {
      const nextIndex = (prevState.activeData.first + 1) % prevState.investmentData.length;
      return { activeData: { first: nextIndex } };
    });
  };

  setActiveCircle(index: any) {
    this.setState({
      activeData: { first: index },
      activeCircle: index
    });
  }

  async getAllNotificationsData() {
    const token = await getStorageData("token");

    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNotificationsCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.notificationData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  apiCall = async (data: ApiCallData) => {
    const { contentType, token, method, endPoint, body, type } = data;
    const header = {
      token,
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async handleMarkAsRead() {
    const notification = this.state.selectedNotificationId;
    if (notification) {
      const token = await getStorageData("token");

      this.markAsReadCallId = await this.apiCall({
        token: token,
        method: webConfigJSON.markAsReadMethod,
        endPoint: `${webConfigJSON.notificationData}/${notification}`,
        contentType: webConfigJSON.dashboarContentType
      })
      this.handleOptionClose()
    }
  }

  async handleDeleteNotification() {
    const notification = this.state.selectedNotificationId;
    if (notification) {
      const token = await getStorageData("token");
      this.deleteCallId = await this.apiCall({
        token: token,
        method: webConfigJSON.deleteMethod,
        endPoint: `${webConfigJSON.notificationData}/${notification}`,
        contentType: webConfigJSON.dashboarContentType
      })
      this.handleOptionClose()
    }
  }

  async handleMarkAllAsRead() {
    const token = await getStorageData("token");
    const notificationIds = this.state.data?.map(item => item.id);
    const body = {
      "notification": {
        "notification_ids": notificationIds
      }
    }
    this.markAllAsReadId = await this.apiCall({
      token: token,
      method: webConfigJSON.markAsReadMethod,
      endPoint: `${webConfigJSON.notificationData}/mark_selected_as_read`,
      body: body,
      contentType: webConfigJSON.dashboarContentType,
    })
  }

  handleClick = () => {
    this.setState({ totalProfitPopup: !this.state.totalProfitPopup, calendarOpen: false, monthWeekOption: false })
  }
  handleScroll = () => {
    this.setState({ totalProfitPopup: false})
  }
  
  handleWeekOption = () => {
    this.setState({monthWeekOption: !this.state.monthWeekOption, calendarOpen: false})
  }

  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  handleOptionOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    this.setState({ anchorEl: event.currentTarget, selectedNotificationId: id });
  };

  handleOptionClose = () => {
    this.setState({ anchorEl: null, selectedNotificationId: null });
  };

  closeBox = () => {
    this.setState({ totalProfitPopup: false})
  }

  handleNavigation = (name: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), name);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  convertToNumber = (value: string | number) => {
    if (typeof value === 'number') {
      return value;
    }
    let cleanedStr = value.replace(/[^0-9.-]+/g, '');
    cleanedStr = cleanedStr.replace(/\.(?=.*\.)/g, '');
    cleanedStr = cleanedStr.replace(/(?!^)-/g, '');
    cleanedStr = cleanedStr.replace(/^\.*/g, '');
    if (cleanedStr.startsWith('-.') || cleanedStr.startsWith('.')) {
      cleanedStr = cleanedStr.replace(/^-\./, '-').replace(/^\./, '');
    }
    const numberValue = parseFloat(cleanedStr);
    return numberValue;
  };
  

  downloadFile = (fileUrl: string) => {
    const docFile = new XMLHttpRequest();
    docFile.open('GET', fileUrl, true);
    docFile.responseType = 'blob';
    docFile.onload = () => {
      if (docFile.status === 200) {
        const blob = docFile.response;
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = 'Fundsmen_document';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    docFile.send();
  };

  handleResForGetAllNotification = async ( message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.data) {
      this.setState({ data: responseJson.data })
      const isReadAll = responseJson.data.every((notification: { attributes: { is_read: boolean; }; }) => notification.attributes.is_read === true);
      this.setState({ isReadAll });
    }
  }

  handleTotalInvestment = async(message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      const response = responseJson?.total_investment?.user_investment_graph_data.data
      const formatedData = response.map((value:any)=>{
        return {
          amount: this.convertToNumber(value.attributes.total_investment),
          date:value.attributes.created_at, time: new Date(value.attributes.created_at).toLocaleTimeString()}
      })
      this.updateAmount(responseJson?.total_investment?.profit_percentage,
         "Total Investments",
          responseJson?.total_investment?.total_investment,
          formatedData
          )
      this.setState({totalInvest: formatedData, totalInvestGrap: responseJson.total_investment})
  }
  };

  handleTotalProfit = async(resp: any) => {
    if(resp){
      const response = resp?.total_profit?.total_profit_graph_data?.user_investment_graph_data?.data
      const formatedData = response.map((item : any) => {
        return {
          amount: this.convertToNumber(item.amount)
        }
      })
      this.updateAmount(resp?.total_profit?.total_profit_percentage, "Total Profits", resp?.total_profit?.total_profit, formatedData)
      this.setState({totalProfit: resp?.total_profit, totalProfitGraph: formatedData})
    }
  };

  handleTotalReturn = async(message: Message) => {
    const resp = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(resp){
      const response = resp?.total_return?.total_return_graph_data?.data
      const formatted = response.map((item:any) => {
        return {
          profit: this.convertToNumber(item.attributes.balance_after_profit)
        }
      })
      this.updateAmount(resp?.total_return?.total_return_percentage, "Total Returns", resp?.total_return?.total_return, formatted)
      this.setState({totalReturn: resp.total_return, totalReturnGraph: formatted})
    }
  };

  handleAvgReturn = async(message: Message) => {
    const resp = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(resp){
      const response = resp?.average_return?.average_return_graph_data?.user_investment_graph_data?.data
      const format = response.map((item: any) => {
        return{
          amount: this.convertToNumber(item.amount)
        }
      })
      this.updateAmount(resp?.average_return?.average_return_percentage, "Avg in Return", resp?.average_return?.average_return, format)
      this.setState({avgReturn: resp.average_return, avgReturnGraph: format})
    }
  }

  handleResForMarksAllRead = async ( message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.meta.message) {
      this.getAllNotificationsData()
    }
  }

  handleResForDeleteNotification = async (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.message) {
      this.getAllNotificationsData()
    }
  }

  handleResForMarkAllAsRead = async (message: Message) => {
    if (this.markAllAsReadId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.getAllNotificationsData();
        this.setState({isReadAll:true})
      }
    }
  }

  getChartWidth = () => {
    const screenWidth = window.innerWidth;
    if(screenWidth <= 900) {
      this.setState({graphWidth: screenWidth - 70})
    }else
    this.setState({graphWidth: screenWidth - 400})
  };

  getGraphInvest = () => {
    const screenWidth = window.innerWidth;
    let totalInvestWidth;
  
    switch (true) {
      case (screenWidth <= 815):
        totalInvestWidth = 140;
        break;
      case (screenWidth <= 900):
        totalInvestWidth = 170;
        break;
      case (screenWidth <= 960):
        totalInvestWidth = 70;
        break;
      case (screenWidth <= 1110):
        totalInvestWidth = 110;
        break;
      case (screenWidth <= 1260):
        totalInvestWidth = 140;
        break;
      case (screenWidth <= 1380):
        totalInvestWidth = 180;
        break;
      case (screenWidth <= 1500):
        totalInvestWidth = 210;
        break;
      case (screenWidth <= 1600):
        totalInvestWidth = 230;
        break;
      case (screenWidth <= 1730):
        totalInvestWidth = 260;
        break;
      case (screenWidth <= 1800):
        totalInvestWidth = screenWidth - 1500;
        break;
      default:
        totalInvestWidth = screenWidth - 1550;
    }
  
    this.setState({ totalInvestWidth });
  };

  checkVisibility = (item: Notification) => {
    const { notification_type, status } = item.attributes;
    return (notification_type === "contract" && (status === "pending" || status === "rejected")) || (notification_type === "withdraw_request");
  } 

  async getTotalInvest() {
    const token = await getStorageData("token");
    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const getResponseData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTotalInvestId = getResponseData.messageId;
    getResponseData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.totalInvest
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getResponseData.id, getResponseData);
    return true;
  };

  async getTotalProfit() {
    const token = await getStorageData("token");
    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const getResponseData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTotalProfitId = getResponseData.messageId;
    getResponseData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.totalProfit
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getResponseData.id, getResponseData);
    return true;
  };

  async getTotalReturn() {
    const token = await getStorageData("token");
    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const getResponseData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTotalReturnId = getResponseData.messageId;
    getResponseData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.totalReturns
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getResponseData.id, getResponseData);
    return true;
  };

  async getAverageReturn() {
    const token = await getStorageData("token");
    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const getResponseData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAvgReturnId = getResponseData.messageId;
    getResponseData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.avarageReturns
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getResponseData.id, getResponseData);
    return true;
  };

  async getCurrentBalance() {
    const token = await getStorageData("token");
    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const getResponseData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrentBalanceId = getResponseData.messageId;
    getResponseData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.currentBalance
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getResponseData.id, getResponseData);
    return true;
  };

  navigateDashboard = () => this.navigateTo("Dashboard")

  handleCurrentBalance = async(message: Message) => {
    const resp = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(resp){
      this.setState({currentBalance: resp?.data?.attributes?.balance})
    }
  }

  async getActivitesDetails() {
    const token = await getStorageData("token");
    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const getResponseData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActivitesId = getResponseData.messageId;
    getResponseData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     this.handleCondition( this.state.startDate,
      `${webConfigJSON.activities}start_date=${this.state.startDate}&end_date=${this.state.endDate}`, webConfigJSON.activities)
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getResponseData.id, getResponseData);
    return true;
  };

  handleActivityDetails = async(message: Message) => {
    const resp = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(resp && !resp.error){   
      this.setState({activityDetails: resp.data}) 
    } else if (resp && resp.error) {
        toast.error(resp.error);
      }
  }

  stepColor = (status: unknown) => {
    const colors: {[key: string]: string} = {
      Deposit: '#10B981',
      Withdraw: '#EF4444',
      'fill form': '#1B4FE4',
      'not started': '#F1F5F9'
    }

    return colors[status as keyof typeof colors] || '#F1F5F9';
  }

  async getDashboardGraphData() {
    const token = await getStorageData("token");
    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const getResponseData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDashboardId = getResponseData.messageId;
    getResponseData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${webConfigJSON.dashboardGraph}&range=${this.state.rangeWeekly}&metric=${this.state.profitListValue}`
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    getResponseData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getResponseData.id, getResponseData);
    return true;
  };

  handleDashboardData = async(message: Message) => {
    const resp = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(resp){     
      if(resp?.user_investment_graph_data){
        const response = resp?.user_investment_graph_data?.data 
        const format = response.map((item: any) => {
          return{
            amountt: this.handleCondition(typeof item.amount === 'string', this.convertToNumber(item.amount), item.amount),
            amount: item.amount,
            create: item.created_at
          }
        })
        this.setState({dashboardDetails: format})
      }else if(resp.data){
        const response = resp?.data 
        const format = response.map((item: any) => {
          return{
            amountt: this.convertToNumber(item.attributes.balance_after_profit),
            amount: item.attributes.balance_after_profit,
            create: item.attributes.created_at
          }
        })
        this.setState({dashboardDetails: format})
      }
      
    }
  }

  handleCalendarCancelBtn = () => {
    this.setState({ tempDate: [], calendarOpen: false });
  };
 

  handleProfitListMenu =(index:number,value:string,label:string)=>{
    this.setState({ activeTab: index });
    this.setState({profitListValue: value,profitListLabel:label}, this.getDashboardGraphData);
    this.setState({totalProfitPopup: false})
  }

  handleMonthly = () => this.setState({rangeWeekly: "monthly"}, this.getDashboardGraphData);
  handleWeekly = () => this.setState({rangeWeekly: "weekly"}, this.getDashboardGraphData);

  openCalendar = () => {
    this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen, totalProfitPopup: false, monthWeekOption: false }));
  };

  handleDateChange = (newDate:any) => {
    this.setState({ tempDate: newDate, calendarBoolean: !this.state.calendarBoolean });
  };

  handleCalendarSaveBtn = () => {
    const tempDate = this.state.tempDate;
    let startDate, endDate;
    if (tempDate instanceof Date) {
      startDate = tempDate;
      endDate = tempDate;
    } else if (Array.isArray(tempDate)) {
      [startDate, endDate] = tempDate;
    }
  
    const formattedStartDate = this.handleCondition(startDate , new Date(startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }) , this.state.startDate);
  
    const formattedEndDate = this.handleCondition(endDate , new Date(endDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }) , formattedStartDate || this.state.startDate);
  
    this.setState({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      tempDate: null,
      calendarOpen: false
    }, () => {
      this.getActivitesDetails()
    });
  };
  
  
  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };

  handleMonth =(index:number,value:string,label:string)=>{
    this.setState({activeTabForMonth: index });
    this.setState({rangeWeekly: value}, this.getDashboardGraphData);
    this.setState({monthWeekOption: false})
  }

  handleButtonBox = () => {
    this.setState({buttonBox: !this.state.buttonBox})
  }

  navigateTo = (endpoint: string) => {
    const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
    goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
    goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContractForm);
  }

   withdrawPage = () => this.navigateTo("WithdrawRequest")

   depositPage = () => this.navigateTo("DepositRequest")

  // Customizable Area End
}
