import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Popover,
  styled,
  createTheme
} from "@material-ui/core";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { message,downloadIcon, optionIcon, rejected, pending, deleteIcon, markAsRead } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        <Box className="navigationBar" >
          <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
        </Box>
        <Box className="container">
          <Box className="notificationHeader">
            <Typography className="headerText">Notification</Typography>
          </Box>
          {
            this.state.data.map((item, itemIndex) => (              
              <Box key={itemIndex} className="notificationTab">
                <Box
                  className="notificationContainer"
                  style={{ backgroundColor: item.attributes.is_read ? "#FFFFFF" : "#EFF6FF", }}
                >
                  <img
                    src={item.attributes.status == "pending" ? pending : item.attributes.status == "rejected" ? rejected : message}
                    style={{ height: "24px", width: "24px" }}
                    alt="message-icon"
                  />
                  <Typography className="notificationContent" >
                    {item.attributes.contents}
                    <Typography className="timerContent" >{item.attributes.created_at}</Typography>
                  </Typography>
                  <Typography data-test-id="downloadBtn" className={`downloadBtn ${this.checkVisibility(item) ? "indicator" : ""}`} onClick={() => this.downloadFile(`${item.attributes.app_url}`)} >
                    <img src={downloadIcon} alt="download-icon" />
                    <Typography className="downloadText" >Download</Typography>
                  </Typography>
                  <img data-test-id="moreBtn" src={optionIcon} alt="options-icon" onClick={(event) => this.handleOptionOpen(event, item.id)} />
                </Box>
                <Popover
                  id="simple-popover"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleOptionClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: webStyle.optionMenu,
                  }}
                >
                  <Box style={webStyle.optionMarkAsRead}>
                    <img src={markAsRead} alt="markAsReadIcon" style={{ height: "24px", width: "24px" }} />
                    <Typography data-test-id="markAsRead" onClick={() => this.handleMarkAsRead()} style={{ cursor: "pointer" }} >Mark as read</Typography>
                  </Box>
                  <Box style={webStyle.optionDelete}>
                    <img src={deleteIcon} alt="deleteIcon" style={{ height: "24px", width: "24px" }} />
                    <Typography data-test-id="delete" style={{ cursor: "pointer" }} onClick={() => this.handleDeleteNotification()} >Delete</Typography>
                  </Box>
                </Popover>
              </Box>
            ))
          }
        </Box>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    height:"100vh",
    display: "flex",
    overflow: "hidden",
  "& .container": {
    [theme.breakpoints.down(900)]: {
     paddingTop:"10px",
     paddingLeft:"0"
    },
    width: "100%",
    margin: "0 auto",
    padding: "25px",
    backgroundColor: "#F8FAFC",
    overflowY: "scroll",
    flex:"1",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    scrollbarWidth:"none"
  },
  "& .notificationHeader": {
    paddingBottom: "20px",
  },
  "& .headerText": {
    [theme.breakpoints.down(900)]: {
      fontSize:"18px"
     },
    fontWeight: "400",
    fontSize: "30px",
    color: "#334155",
  },
  "& .notificationTab": {
    width: "100%",
  },
  "& .notificationContainer": {
    [theme.breakpoints.down(600)]: {
      padding: "18px 30px",
      gap: "20px",
     },
     [theme.breakpoints.down(485)]: {
      padding: "18px 10px",
      gap: "15px",
      height:"60px"
     },
     [theme.breakpoints.down(355)]: {
      padding: "20px 5px",
      gap: "12px",
      height:"50px"
     },
    height: "80px",
    display: "flex",
    alignItems: "center",
    padding: "18px 40px",
    gap: "24px",
    borderBottom: "1px solid #CBD5E1",
    backgroundColor: "#EFF6FF",
  },
  "& .notificationContent": {
    [theme.breakpoints.down(450)]: {
      fontSize: "12px",
     },
     [theme.breakpoints.down(380)]: {
      fontSize:"11px"
     },
    flex: 1,
    fontSize: "14px",
    fontWeight: "400",
    color: "#0F172A",
  },
  "& .timerContent": {
    [theme.breakpoints.down(400)]: {
      fontSize:"10px"
     },
    fontWeight: "400",
    fontSize: "12px",
    color: "#64748B"
  },
  "& .downloadText":{
    [theme.breakpoints.down(600)]: {
      display:"none"
     },
  },
  "& .downloadBtn": {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B4FE4",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer"
  },
  "& .indicator":{
    visibility:"hidden"
  }
})
const webStyle ={
  optionMenu: {
    height: "96px",
    width: "178px",
    border: "1px solid #F5F5F5",
    boxShadow: "none",
    borderRadius: "8px",
    background: "#FFFFFF"
  },
  optionMarkAsRead: {
    display: "flex",
    height: "48px",
    alignItems: "center",
    gap: "8px",
    paddingLeft: "16px",
    fontWeight: "400",
    fontSize: "16px",
    color: "#F8FAFC",
    backgroundColor: "#1B4FE4"
  },
  optionDelete: {
    display: "flex",
    height: "48px",
    alignItems: "center",
    gap: "8px",
    paddingLeft: "16px",
    fontWeight: "400",
    fontSize: "16px"
  }
}

// Customizable Area End
