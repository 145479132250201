import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  createTheme,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Popover,
  ClickAwayListener,
} from "@material-ui/core";
import {Area, AreaChart, Tooltip, ResponsiveContainer} from "recharts";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import {
  PlusIcon,
  bellIcon,
  bell,
  arrow,
  drop,
  activity,
  calendar,
  plusButton,
  threeDots,
  downloadIcon,
  optionIcon,
  markAsRead,
  deleteIcon
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { withTranslation } from "react-i18next";
import Calendar from "react-calendar";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  MainWrapper = styled(Box)({
    zIndex:-1,
    position:"absolute",
    width: "calc(100vw - 358px)",
    [theme.breakpoints.up(900)]:{
      zIndex:0,
      position:"inherit",
    },
    "& .container": {
      [theme.breakpoints.down(900)]: {
        position: "absolute",
        top: "0",
        padding: "0",
        zIndex: 99,
        width: "calc(100vw - 0px)",
        height:"calc(100vh - 1px)",
      },
      width: "calc(100vw - 358px)",
      height:"calc(100vh - 4.5rem)",
      padding: "4rem 10px 10px 0rem",
      overflow:"scroll",
      "&::-webkit-scrollbar": {display: "none"},
    },
    "& .linkBox": {
      [theme.breakpoints.down(900)]: {},
    },
    "& .dashboardBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
      },
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      padding: "0 20px 0 40px",
      width: "calc(100% - 60px)",
      minWidth: "calc(100% - 60px)",
    },
    "& .dashboardStyle": {
      textAlign: "center",
      color: "#334155",
      fontSize: "30px",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .dashboardBox2": {
      [theme.breakpoints.down(900)]: {
          width: "100%"
        },
      [theme.breakpoints.down(380)]: {
        gap: "10px",
      },
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "20px",
      width: "fit-content",
    },
    "& .dashboardSearch": {
      fontSize: "16px",
      border: "none",
      color: "#94A3B8",
      fontFamily: "DIN Next LT Arabic Regular",
      width: "100%"
    },
    "& .requestButton": {
      [theme.breakpoints.down(900)]: {
        padding: "14px 8px",
      },
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      backgroundColor: "#1B4FE4",
      gap: "8px",
      padding: "12px 8px",
      borderRadius: "8px",
      flexWrap: "nowrap",
      cursor:"pointer"
    },
    "& .buttonOne": {
      [theme.breakpoints.down(1451)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(1370)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(1262)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(900)]: {
        fontSize: "12px",
      },
      cursor:"pointer",
      color: "#FFFFFF",
      fontWeight: 700,
      backgroundColor: "#1B4FE4",
      fontSize: "16px",
      border: "none",
      fontFamily: "DIN Next LT Arabic Bold",
      flexWrap: "nowrap",
    },
    "& .serachBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(30% - 16px)",
      },
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      border: "1px solid #64748B",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "10px 8px",
      borderRadius: "8px",
      gap: "8px",
      width: "calc(30% - 16px)",
    },
    "& .serchStyle": {
      width: "24px",
      height: "32px",
      cursor:"pointer"
    },
    "& .plusStyle": {
      [theme.breakpoints.down(1276)]: { width: "23px", height: "23px" },
      [theme.breakpoints.down(1217)]: { width: "20px", height: "20px" },
      [theme.breakpoints.down(1120)]: { width: "17px", height: "17px" },
      [theme.breakpoints.down(1021)]: { width: "15px", height: "15px" },
      [theme.breakpoints.down(900)]: {},
      cursor:"pointer"
    },
    "& .totalReviewBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
        gap: "6px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "48px 40px",
      height: "fit-content",
      gap: "24px",
      width: "calc(100% - 80px)",
      minWidth: "calc(100% - 80px)",
    },
    "& .totalReviewBoxforMobile": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "48px 40px",
      height: "fit-content",
      gap: "24px",
      width: "calc(100% - 80px)",
      minWidth: "calc(100% - 80px)",
    },
    "& .reviewBox": {
      [theme.breakpoints.down(900)]: {
        width: "170",
        height: "130px",
        gap: "8px",
        padding: "4px 8px",
      },
      width: "calc(25% - 48px)",
      height: "150px",
      padding: "16px 24px",
      gap: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "& .totalInvest": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .totalText": {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "DIN Next LT Arabic Light",
    },
    "& .totalPercent": {
      padding: "2px 8px",
      borderRadius: "40px",
      color: "#FFFFFF",
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .totalPercentInvest": {
      padding: "2px 8px",
      borderRadius: "40px",
      color: "#FFFFFF",      backgroundColor: "#EF4444",
      fontSize: "12px",      fontWeight: 500,
      textAlign: "center",
      display: "flex",
      alignItems: "center",      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .totalPercentProfit": {
      padding: "2px 8px",
      borderRadius: "40px",
      color: "#FFFFFF",
      backgroundColor: "#F59E0B",      fontSize: "12px",
      fontWeight: 500,
      textAlign: "center",
      display: "flex",      alignItems: "center",
      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .totalPercentReturn": {
      padding: "2px 8px",      borderRadius: "40px",      color: "#FFFFFF",
      backgroundColor: "#10B981",
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .totalPercentAvg": {
      padding: "2px 8px",      borderRadius: "40px",      color: "#FFFFFF",
      backgroundColor: "#1B4FE4",
      fontSize: "12px",      fontWeight: 500,     textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .totalNumber": {
      [theme.breakpoints.down(900)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(750)]: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "18px",
        width: "100%",
      },
      fontSize: "18px",
      width: "100%",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .reportsContainer": {
      [theme.breakpoints.down(900)]: {
      width: "calc(100vw - 75px)",
      },
      [theme.breakpoints.up(750)]: {
        borderTop: "1px solid  #CBD5E1",
        borderBottom: "1px solid #CBD5E1",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "calc(100% - 80px)",
      padding: "48px 40px",
      gap: "48px",
      flexDirection:"column",
    },
    "& .reportsBox": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "8px",
      width: "calc(60%- 20px)",
    },
    "& .report": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    "& .expensesText": {
      [theme.breakpoints.down(480)]: {},
      fontSize: "16px",
      color: "#1B4FE4",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
      cursor: "pointer",
    },
    "& .expensesButton": {
      color: "#475569",
      fontWeight: 400,
      fontFamily: "DIN Next LT Arabic Light",
      fontSize: "14px",
      borderRadius: "26px",
      border: " 1px solid #94A3B8",
      padding: "8px",
      width: "calc(100% - 22px)",
      backgroundColor:"#FFFFFF",
      cursor:"pointer"
    },
    "& .expensesButtonA": {
      color: "#475569",
      fontWeight: 400,
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "14px",
      borderRadius: "26px",
      border: " 1px solid #94A3B8",
      padding: "8px",
      width: "calc(100% - 22px)",
      backgroundColor:"#B6C9FF",
      cursor:"pointer"
    },
    "& .expensesButtonafter": {
      color: "#FFFFFF",
      fontWeight: 400,
      fontFamily: "DIN Next LT Arabic Light",
      fontSize: "14px",
      borderRadius: "26px",
      border: " 1px solid #94A3B8",
      padding: "8px",
      width: "calc(100% - 22px)",
      backgroundColor:"#1B4FE4"
    },
    "& .activityTable": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 20px)",
      },
      [theme.breakpoints.down(750)]: {
        flexDirection: "column",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "calc(100% - 30px)",
      minWidth: "calc(100% - 30px)",
      height: "fit-content",
      gap: "24px",
      padding: "48px 10px",
      borderBottom: "1px solid #CBD5E1",
    },
    "& .leftActivity": {
      [theme.breakpoints.down(750)]: {
        width: "calc(100% - 20px)",
        padding: "0 20px"
      },
      display: "flex",
      justifyContent: "space-between",
      gap: "24px",
      alignItems: "center",
      width: "calc(100% - 100px)",
      padding: "16px 40px",
      flexDirection: "column",
    },
    "& .activityText": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px",
      },
      width: "100%",
      fontSize: "18px",
      color: " #1E293B",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "   & .activityrightttext": {
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      fontSize: "18px",
      color: " #1B4FE4",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
      gap: "8px",
    },
    "& .rightActivity": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    "& .waweStyle": {
      [theme.breakpoints.down(900)]: {
        width: "10rem",
      },
      [theme.breakpoints.down(831)]: {
        width: "9rem",
      },
      width: "100%",
    },
    "& .activityBox": {
      [theme.breakpoints.down(480)]: {},
      width: "100%",
      gap: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .calendar": {
      [theme.breakpoints.down(480)]: {},
      fontWeight: 700,
      fontSize: "16px",
      color: "#1B4FE4",
      fontFamily: "DIN Next LT Arabic Bold",
      width: "80px",
      cursor:"pointer"

    },
    "& .reportss": {
      [theme.breakpoints.down(480)]: {},
      fontWeight: 700,
      fontSize: "18px",
      color: "#1E293B",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .headingBox": {
      [theme.breakpoints.down(480)]: {
        gap: "16px",
      },
      width: "100%",
      gap: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .headingStyle": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px",
      },
      fontSize: "14px",
      width: "100%",
      textAlign: "center",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .itemBox": {
      width: "100%",
      gap: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .itemData": {
      width: "100%",
      fontSize: "14px",
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .itemDataBal": {
      [theme.breakpoints.down(480)]: {},
      width: "100%",
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "center",
      borderRadius: "40px",
      color: "#fff",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .bellIcon": {
      [theme.breakpoints.down(900)]: {
        width: "1rem",
      },
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
      cursor:"pointer"
    },
    "& .Expenses": {
      [theme.breakpoints.down(900)]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      [theme.breakpoints.down(750)]: {
        gap: "18px",
      },
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "10px",
      width: "fit-content",
    },
    "& .expenseDrop": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
      width: "100%",
    },
    "& .dashBox": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      paddingBottom: "10px",
    },
    "& .ButtonPlus": {
      [theme.breakpoints.down(750)]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      cursor:"pointer"
    },
    "& .mobileSearch": {
      [theme.breakpoints.down(750)]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .circlePager": {
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      padding: "20px 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
      cursor:"pointer"
    },
    "& .circle": {
      [theme.breakpoints.down(750)]: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#E2E8F0",
      },
      cursor:"pointer"
    },
    "& .circle.active": {
      backgroundColor: "#1B4FE4",
    },
    "& .threeDots": {
      [theme.breakpoints.down(750)]: {
        width: "35px",
        height: "35px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      cursor:"pointer"
    },
    "& .statusBox": {
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "24px",
      width: "100%",
      textAlign: "center",
    },
    "& .calenderIcon": {
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      cursor:"pointer"
    },
    "& .acountIcon": {
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
    },
    "& .mobileReviewBox": {
      [theme.breakpoints.down(750)]: {},
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      gap: "16px",
    },
    "& .mobileTextBox": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    "& .mobileTextBoxNum": {
      [theme.breakpoints.down(750)]: {},
      borderRadius: "40px",
      textAlign: "center",
      width: "48px",
      height: "22px",
      padding: "2px 8px",
    },
    "& .mobileNum": {
      [theme.breakpoints.down(750)]: {},
      color: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .mobileAmount": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    "& .mobileImage": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      height: "23px",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    "& .profitContainer": {
      position: "fixed" as "fixed",
      top: "100%",
      width:"100%",
      right: 0,
      height: "100%",
      zIndex: 30,
      display: "flex",
      justifyContent: "center" as "center",
      alignItems: "flex-start",
    },
    "& .profitContainerButton": {
      [theme.breakpoints.up(750)]: {
        display: "none"
      },
      top: "6%",
      zIndex: 30,
      width: "250px",
      right:"25px"
    },
    "& .popupBox2": {
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      top: 0,
      boxShadow: "0px 2px 4px 0px #00000026",
    },
    "& .popupBox": {
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      top: 0,
      boxShadow: "0px 2px 4px 0px #00000026",
      whiteSpace:"nowrap",
      marginTop:"10%"
    },
    "& .popupBoxForWeek": {
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      top: 0,
      boxShadow: "0px 2px 4px 0px #00000026",
    },
    "& .profitData": {
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Light",
      padding: "12px 16px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#3C3E49",
      textTransform:"none"
    },
    "& .active": {
      backgroundColor: "#1B4FE4",
      color: "#F8FAFC",
      textTransform:"none"
    },
    "& .myCurrentBalance": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
      },
      display: "flex",
      gap:"24px",
      alignItems: "center",
      padding: "48px 40px",
    },
    "& .currentBal":{
      [theme.breakpoints.down(900)]:{
       fontSize:"18px",
      },
      textAlign:"center",
      width:"100%",
      fontSize:"30px",
      fontFamily: "DIN Next LT Arabic Light",
      },
    "& .balanceAmount":{
      [theme.breakpoints.down(900)]:{
       },
      padding:"16px 24px",
      alignItems:"center",
      width:"100%",
      fontSize:"18px",
      color:"#1E293B",
      backgroundColor:"#1B4FE4",
      fontFamily: "DIN Next LT Arabic Light",
      WebkitTextStroke:"1px #FFFFFF",
      WebkitTextFillColor:"transparent"
    },
    "& .weekOption":{
      position:"absolute",
        height: "100%",
        zIndex: 30,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        width: "100%",
        right:"25px",
        top:"100%",
        left:0,
        whiteSpace:"nowrap",
        marginTop: "130px"
      },
  });

  CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {  
      return (
        <div style={{height:"fit-content",backgroundColor:"#1B4FE4",
        top:0,paddingTop:"0px", padding:"16px 24px", borderRadius:"12px",
         display:"flex",alignItems:"center",justifyContent:"center",
          flexDirection:"column", gap:"1px"}}>
          <p style={{fontSize:"24px", color:"#FFFFFF",fontFamily: "DIN Next LT Arabic Regular"}}>{payload[0]?.payload?.amount}</p>
          <div>
          <div style={{ display: "inline-block", padding: 10 }}>
            <div style={{display:"flex", gap:"5px"}}>
                <div style={{color:"#FFFFFF", fontSize:"18px"}}>{payload[0]?.payload?.create}</div>
                </div>
              </div>
          </div>
        </div>
      );
    }
  
    return null;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { totalProfit, totalReturn, avgReturn, totalInvestGrap, avgReturnGraph, investmentData, activeData} = this.state
    const activeInvestment = investmentData[activeData.first];
    const chartData = activeInvestment.image || [];
    const dataKey =
    this.handleCondition(activeInvestment.name === "Total Returns", "profit", "amount");  
    return (
      <div style={{ display: "flex", width: "fit-content"}}>
        <Box style={webStyle.navigationMenu}>
          <NavigationMenu id="" navigation={this.props.navigation} i18n={this.props.i18n}></NavigationMenu>
        </Box>
        <this.MainWrapper>
          <Box className="container">
            <CustomDialogBox
              open={this.state.isDialogOpen}
              onClose={this.handleDialogClose}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
              PaperProps={{
                style:{
                  borderRadius:"0"
                }
              }}
            >
              <DialogContent style={{ padding: 0 }}>
                <Box className="notificationTab" >
                  <Box className="notificationHeader" >
                    <Typography className="headerText" >Notification</Typography>
                    <Typography className="headerTextMarkAsRead" data-test-id="handleMarkAllAsRead" onClick={() => this.handleMarkAllAsRead()} >Mark all as Read</Typography>
                  </Box>
                  {
                    this.state.data.slice(0, 5).map((item, itemIndex) => (
                      <Box className="notificationContainer" style={{
                        backgroundColor: this.handleCondition(item.attributes.is_read, "#FFFFFF", "#EFF6FF"),
                      }} key={itemIndex} >
                        <img src={this.getStatusIcon(item.attributes.status)} alt="message icon" style={{ height: "24px", width: "24px" }} data-test-id="statusIcon" />
                        <Box className="notificationContent">
                          <Typography className="contentText" >{item.attributes.contents}</Typography>
                          <Typography className="timerContent" >{item.attributes.created_at}</Typography>
                        </Box>
                        <Typography data-test-id="downloadBtn" className={`downloadBtn ${this.checkVisibility(item) ? "indicator" : ""}`} onClick={() => this.downloadFile(`${item.attributes.app_url}`)}>
                          <img src={downloadIcon} alt="download-icon" />
                          <Typography className="downloadText" >Download</Typography>
                        </Typography>
                        <img data-test-id="moreBtn" src={optionIcon} alt="options icon" onClick={(event) => this.handleOptionOpen(event, item.id)} />
                        <Popover
                          id="simple-popover"
                          anchorEl={this.state.anchorEl}
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleOptionClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          PaperProps={{
                            style: webStyle.optionMenu,
                          }}
                        >
                          <Box style={webStyle.optionMarkAsRead}>
                            <img src={markAsRead} alt="markAsReadIcon" style={{ height: "24px", width: "24px" }} />
                            <Typography data-test-id="markAsRead" onClick={() => this.handleMarkAsRead()} style={{ cursor: "pointer" }} >Mark as read</Typography>
                          </Box>
                          <Box style={webStyle.optionDelete}>
                            <img src={deleteIcon} alt="deleteIcon" style={{ height: "24px", width: "24px" }} />
                            <Typography data-test-id="delete" style={{ cursor: "pointer" }} onClick={() => this.handleDeleteNotification()} >Delete</Typography>
                          </Box>
                        </Popover>
                      </Box>
                    )
                    )}
                  <Box className="allNotificationContainer" >
                    <Box>
                      <Typography data-test-id="handleNavigation" onClick={() => this.handleNavigation('Notifications')} className="allNotifications" >See All Notifications</Typography>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
            </CustomDialogBox>
            
            <Box className="dashboardBox">
              <Box className="dashBox">
                <Typography className="dashboardStyle" onClick={this.getTotalInvest}>Dashboard</Typography>
              </Box>
              <Box className="dashboardBox2">
                {this.state.isReadAll ? 
                <img data-test-id="bellBtn" src={bellIcon} onClick={this.handleDialogOpen} className="bellIcon" /> : 
                <img data-test-id="bellBtn" src={bell} onClick={this.handleDialogOpen} className="bellIcon" />}
                <div>
                <img src={plusButton} className="ButtonPlus" data-test-id={"closeBox"} onClick={this.handleButtonBox} style={{position: "relative"}}/>
                {this.handleCondition(this.state.buttonBox, (
                          <Box
                            className="profitContainerButton"
                            style={{ position: "absolute" }}
                          >
                            <Box className="popupBox2">
                            <Button
                              data-test-id="profitList0"
                              key="0"
                              className={`profitData ${
                                this.handleCondition(this.state.activeTab === 0, 'active', 'profitData')
                              }`}
                              onClick={this.withdrawPage}
                              
                            >
                              New Withdraw Request
                            </Button>

                            <Button
                              data-test-id="profitList1"
                              key="1"
                              className={`profitData ${
                                this.handleCondition(this.state.activeTab === 1, 'active', 'profitData')
                              }`}
                              onClick={this.depositPage}
                            >
                              New Deposit Request
                            </Button>
                            </Box>
                          </Box>
                        ), "")}
                </div>
                <Box className="requestButton" onClick={this.withdrawPage}>
                  <img src={PlusIcon} className="plusStyle" />
                  <button className="buttonOne">New Withdraw Request</button>
                </Box>
                <Box className="requestButton" onClick={this.depositPage}>
                  <img src={PlusIcon} className="plusStyle" />
                  <button className="buttonOne">New Deposit Request</button>
                </Box>
              </Box>
            </Box>
            <Box className="myCurrentBalance">
                <Typography className="currentBal">My Current balance </Typography>
                <Button className="balanceAmount">{this.state.currentBalance}</Button>
            </Box>
            {window.innerWidth < 750 ? <Box className="totalReviewBoxforMobile">
                <Box className="mobileReviewBox">
                  <Box className="mobileTextBox">
                    <Typography className="totalText">
                      {
                        this.state.investmentData[this.state.activeData.first]
                          .name
                      }
                    </Typography>
                    <Box className="mobileTextBoxNum" style={{backgroundColor: this.state.investmentData[this.state.activeData.first].color}}>
                      <Typography className="totalPercent" style={{backgroundColor: this.state.investmentData[this.state.activeData.first].color}}>
                        {
                         this.state.investmentData[this.state.activeData.first]
                            .percentage
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Typography className="totalNumber">
                    {
                      this.state.investmentData[this.state.activeData.first]
                        .total
                    }
                  </Typography>
                  <AreaChart
                          width={this.handleCondition(window.innerWidth < 750, window.innerWidth - 78, this.state.totalInvestWidth)}
                          height={40}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                          data={chartData}
                        >
        <defs>
          <linearGradient id="colorUt" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={this.state.investmentData[this.state.activeData.first].color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={this.state.investmentData[this.state.activeData.first].color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey={dataKey} strokeWidth={2} stroke={this.state.investmentData[this.state.activeData.first].color} fill="url(#colorUt)" />
      </AreaChart>
                </Box>
              </Box> : <Box className="totalReviewBox">
                    <Box className="reviewBox" >
                      <Box className="totalInvest">
                        <Typography className="totalText">
                          Total Investments
                        </Typography>
                        <Box
                          className="totalPercentInvest"
                        >
                          {totalInvestGrap.profit_percentage}
                        </Box>
                      </Box>
                      <Typography className="totalNumber">
                        {totalInvestGrap.total_investment}
                      </Typography>
                      <AreaChart
                      width={this.state.totalInvestWidth}
                      height={40}
                      margin={{top: 10, right: 0, left: 0, bottom: 0}}
                      data={this.state.totalInvest}
                      >
                    <defs>
                         <linearGradient id="colorUx" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#EF4444" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#EF4444" stopOpacity={0}/>
                           </linearGradient>
                   </defs>
                  <Area
                   type="monotone"
                    dataKey="amount" strokeWidth={2}   stroke="#EF4444" fill="url(#colorUx)" />
                </AreaChart>
                    </Box>
                    <Box className="reviewBox" >
                      <Box className="totalInvest">
                        <Typography className="totalText">
                          Total Profit
                        </Typography>
                        <Box
                          className="totalPercentProfit"
                        >
                          {totalProfit.total_profit_percentage}
                        </Box>
                      </Box>
                      <Typography className="totalNumber">
                        {totalProfit.total_profit}
                      </Typography>
                      <AreaChart
                      width={this.state.totalInvestWidth}
                      height={40}
                      margin={{top: 10, right: 0, left: 0, bottom: 0}}
                      data={this.state.totalProfitGraph}
                      >
                    <defs> <linearGradient id="colorUz" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#F59E0B" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#F8FAFC" stopOpacity={0}/>
                             </linearGradient>
                    </defs>
                  <Area type="monotone" dataKey="amount" 
                  strokeWidth={2} stroke="#F59E0B" 
                  fill="url(#colorUz)" />
                </AreaChart>
                    </Box>
                    <Box className="reviewBox" >
                      <Box className="totalInvest">
                        <Typography className="totalText">
                          Total Return
                        </Typography>
                        <Box
                          className="totalPercentReturn"
                        >
                          {totalReturn.total_return_percentage}
                        </Box>
                      </Box>
                      <Typography className="totalNumber">
                        {totalReturn.total_return}
                      </Typography>
                      <AreaChart
                      width={this.state.totalInvestWidth}
                      height={40}
                      margin={{top: 10, right: 0, left: 0, bottom: 0}}
                      data={this.state.totalReturnGraph}
                      >
                       <defs>
                    <linearGradient id="colorUt" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#10B981" stopOpacity={0.8}/>
                       <stop offset="95%" stopColor="#C6FFEC" stopOpacity={0}/>
                    </linearGradient>
                         </defs>
                  <Area
                   type="monotone" 
                  dataKey="profit" strokeWidth={2} 
                  stroke="#10B981" fill="url(#colorUt)" />
                </AreaChart>
                    </Box>
                    <Box className="reviewBox" >
                      <Box className="totalInvest">
                        <Typography className="totalText">
                        Avg in Return
                        </Typography>
                        <Box
                          className="totalPercentAvg"
                        >
                          {avgReturn.average_return_percentage}
                        </Box>
                      </Box>
                      <Typography className="totalNumber">
                        {avgReturn.average_return}
                      </Typography>
                      <AreaChart
                      width={this.state.totalInvestWidth}
                      height={40}
                      margin={{top: 10, right: 0, left: 0, bottom: 0}}
                      data={avgReturnGraph}
                      >
                      <defs>
                        <linearGradient
                         id="colorUv" x1="0"
                          y1="0" x2="0"
                           y2="1">
                             <stop offset="5%" stopColor="#1B4FE4" stopOpacity={0.8}/>
                          <stop offset="95%"
                           stopColor="#8884d8" stopOpacity={0}/>
                         </linearGradient>
                </defs>
                  <Area type="monotone"   dataKey="amount" strokeWidth={2}   stroke="#1B4FE4" fill="url(#colorUv)" />
                </AreaChart>
                    </Box>
              </Box>}
            
            <Box className="circlePager">
                {this.state.investmentData.map((item, index) => (
                  <Box
                    key={index}
                    data-test-id = {`investmentData${index}`}
                    className={`circle ${
                      this.handleCondition(this.state.activeCircle === index, "active", "")
                    }`}
                    onClick={() => this.setActiveCircle(index)}
                  ></Box>
                ))}
              </Box>
              <Box className="reportsContainer">
              <Box style={{display:"flex", alignItems:"flex-start", justifyContent:"space-between", width:"100%"}}>
                <Box className="reportsBox">
                    <img src={arrow} />
                    <Typography className="reportss">Reports</Typography>
                </Box>
                <Box className="Expenses">
                  <ClickAwayListener onClickAway={this.handleScroll}>
                  <Box className="expenseDrop" style={{position: "relative"}} onClick={this.handleClick}>
                    <Typography className="expensesText">{this.state.profitListLabel}</Typography>
                    <img src={drop} alt="" />
                    {this.state.totalProfitPopup && (
              <Box className="profitContainer" style={{position: "absolute"}}>
                <Box className="popupBox">
                  {this.state.profitList.map((item, index) => (
                    <Button
                      data-test-id={`profitList${index}`}
                      key={index}
                      className={`profitData ${this.state.activeTab === index
                          ? "active"
                          : "profitData"
                        }`}
                      onClick={()=>this.handleProfitListMenu(index,item.value,item.label)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </Box>
              </Box>
            )}
                  </Box>
                  </ClickAwayListener>
                  <img src={threeDots} className="threeDots" onClick={this.handleWeekOption} data-test-id="weekOption"/>
                  <div style={{position:"relative"}} >
                  {this.handleCondition(this.state.monthWeekOption , (
                    <Box className="weekOption" style={{position: "absolute"}}>
                      <Box className="popupBoxForWeek">
                      {this.state.ratingList.map((item: {value: string; label: string}, index: number) => (
                                <Button
                                  data-test-id={`ratingList`}
                                  key={index}
                                  className={`profitData ${
                                    this.handleCondition(this.state.activeTabForMonth === index
                                      , "active"
                                      , "profitData")
                                  }`}
                                  onClick={()=>this.handleMonth(index,item.value,item.label)}
                                >
                                  {item.label}
                                </Button>
                              ))}
                        </Box>
                    </Box>
                  ), "")}
                  </div>
                  <Box className="statusBox">
                    <Typography
                      className={`expensesButton ${this.state.rangeWeekly === "monthly" ? "expensesButtonA" : ""}`}
                      onClick={this.handleMonthly}
                      >Monthly</Typography>
                    <Typography
                    className={`expensesButton ${this.state.rangeWeekly === "weekly" ? "expensesButtonA" : ""}`}
                     onClick={this.handleWeekly}>Weekly</Typography>
                  </Box>
                </Box>
                </Box>
                <AreaChart
                  width={this.state.graphWidth}
                  height={400}
                  data={this.state.dashboardDetails}
                  margin={{top: 10, right: 0, left: 0, bottom: 0}}
                  >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#1B4FE4" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                  <Tooltip content={<this.CustomTooltip tooltipData={this.state.dashboardDetails} />} />
                  <Area type="monotone" dataKey="amountt" strokeWidth={2} stroke="#1B4FE4" fill="url(#colorUv)" />
                </AreaChart>
            </Box>
            <Box className="activityTable">
                <Box className="leftActivity">
                  <Box className="activityBox">
                    <img src={activity} alt="/" className="acountIcon" />
                    <Typography className="activityText">
                      Account Activity
                    </Typography>
                    <img
                      src={calendar}
                      alt=""
                      className="calenderIcon"
                      onClick={this.openCalendar}
                    />
                    <Box className="activityrightttext">
                      <Typography className="calendar" onClick={this.openCalendar}>Calendar</Typography>
                      <img
                        src={calendar}
                        alt=""
                        onClick={this.openCalendar}
                        style={{cursor:"pointer"}}
                      />
                    </Box>
                  </Box>
                  <CustomDatePicker>
                           {this.handleCondition(this.state.calendarOpen, (
                            <div className="reactCalendarBox">
                            <Calendar
                              data-test-id="selectDate"
                              selectRange={this.state.calendarBoolean}
                              onChange={this.handleDateChange}
                              value={this.state.tempDate || this.state.selectedDate}
                            />
                            <div className="action-button-div">
                              <Button 
                                className="action-btn cancel-btn" 
                                onClick={this.handleCalendarCancelBtn}
                                data-test-id={"cancelCalendar"}
                              >
                              Cancel
                            </Button>
                            <Button 
                              value={this.state.tempDate || this.state.selectedDate}
                              className="action-btn save-btn active"
                              onClick={this.handleCalendarSaveBtn}
                              data-test-id={"DateSave"}
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      ), "")}
                        </CustomDatePicker>
                  <Box className="headingBox">
                    <Typography className="headingStyle">Date</Typography>
                    <Typography className="headingStyle">
                      Description
                    </Typography>
                    <Typography className="headingStyle">Amount</Typography>
                    <Typography className="headingStyle">Balance</Typography>
                  </Box>
                  {this.state.activityDetails.map(
                    (
                      item: {attributes: {user_investable_type: string; created_at: string; amount: string; total_investment: string}},
                      index: number
                    ) => {
                      const status = item?.attributes?.user_investable_type; 
                      const color = this.stepColor(status as unknown);
                    return (
                      <Box className="itemBox" key={index}>
                        <Typography className="itemData">
                          {new Date(item?.attributes?.created_at).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit'
                            })
                          }
                        </Typography>
                        <Typography className="itemData">
                          {item?.attributes?.user_investable_type}
                        </Typography>
                        <Typography
                          className="itemData"
                          style={{color: `${color}`}}
                        >
                          {item?.attributes?.amount}
                        </Typography>
                        <Typography
                          className="itemDataBal"
                          style={{color, backgroundColor: `${color}30`}}
                        >
                          {item?.attributes?.total_investment}
                        </Typography>
                      </Box>
                    )
                    }
                  )}
                </Box>
              </Box>
          </Box>
        </this.MainWrapper>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  navigationMenu: {
    zIndex: 100,
    height: "fit-content",
    width: "fit-content",
  },
  optionMenu:{
    height:"96px",
    width: "178px",
    border: "1px solid #F5F5F5",
    boxShadow: "none",
    borderRadius:"8px",
    background: "#FFFFFF"
  },
  optionMarkAsRead:{
    display:"flex",
    height:"48px",
    alignItems:"center",
    gap:"8px",
    paddingLeft:"16px",
    fontWeight:"400",
    fontSize:"16px",
    color:"#F8FAFC",
    backgroundColor:"#1B4FE4"
  },
  optionDelete:{
    display:"flex",
    height:"48px",
    alignItems:"center",
    gap:"8px",
    paddingLeft:"16px",
    fontWeight:"400",
    fontSize:"16px"
  }
};

const CustomDatePicker = styled(Box)({
  borderRadius: '8px',
  position: 'relative' as 'relative',
  boxSizing: "border-box",
  "& .MuiInput-underline::after, .MuiInput-underline::before": {
    display: 'none'
  },
  "& .reactCalendarBox":{
    [theme.breakpoints.down(750)]: {
     },
    width: "100%",
    position: "fixed" as "fixed",
    top: "45px",
    height: "100%",
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    flexDirection:"column" as "column",
    alignItems: "flex-end",
    right: "25px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  "& .react-calendar": {
    border: "none",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#0F172A",
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button":{
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#94A3B8", 
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    color: '#0F172A',
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    textDecoration: 'none',
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    width: '50px',
    height: '50px',
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#F1F5F9',
    color: '#044352',
    borderRadius: '0px',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    background: '#F1F5F9', 
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    background: '#F1F5F9',
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    background: '#1B4FE4',
    color: '#F8FAFC',
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
},
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .action-button-div": {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    gap: '20px',
    backgroundColor: "#FFFFFF"
  },
  "& .action-button-div button": {
    width: '140px',
    height: '50px',
    borderRadius: '7.21px',
    fontFamily: "DIN Next LT Arabic Regular",
    textTransform: 'capitalize',
    fontSize: '14.42px',
  },
  "& .action-button-div .cancel-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
  "& .action-button-div .save-btn.active": {
    background: '#1B4FE4',
    color: '#FFFFFF'
  },
  "& .action-button-div .save-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
});

const CustomDialogBox = styled(Dialog)({
  [theme.breakpoints.down(1250)]: {
    margin: 0
  },
  marginLeft: "52rem",
  "& .notificationTab": {
    [theme.breakpoints.down(560)]: {
      width:"auto",
    },
    width: "492px",
  },
  "& .notificationHeader": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #DDE0E0",
    padding: "16px 24px 16px 24px"
  },
  "& .headerText": {
    fontWeight: "400",
    fontSize: "16px"
  },
  "& .headerTextMarkAsRead": {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B4FE4",
    cursor: "pointer"
  },
  "& .notificationContainer": {
    [theme.breakpoints.down(600)]: {
    height:"100px",
    padding: "18px 30px 18px 30px",
    },
    [theme.breakpoints.down(515)]: {
      padding: "18px 16px 18px 16px",
      gap:"20px"
    },
    [theme.breakpoints.down(435)]: {
      gap:"25px"
    },
    [theme.breakpoints.down(375)]: {
      gap:"18px"
    },
    height: "120px",
    backgroundColor: "#EFF6FF",
    display: "flex",
    alignItems: "center",
    padding: "18px 40px 18px 40px",
    gap: "24px",
    borderBottom: "1px solid #CBD5E1",
  },
  "& .notificationContent": {
    flex: "1",
    width: "185px",
    height: "auto",
  },
  "& .contentText":{
    [theme.breakpoints.down(515)]: {
      fontSize:"13px"
    },
    fontSize: "14px",
    fontWeight: "400",
    color: "#0F172A",
  },
  "& .timerContent": {
    fontWeight: "400",
    fontSize: "12px",
    color: "#64748B"
  },
  "& .downloadBtn": {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B4FE4",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer"
  },
  "& .indicator":{
    visibility : "hidden"
  },
  "& .downloadText":{
    [theme.breakpoints.down(600)]: {
      display:"none"
     },
  },
  "& .allNotificationContainer": {
    height: "56px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  "& .allNotifications": {
    color: "#1B4FE4",
    fontWeight: "400",
    fontSize: "16px",
    cursor: "pointer"
  },
})
export const DashboardWeb = withTranslation()(Dashboard)
// Customizable Area End
