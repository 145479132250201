Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.googleLoginApiEndPoint = "account_block/accounts/google_login";
exports.postHttpRequest = "POST";
exports.urlHeaderTypeJSON = "application/json";
exports.clientID = "948968019587-4dba526lu3sid77pbb25j3h4rdqi6l6k.apps.googleusercontent.com";
exports.placeHolderEmail = "Email";
exports.getUserDetailsApi = "account_block/accounts/get_profile"
exports.contentTypeApiGetUserProfile = "application/json"
exports.methodTypeApiGetUserProfile = "GET"
exports.userDetailsUpdateApi = "account_block/accounts/update_profile"
exports.apiUpdateUserType = "PUT"
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";
exports.contentTypeFormData = "multipart/form-data";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.countryList = [
  { value: "afghanistan",
   label: "Afghanistan" },
  { value: "albania",
   label: "Albania" },
  { value: "algeria",
  label: "Algeria" },
  { value: "andorra",
   label: "Andorra" },
  { value: "angola",
   labe: "Angola" },
  { 
   value: "antigua and barbuda", label: "Antigua and Barbuda", },
  { value: "argentina", label: "Argentina" },
  { value: "armenia", label: "Armenia", },
  {  value: "australia", label: "Australia"},
  { label: "Austria",
   value: "austria" },
  { label: "Azerbaijan", value: "azerbaijan" },
  { value: "bahamas", label: "Bahamas" },
  { value: "bahrain",
   label: "Bahrain" },
  { label: "Bangladesh",
   value: "bangladesh" },
  { value: "barbados", label: "Barbados" },
  { value: "belarus", label: "Belarus"
    },
  { label: "Belgium",
   value: "belgium" },
  { label: "Belize",
   value: "belize" },
  { value: "benin", label: "Benin" },
  { label: "Bhutan",
   value: "bhutan" },
  { value: "bolivia", label: "Bolivia", },
  { label: "Bosnia and Herzegovina",
   value: "bosnia and herzegovina" },
  { value: "botswana",label: "Botswana", },
  { label: "Brazil",
   value: "brazil" },
  {value: "brunei" , label: "Brunei",
   },
  { label: "Bulgaria",
   value: "bulgaria" },
  { label: "Burkina Faso", value: "burkina faso" },
  { label: "Burundi", value: "burundi" },
  { label: "Cabo Verde", value: "cabo verde" },
  { label: "Cambodia",
   value: "cambodia" },
  { label: "Cameroon", value: "cameroon" },
  { label: "Canada",
   value: "canada" },
  { label: "Central African Republic",
   value: "central african republic" },
  { label: "Chad", value: "chad" },
  { label: "Chile", value: "chile" },
  { label: "China", value: "china" },
  { label: "Colombia",
   value: "colombia" },
  { label: "Comoros",
   value: "comoros" },
  { value: "congo (congo-brazzaville)",
    label: "Congo (Congo-Brazzaville)" },
  {value: "costa rica",
   label: "Costa Rica",
    },
  { label: "Croatia",
   value: "croatia" },
  { value: "djibouti", 
  label: "Djibouti" },
  { value: "dominica", 
  label: "Dominica" },
  { value: "dominican republic", label: "Dominican Republic" },
  { value: "east timor (timor-leste)", label: "East Timor (Timor-Leste)" },
  { value: "ecuador", label: "Ecuador" },
  { value: "egypt",
   label: "Egypt" },
  { value: "el salvador", label: "El Salvador" },
  { value: "equatorial guinea", label: "Equatorial Guinea" },
  { value: "eritrea", label: "Eritrea" },
  { value: "estonia", label: "Estonia" },
  { value: 'eswatini (fmr. "swaziland")',
  label: 'Eswatini (fmr. "Swaziland")'},
  { label: "Cuba", value: "cuba" },

  { label: "Cyprus", 
  value: "cyprus" },
  { label: "Czech Republic",
   value: "czech republic" },
  { value: "democratic republic of the congo",
    label: "Democratic Republic of the Congo" },
  { value: "denmark" ,
     label: "Denmark",  },
  { value: "ethiopia" ,
    label: "Ethiopia" },
  { value: "fiji" ,
    label: "Fiji"},
  { value: "finland" ,
    label: "Finland" },
  { value: "france" ,
    label: "France"},
  { value: "gabon",
     label: "Gabon" },
  { value: "gambia",
    label: "Gambia" },
  { 
    value: "georgia",
    label: "Georgia"},

  { value: "germany", 
    label: "Germany"},
  { value: "ghana",
    label: "Ghana" },

  { value: "greece", label: "Greece", },
  { value: "grenada", label: "Grenada",  },
  { value: "guatemala", label: "Guatemala", },

  { value: "guinea",  label: "Guinea",  },
  { label: "Guinea-Bissau", value: "guinea-bissau" },
  { label: "Guyana",
   value: "guyana" },
  { label: "Kenya",
   value: "kenya" },

  { label: "Kiribati",
   value: "kiribati" },
  { label: "Kosovo", 
  value: "kosovo" },
  { label: "Kuwait", 
  value: "kuwait" },
  { label: "Kyrgyzstan",
   value: "kyrgyzstan" },

  { label: "Laos", 
  value: "laos" },
  { label: "Latvia", 
  value: "latvia" },
  { 
   value: "lebanon",
    label: "Lebanon" },
  { label: "Lesotho",
   value: "lesotho" },
  { label: "Liberia",
   value: "liberia" },

  { value: "haiti", label: "Haiti" },
  { 
    value: "honduras",
    label: "Honduras", 
 },

  {
    value: "hungary",
     label: "Hungary",
   },

  { value: "iceland",
    label: "Iceland" },
  { value: "india",
    label: "India",},

  { value: "indonesia",
    label: "Indonesia", },
  { value: "iran",
    label: "Iran",
   },
  {  value: "iraq", label: "Iraq", },
  { value: "ireland", label: "Ireland" },

  { value: "israel",  label: "Israel", },

  { label: "Italy", value: "italy" },
  { value: "ivory coast", label: "Ivory Coast" },
  { label: "Jamaica", value: "jamaica" },

  { label: "Japan", value: "japan" },
  {  value: "jordan", label: "Jordan" },
  { label: "Kazakhstan", value: "kazakhstan" },

  { label: "Libya", value: "libya" },
  { label: "Liechtenstein", value: "liechtenstein" },
  { value: "lithuania", label: "Lithuania" },

  { label: "Luxembourg", value: "luxembourg" },
  { value: "madagascar", label: "Madagascar" },

  { value: "malawi", label: "Malawi" },
  { label: "Malaysia", value: "malaysia" },
  { value: "maldives", label: "Maldives" },
  { label: "Mali", value: "mali" },
  { label: "Malta", value: "malta" },
  { label: "Marshall Islands",
   value: "marshall islands" },
  { label: "Mauritania", value: "mauritania" },

  { value: "mauritius", label: "Mauritius" },
  { label: "Mexico", value: "mexico" },
  { label: "Micronesia", value: "micronesia" },

  { 
   value: "moldova", label: "Moldova" },
  { label: "Monaco", value: "monaco" },
  { value: "mongolia", label: "Mongolia" },

  { label: "Montenegro",
   value: "montenegro" },
  { label: "Morocco", 
  value: "morocco" },

  { value: "mozambique", label: "Mozambique" },
  { value: "myanmar (formerly burma)", label: "Myanmar (formerly Burma)", },

  {  value: "namibia", label: "Namibia" },
  {  value: "nauru", label: "Nauru" },

  { value: "nepal",  label: "Nepal" },
  { value: "netherlands",  label: "Netherlands", },

  {  value: "new zealand", label: "New Zealand" },
  {  value: "nicaragua", label: "Nicaragua", },
  {  value: "niger", label: "Niger", },

  { label: "Nigeria", value: "nigeria" },
  {  value: "north korea", label: "North Korea", },

  { value: "north macedonia", label: "North Macedonia" },
  { value: "norway",  label: "Norway", },
  {  value: "oman", label: "Oman", },

  {  value: "pakistan", label: "Pakistan" },
  { value: "palau",  label: "Palau" },
  { value: "palestine state", label: "Palestine State" },

  {  value: "panama", label: "Panama" },
  { value: "papua new guinea", label: "Papua New Guinea" },
  { value: "paraguay",  label: "Paraguay" },

  { value: "peru", label: "Peru" },
  { value: "philippines",  label: "Philippines" },
  {  value: "poland", label: "Poland" },

  { label: "Portugal", value: "portugal" },
  {  value: "qatar", label: "Qatar" },

  {  value: "romania", label: "Romania" },
  {  value: "russia", label: "Russia", },

  { value: "rwanda", label: "Rwanda",},
  { value: "saint kitts and nevis",
    label: "Saint Kitts and Nevis",
   },
  { value: "saint lucia",
    label: "Saint Lucia",  },
  { value: "saint vincent and the grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "samoa", label: "Samoa" },
  { value: "san marino",
    label: "San Marino", },

  { value: "sao tome and principe",
    label: "Sao Tome and Principe", },
  { value: "saudi arabia",
    label: "Saudi Arabia",
   },
  { value: "senegal",
    label: "Senegal" },
  { value: "serbia",
    label: "Serbia", 
 },
 
  { value: "slovakia",
    label: "Slovakia" },
    { value: "seychelles",
    label: "Seychelles" }, { value: "sierra leone",
     label: "Sierra Leone" }, { value: "singapore",
     label: "Singapore" },
  { value: "slovenia",
    label: "Slovenia" },  { value: "solomon islands",
    label: "Solomon Islands" },
  { value: "somalia",
    label: "Somalia"}, {value: "south africa", 
    label: "South Africa"},

  { value: "south korea",
    label: "South Korea" },{ value: "south sudan",label: "South Sudan" },
  { value: "spain",
    label: "Spain" },  { value: "sri lanka",
    label: "Sri Lanka" },
  { value: "sudan",
    label: "Sudan" },
  { value: "suriname",
    label: "Suriname" },  { value: "sweden", 
     label: "Sweden" },
  {value: "switzerland",
     label: "Switzerland" },
  { value: "syria" ,
    label: "Syria" }, { value: "taiwan", 
    label: "Taiwan" },
  { value: "tajikistan",
    label: "Tajikistan"},
  { value: "tanzania",
   label: "Tanzania" },
  { value: "thailand",
   label: "Thailand" },
  { value: "togo",
   label: "Togo" },

  { value: "tonga",
   label: "Tonga" },
  {
    value: "trinidad and tobago", 
     label: "Trinidad and Tobago",
  },
  { value: "tunisia",
   label: "Tunisia" },

  { label: "Turkey",
   value: "turkey" },
  { value: "turkmenistan", label: "Turkmenistan", },
  { value: "tuvalu",  label: "Tuvalu", },

  { label: "Uganda",
   value: "uganda" },
  { label: "Ukraine",
   value: "ukraine" },
  { value: "united arab emirates", label: "United Arab Emirates", },
  { value: "united kingdom", label: "United Kingdom" },
  { value: "united states of america",
    label: "United States of America",
  },
  {  value: "uruguay", label: "Uruguay" },
  { value: "uzbekistan", label: "Uzbekistan",
    },
  { label: "Vanuatu", value: "vanuatu" },

  {  value: "vatican city", label: "Vatican City", },
  { label: "Venezuela", value: "venezuela" },

  {  value: "vietnam", label: "Vietnam", },
  {  value: "yemen" , label: "Yemen",},
  { value: "zambia",  label: "Zambia", },

  { value: "zimbabwe",  label: "Zimbabwe", },
];

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.signupApiEndPoint = "account_block/accounts"
// Customizable Area End
