import React, { useState } from 'react';
import { 
  makeStyles, 
  createStyles, 
  Theme,
  FormControl,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    formControl: {
      minWidth: 150,
      [theme.breakpoints.down(600)]: {
        minWidth: 100,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "16px",
      color: "#334155",
      lineHeight: '26px',
      // textAlign: 'center',
      borderRadius: '8px',
      borderColor: '#94A3B8',
      [theme.breakpoints.down(600)]: {
        fontSize: '12px'
      },
      "& .MuiOutlinedInput-input": {
        padding: '4px 0px 10px 10px',
        [theme.breakpoints.down(600)]: {
          padding: '4px 20px 7px 7px',
        },
      }
    },
    selectIcon: {
      right: 8,
      [theme.breakpoints.down(600)]: {
        width: '0.75em',
        height: '0.75em',
        right: 4,
        top: 9
      },
    },
    label: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "16px",
      color: "#334155",
      lineHeight: '26px',
      paddingBottom: '5px',
      [theme.breakpoints.down(600)]: {
        fontSize: '12px'
      },
    },
    menuItem: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "16px",
      color: "#334155",
      lineHeight: '26px',
      [theme.breakpoints.down(600)]: {
        fontSize: '12px'
      },
    },
  })
);

const sortOptions = [
  { value: 'newest', label: 'Most Recent' },
  { value: 'oldest', label: 'Oldest' }
];

export default function SortingAlDropdown({handleSorting}: {handleSorting: (valus: string) => void}) {
  const classes = useStyles();
  const [sortBy, setSortBy] = useState('newest');

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setSortBy(value);
    handleSorting(value)
  };

  const CustomExpandMoreIcon = (props: any) => (
    <ExpandMoreIcon {...props} className={`${props.className} ${classes.selectIcon}`} />
  );

  return (
    <div className={classes.container}>
      <Typography variant="body1" className={classes.label}>
        Sort by:
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={sortBy}
          onChange={handleChange}
          className={classes.select}
          IconComponent={CustomExpandMoreIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {sortOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
