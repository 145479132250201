import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { iconClose } from "./assets";
import { getStorageData} from "../../../framework/src/Utilities";
import { FormikErrors, FormikTouched } from "formik";
import { Typography } from "@material-ui/core";
import * as Yup from "yup";
interface CalValue {
    investmentAmount: string;
    currencyName: string;
    investmentDuration: string;
    selectPeriod: string;
  }
interface DataResponse {
    future_value:number
}  
type HandleCondition = <T>(condition: boolean, truePart: T, falsePart: T) => T;

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose?:any;
  i18n?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  type: string,
  weight: number,
  calculationMethod: string;
  selectedOption: number;
  userAge: number,
  results: object;
  heightInchesInput: number;
  heightFeetInput: number;
  heightCmInput: number
  calcType: string,
  unitType: string,
  calcValue: number,
  usUnitDetailsAge: string,
  gender: string,
  usUnitDetailsheightInFeet: string,
  usUnitDetailsgender: string,
  usUnitDetailsheightInInches: string,
  usUnitDetailsweightInPound: string,
  calcDetailsHandlerselectCell: string,
  metricUnitsAge: string,
  metricUnitsGenderValue: string,
  metricUnitsHeigthValue: string,
  metricUnitsWeigthValue: string,
  weightInPound: string,
  activity: string
  heightInCm: number,
  weightInKg: number,
  activeUnit: string,
  inputUnit: number,
  usCalorieErr: boolean,
  metricCalorieErr: boolean,
  calculatedValue: number,
  isEmptyData: boolean;
  isText: string;
  isUsResult: boolean;
  isMetricResult: string,
  showResultData: string,
  isCalorieResult: string,
  open:boolean,
  iconClose:string,
  selectWidth:number,
  selectRef: React.RefObject<HTMLSelectElement>;
  result:number|null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InteractivecalculatorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    calculatorApiCallId: string = "";
    getResultApiCallId: string ="";
    token: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: '',
      weight: 0,
      userAge: 14,
      calculationMethod: "usUnit",
      selectedOption: 1,
      results: {},
      heightInchesInput: 0,
      heightFeetInput: 0,
      heightCmInput: 0,
      calcType: "BMR",
      unitType: "metricUnits",
      calcValue: 1,
      usUnitDetailsAge: "25",
      gender: "male",
      usUnitDetailsheightInFeet: "5",
      usUnitDetailsgender: "male",
      usUnitDetailsheightInInches: "10",
      usUnitDetailsweightInPound: "110",
      weightInPound: "",
      calcDetailsHandlerselectCell: '',
      metricUnitsAge: "25",
      metricUnitsGenderValue: "male",
      metricUnitsHeigthValue: "180",
      metricUnitsWeigthValue: "65",
      activity: '',
      heightInCm: 180,
      weightInKg: 65,
      activeUnit: "Meter",
      inputUnit: 0,
      usCalorieErr: false,
      metricCalorieErr: false,
      calculatedValue: 0,
      isEmptyData: false,
      isText: '',
      isUsResult: false,
      isMetricResult: "",
      showResultData: "",
      isCalorieResult: "",
      open:true,
      iconClose:iconClose,
      selectWidth:0,
      selectRef:React.createRef<HTMLSelectElement>(),
      result:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
          if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            runEngine.debugLog("API Message Recived", message);
            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.calculatorApiCallId) {
                    this.postCalculatorApiSuccessCallBack(responseJson);
                }
                this.handleApiResponse(apiRequestCallId, responseJson);
            }
            else if (responseJson && responseJson.errors) {
                if (apiRequestCallId === this.calculatorApiCallId) {
                    this.postCalculatorApiFailureCallBack(responseJson);
                }
            }
           
        }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.calculateSelectWidth();
    window.addEventListener('resize', this.handleResize);
}

async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
}

handleResize = () => {
    this.calculateSelectWidth();
};

calculateSelectWidth = () => {
    const selectRef = this.state.selectRef.current;
    if (selectRef) {
        const width = selectRef.clientWidth;
        this.setState({ selectWidth: width });
    }
};
    calcDetailsHandler = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        this.setState({ usUnitDetailsAge: event.target.value, isEmptyData: false })
    }

    activityMetricUnitCall = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        this.setState({ activity: event.target.value, isEmptyData: false })
    }

    metricUnitsAgeCell = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        this.setState({ metricUnitsAge: event.target.value, isEmptyData: false })
    }

    metricUnitsGenderValueCell = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        this.setState({ metricUnitsGenderValue: event.target.value, isEmptyData: false })
    }

    metricUnitsHeigthValueCell = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        this.setState({ metricUnitsHeigthValue: event.target.value, isEmptyData: false })
    }

    metricUnitsWeigthValueCell = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        this.setState({ metricUnitsWeigthValue: event.target.value, isEmptyData: false })
    }

    clearData = () => {
        if (this.state.unitType === "metricUnits") {
            this.setState({
                metricUnitsAge: "",
                metricUnitsGenderValue: "male",
                metricUnitsHeigthValue: "",
                metricUnitsWeigthValue: "",

            });
        } else {
            this.setState({
                usUnitDetailsAge: "",
                usUnitDetailsheightInFeet: "",
                usUnitDetailsgender: "male",
                usUnitDetailsweightInPound: "",
                usUnitDetailsheightInInches: "",
            });
        }
        this.clearCalcValue();
    }


    calcTypeHandler = (calc: string) => {
        this.setState({
            calcType: calc,
            isEmptyData: false
        });
    }

    unitTypeHandler = (unit: string) => {
        this.setState({ unitType: unit, isEmptyData: false });
    }

    apiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        type?: string;
    }) => {
        let { contentType, method, endPoint, body } = valueData;
        const header = {
            "Content-Type": contentType,
            // token: this.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    postCalculatorApiSuccessCallBack = async (responseJson:{ 
        bmr : string,
        bmi:string,
        calorie:string
    } ) => {  
        this.setState({showResultData:responseJson.bmr,isMetricResult:responseJson.bmi,isCalorieResult:responseJson.calorie})
    };

    postCalculatorApiFailureCallBack = (errorReponse: {
        bmr?: null,
    }) => {
    };

    clearCalcValue = () => {
        this.setState({ calcValue: 240 })
    }

      getResultsData() {
    
        const headers = {
          "Content-Type": configJSON.validationApiContentType
        };
        const height: number | string = this.state.calculationMethod === 'usUnit' ? parseFloat(`${this.state.heightFeetInput}.${this.state.heightInchesInput}`) : this.state.heightCmInput
        const httpBody = {
          type: this.state.type,
          age: this.state.userAge,
          gender: this.state.gender,
          height: height,
          weight: this.state.weight,
          activity: this.state.activity,
          units: this.state.calculationMethod
        }
        const getResults = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.calculatorApiCallId = getResults.messageId;
    
        getResults.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getResultsAPiEndPoint
        );
    
        getResults.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        getResults.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        getResults.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getResultsApiMethod
        );
        runEngine.sendMessage(getResults.id, getResults);
      }
      handleClose=()=>{
        this.setState({ open: false,result:null });

      }

    handleCondition = (condition: any, truePart: any, falsePart: any) => {
        return condition ? truePart : falsePart;
      };
     
      handleFormValue = async(values:CalValue)=>{
        let token= await getStorageData("token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token":token
          };
          const select = values.selectPeriod === "شهر" ? "month" : "year"
        const httpBody={
            "amount" : values.investmentAmount,
            "duration" : values.investmentDuration,
            "period" : this.props.i18n.language === 'ar' ? select : values.selectPeriod
        }
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getResultApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.calculatorEndPoint
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getResultsApiMethod
          );
    
          runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      handleApiResponse(apiRequestCallId:string, responseJson:DataResponse) {
        if (apiRequestCallId === this.getResultApiCallId) {
            this.getCalculatorResponse(responseJson);
        }
    }
    getCalculatorResponse=(responseJson:DataResponse)=>{
        this.setState({result:responseJson.future_value})

    }
    clearResultState=()=>{
        this.setState({result:null})
    }
    
    userSchema = () => {
        return Yup.object().shape({
            investmentAmount : Yup.string().required("Please enter investment amount"),
            investmentDuration : Yup.string().required("Please enter investment duration"),
            selectPeriod  : Yup.string().required("Please enter investment period")
      
        });
      };

      userSchemaAr = () => {
        return Yup.object().shape({
          investmentAmount: Yup.string().required("يرجى إدخال مبلغ الاستثمار"),
          investmentDuration: Yup.string().required("يرجى إدخال مدة الاستثمار"),
          selectPeriod: Yup.string().required("يرجى إدخال فترة الاستثمار"),
        });
      };
      
      getErrorMessage = (
        touched: FormikTouched<CalValue>,
        errors: FormikErrors<CalValue>,
        value: keyof CalValue
    ): React.ReactNode => {
        const hasError = Boolean(touched[value] && errors[value]);
        return this.handleCondition(
            hasError,
            <Typography style={{ 
                color: 'var(--Status-Red-600, #DC2626)',
                fontSize: '12px',
                fontStyle: 'normal', fontWeight: 400, fontFamily: 'DIN Next LT Arabic Regular', lineHeight: '18px',
                textAlign: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar', 'right', 'left')
            }}>
                {errors[value]}
            </Typography>,
            null 
        );
    }; 
  // Customizable Area End
}
