import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, imgLeftLogo, imgLogo, imgGoogleIcon } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import googleController from "../../social-media-account/src/GoogleWebController"
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: string;
  imgPasswordInVisible: string;
  imgLeftLogo:string
  imgLogo:string
  imgGoogleIcon:string 
  labelHeader: string;
  labelTitle: string;
  labelSignup:string;
  labelDontAcc:string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  EmailValidation:string;
  passwordChangeMsg:string;
  incomingAccessToken:string;
  loading:boolean;
  isRegistration:boolean;
  isLoggedIn:boolean;
  openDialog:boolean;
  showSuccessMessage:boolean;
  showSuccessMessageProfile:boolean;
  showSuccessMessageUpdate:boolean;
  showAccountNotActivated:boolean;
  showAlreadyRegisterMessage:boolean;
  googleClientId:string;
  selectWidth:number;
  selectRef: React.RefObject<HTMLButtonElement>;
  openCalculator:boolean;
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  createAccountAPICallId:string = "";
  loginApiCallId:string="";
  emailReg: RegExp;
  labelTitle: string = "";
  googleLoginRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      imgLeftLogo : imgLeftLogo,
      imgLogo : imgLogo,
      imgGoogleIcon : imgGoogleIcon,
      labelSignup : configJSON.labelSignup,
      labelDontAcc : configJSON.labelDontAcc,
      labelTitle: configJSON.labelTitle,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      EmailValidation:"",
      passwordChangeMsg:"",
      incomingAccessToken:"",
      loading: false,
      isRegistration:false,
      isLoggedIn:false,
      openDialog:false,
      showSuccessMessage:false,
      showSuccessMessageProfile:false,
      showSuccessMessageUpdate:false,
      showAccountNotActivated:false,
      showAlreadyRegisterMessage:false,
      googleClientId:configJSON.clientID,
      selectWidth:0,
      selectRef:React.createRef<HTMLButtonElement>(),
      openCalculator: false,
      errorMessage: ""
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.googleLoginRef = React.createRef();
    
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const rememberMeData = await getStorageData("rememberMe")
    const rememberMeEmail = await getStorageData("email")
    const rememberMePassword = await getStorageData("password")
    const rememberMeToken = await getStorageData("token")
    
    if (rememberMeData && !rememberMeToken) {
      this.setState({
        email: rememberMeEmail,
        password: rememberMePassword,
        checkedRememberMe: rememberMeData,
      })
    }
    if (rememberMeToken && rememberMeData) {
      this.setState({checkedRememberMe:true})
      const values = {
        email: rememberMeEmail,
        password: rememberMePassword
      };
      this.handleSiginClick(values)
    }
    this.calculateSelectWidth();
    window.addEventListener('resize', this.handleResize);
    // Customizable Area End
  }

  // Customizable Area Start

async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
}

handleResize = () => {
    this.calculateSelectWidth();
};

calculateSelectWidth = () => {
    const selectRef = this.state.selectRef.current;
    if (selectRef) {
        const width = selectRef.clientWidth;
        this.setState({ selectWidth: width });
    }
};
  handleCloseDialog=()=>{
    this.setState({openDialog:false})
  }
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.handleLogin,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };



  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

    
    handleSiginClick = (values: any) => {
      this.setState({email:values.email})
      this.setState({password:values.password})
      const formData = new FormData();
      formData.append("data[type]", "email_account");
      formData.append("data[attributes][email]", values.email);
      formData.append("data[attributes][password]", values.password);
  
      const header = {
        contentType: configJSON.contentTypeFormData,
      };
     
         const loginRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.loginApiCallId = loginRequestMessage.messageId;
      loginRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.SignInEndPoint
      );
  
      loginRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      loginRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      loginRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
  
      runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
      return true;
    };

    handleLogin=(responseJson:any)=>{
      if (responseJson?.meta) {
        setStorageData("token", responseJson.meta.token);
        if (responseJson?.meta?.first_time_login === true) {
          const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage), "QuestionBank");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message);
        }
        else{
          const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message);
        }
        if(this.state.checkedRememberMe){
          setStorageData("rememberMe",true)
          setStorageData("email",this.state.email)
          setStorageData("password",this.state.password)
        }else {
          removeStorageData("rememberMe");
          removeStorageData("email");
          removeStorageData("password");
        }
      } else if (responseJson?.errors) {
        
        this.setState({
          EmailValidation: responseJson.errors[0].failed_login,
        });
      }
    }

    handleSignupClick = ()=>{
      this.props.navigation.navigate("EmailAccountRegistration");
    }
    
    handleForgotPasswordClick = ()=>{
      this.props.navigation.navigate("ForgotPassword");
    }

    handleLoginSuccess = (response:any) => {
      this.googleLogIn(false,response.credential)
    };
  
    handleLoginFailure = () => {
      console.error("Login failed");
    };
  
  

    googleLogIn = (isRegistration: boolean, credential: string) => {
      const self = this;
  
      googleController.googleLogIn(this, credential)?.then(
        function() {
          self.stopLoading();
          runEngine.debugLog("User SIGNED IN.");
        },
        function(error: { error: string }) {
          self.stopLoading();
         
        }
      );
    };
    startLoading = () => {
      this.setState({ loading: true });
    };
  
    stopLoading = () => {
      this.setState({ loading: false });
    };
    googleUserStatusChanged(userInfo: {
      email: string;
      id: string;
      status?: string;
      credential:string;
    }): void {
        this.logInWithSocial(userInfo.email, userInfo.id,userInfo.id,userInfo.credential, this.props);
      
    }
    async logInWithSocial(
      incomingEmail: string,
      incomingPassword: string,
      incomingId: string,
      incomingToken:string,
      props: Props
    ) {
      if (
        !incomingEmail ||
        incomingEmail.length === 0
      
  
      ) {
        runEngine.debugLog("createAccountFromSocial empty info");
        return;
      }
  
      this.startLoading();
  
      const header = {
        "Content-Type": configJSON.urlHeaderTypeJSON
      };
  
      const attributes = {
        type: "google", 
        user_type: "test", 
        access_token : incomingToken,
        email : incomingEmail
      };
      this.setState({email:incomingEmail})
      const httpBodyData = {
        type: "social_account",
        attributes
      };
  
      const httpBody = {
        data: httpBodyData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAccountAPICallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.googleLoginApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postHttpRequest
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    dashboardPage(){
      const goToDashboard = new Message(getName(MessageEnum.NavigationMessage));
      goToDashboard.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
      goToDashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(goToDashboard);
      
  
    }
    saveLoggedInUser(responseJson: any) {
      if (responseJson && responseJson.meta && responseJson.meta.token && responseJson.data.attributes.activated) {
        setStorageData("email",this.state.email);
        setStorageData("token",responseJson.meta.token);
        this.dashboardPage();
        const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
  
        msg.addData(
          getName(MessageEnum.SessionResponseData),
          JSON.stringify(responseJson)
        );
        msg.addData(
          getName(MessageEnum.SessionResponseToken),
          responseJson.meta.token
        );
  
        this.send(msg);
      }else{
        this.setState({
          openDialog:true,
          showSuccessMessage:false,
          showSuccessMessageProfile:false,
          showSuccessMessageUpdate:false,
          showAlreadyRegisterMessage:false,
          showAccountNotActivated:true
      })
      }
    }
    GoogleLoginResponse = (message:any) => {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        if(responseJson.meta.first_time_login){
          this.SignUpNavProps(responseJson.meta.token)
        }else {
          this.saveLoggedInUser(responseJson);
        }
        
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } 
    }
    SignUpNavProps = (token:string) =>{
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'EmailAccountRegistration'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { token: token,message:"coming from login page"})
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
  
    }
    async receive1(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
        this.setState({
          passwordChangeMsg : sessionData.successMessage,
        });
        if(sessionData.Message=="Email account is not activated"){
          this.setState({
            openDialog:true,
            showSuccessMessage:false,
            showSuccessMessageProfile:false,
            showSuccessMessageUpdate:false,
            showAccountNotActivated:true,
            showAlreadyRegisterMessage:false
        })
        
        }
        if(sessionData.Message=="successfully updated"){
          this.setState({
            openDialog:true,
            showSuccessMessage:false,
            showSuccessMessageProfile:true,
            showSuccessMessageUpdate:false,
            showAccountNotActivated:false,
            showAlreadyRegisterMessage:false
        })
        
        }
        if(sessionData.Message=="Already registered"){
          this.setState({
            openDialog:true,
            showSuccessMessage:false,
            showSuccessMessageProfile:false,
            showSuccessMessageUpdate:false,
            showAccountNotActivated:false,
            showAlreadyRegisterMessage:true
        })
        
        }
      }
    }
  }
  handleNavigateToCalculator = () => {
    this.setState({openCalculator: !this.state.openCalculator})
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receive1(from, message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.loginApiCallId) {
          this.handleLogin(responseJson)
          this.setState({errorMessage: responseJson?.errors[0].failed_login})
        }
      }
    }
    this.stopLoading();

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createAccountAPICallId !== "" &&
      this.createAccountAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.GoogleLoginResponse(message)
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
