// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
const configJSON = require("./config.js");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  // Customizable Area Start
  activeData: {
    first: number;
  };
  selectedValue: string;
  successPopup: boolean;
  totalCheckPopup: boolean;
  questionArr: {
    id: number;
    type: string;
    attributes: {
      id: number;
      question_description: string;
      question_options: {
        data: {
          id: number;
          type: string;
          attributes: { id: number; option: string; question_bank_id: number };
        }[];
      };
    };
  }[];
  dataArray: {
    question_bank_id: number;
    question_option_id: number;
  }[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  questionBankListId: string = "";
  questionBankAllDataId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area Start
    this.state = {
      successPopup: false,
      totalCheckPopup: false,
      activeData: {
        first: 0,
      },
      selectedValue: "",
      questionArr: [],
      dataArray: [],
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (
      this.questionBankListId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apierror = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apierror) {
        Object.values(apierror.errors[0]).forEach((item: any) =>
          toast.error(item)
        );
        if (Object.keys(apierror.errors[0]).includes("token")) {
          const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "EmailAccountLoginBlock"
          );
          message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
          this.send(message);
        }
      } else {
        this.setState({ questionArr: apiResponse.data });
        this.setState({
          dataArray: apiResponse.data.map(
            (item: {
              attributes: { id: number; question_bank_id: number };
            }) => {
              return {
                question_bank_id: item.attributes.id,
                question_option_id: "",
              };
            }
          ),
        });
      }
    }
  }

  componentDidMount = async () => {
    this.questionBankList();
  };

  questionBankList = () => {
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const questionBankData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    questionBankData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.questionBankListId = questionBankData.messageId;
    questionBankData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionBankListEndPoint
    );
    questionBankData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(questionBankData.id, questionBankData);
  };

  questionBankPost = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const body = {
      attempt_question: {
        answers: this.state.dataArray,
      },
    };
    const questionBankAllData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    questionBankAllData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.questionBankAllDataId = questionBankAllData.messageId;
    questionBankAllData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionBankListPostAPiEndPoint
    );
    questionBankAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    questionBankAllData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ successPopup: true });
    runEngine.sendMessage(questionBankAllData.id, questionBankAllData);
  };

  handleNext = () => {
    const { activeData, questionArr } = this.state;
    if (activeData.first < questionArr.length - 1) {
      this.setState({
        activeData: { first: activeData.first + 1 },
      });
    }
  };
  handlePrevious = () => {
    const { activeData } = this.state;
    if (activeData.first > 0) {
      this.setState({
        activeData: { first: activeData.first - 1 },
      });
    }
  };
  handleSuccessPopup = () => {
    this.setState({ successPopup: false });
  };

  handleAllCheck = () => {
    this.setState({ totalCheckPopup: false });
  };

  handleOptionClick = (item: {
    attributes: { id: number; option: string; question_bank_id: number };
  }) => {
    this.setState({
      dataArray: this.state.dataArray.map((item1) =>
        item1.question_bank_id ===
        this.state.questionArr?.[this.state.activeData.first]?.attributes?.id
          ? { ...item1, question_option_id: Number(item?.attributes?.id) }
          : item1
      ),
    });
  };

  handleSelectOption = () => {
    const data =
      this.state.dataArray.every(
        (item) => String(item.question_option_id).length
      ) === true;
    if (data) {
      this.questionBankPost();
    } else {
      this.setState({ totalCheckPopup: true });
    }
    return data;
  };

  handleNavigate = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleFeature = () =>{
    this.setState({totalCheckPopup: true})
  }
}
// Customizable Area End
