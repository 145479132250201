import {
    Box,
    ThemeProvider,
    Typography,
    createTheme,
    styled,
  } from "@material-ui/core";
  import React, { Component } from "react";
import { checkIcon, crossIcon } from "./assets";
import FeedbackController, {Props} from "./FeedbackController";
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });
  
  
  export default class FeedbackPopup extends FeedbackController {
    constructor(Props: Props) {
      super(Props);
    }
    MainWrapper = styled(Box)(({ theme }) => ({
      "& .box": {
        [theme.breakpoints.down(900)]: {
          width: "45%"
        },
        [theme.breakpoints.down(598)]: {
          width: "80%"
        },
        [theme.breakpoints.down(321)] : {
          width:"76%"
        },
        width: "30%",
        height: "fit-content",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column" as "column",
        gap: "16px",
        padding: "20px 20px 40px 20px",
      },
    }));
    render() {
      return (
        <this.MainWrapper>
          <ThemeProvider theme={theme}>
            <Box style={webStyle.container}>
              <Box style={webStyle.box} className="box">
                <Box style={{...webStyle.box2, direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                  <img src={crossIcon}/>
                </Box>
                  <Box style={webStyle.textBox}>
                    <img src={checkIcon} />
                    <Typography style={webStyle.feedbackTitle}>
                      {this.props.i18n?.t('feedbackSubmitSuccess')}
                    </Typography>
                    <Typography style={webStyle.feedbackDescription}>
                      {this.props.i18n?.t('feedbackDetails')} 
                    </Typography>
                  </Box>
              </Box>
            </Box>
          </ThemeProvider>
        </this.MainWrapper>
      );
    }
  }
  
  const webStyle = {
    container: {
      width: "100%",
      height: "100%",
      position: "fixed" as "fixed",
      top: 0,
      left: 0,
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.32)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    box: {
     
    },
    box2: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      gap: "12px",
      flexDirection: "column" as "column",
    },
    feedbackTitle: {
      fontSize: "24px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#334155",
      textAlign: "center" as "center",
    },
    feedbackDescription: {
      fontSize: "18px",
      fontFamily: "DIN Next LT Arabic Regular",
      color: "#475569",
      textAlign: "center" as "center"
    },
    textBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
      flexDirection: "column" as "column",
    },
  };
  