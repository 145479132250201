Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.transactionStatementsListEndpoint = "bx_block_visual_analytics/transaction_statements";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.topTradedIndustriesEndpoint = "bx_block_visual_analytics/monthly_reports/top_traded_industries";
exports.monthlyListingEndpoint = "bx_block_visual_analytics/monthly_reports/month_listing";
exports.yearlyListingEndpoint = "bx_block_visual_analytics/monthly_reports/year_listing";
exports.monthlyReportsGraphEndpoint = "bx_block_visual_analytics/monthly_reports";
exports.downloadReportEndpoint = "bx_block_visual_analytics/monthly_reports/download_report";
exports.monthlyAnalyticsEndpoint = "bx_block_visual_analytics/monthly_reports/monthly_analytics";
exports.yearToDateProfitEndpoint = "bx_block_visual_analytics/monthly_reports/year_to_date_profit";

// Customizable Area End