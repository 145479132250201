import React, { createRef, useRef } from 'react';
import { Box, Typography, Modal, TextField, FormControl, Select, MenuItem, FormHelperText, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { calendar, cross2 } from '../../blocks/multipageforms/src/assets';
import { formatDateToString } from './utils.web';

interface ConfirmSaveDraftProps {
    open: boolean;
    handleSave: (draft: boolean) => void;
    handleDiscard: () => void;
    handleCloseModal: () => void;
}


const useStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        [theme.breakpoints.down(598)]: {
            gap: "16px",
            width: "100%",
            padding: "0px",
        },
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    heading: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "24px",
        color: "#334155",
        lineHeight: '32px',
    },
    label: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "18px",
        color: "#475569",
        lineHeight: '26px'
    },
    modalContainer: {
        [theme.breakpoints.down(938)]: {
            width: "calc(55% - 48px)",
        },
        [theme.breakpoints.down(853)]: {
            width: "calc(60% - 48px)",
        },
        [theme.breakpoints.down(782)]: {
            width: "calc(65% - 48px)",
        },
        [theme.breakpoints.down(722)]: {
            width: "calc(70% - 48px)",
        },
        [theme.breakpoints.down(670)]: {
            width: "calc(75% - 48px)",
        },
        [theme.breakpoints.down(626)]: { width: "calc(80% - 48px)" },
        [theme.breakpoints.down(598)]: { width: "90%" },
        width: "calc(40% - 48px)",
    },
    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        width: '100%'
    },
    discardBtn: {
        width: '100%',
        color: '#1B4FE4',
        fontFamily: 'DIN Next LT Arabic Regular',
        border: '1px solid #1B4FE4',
        borderRadius: '8px',
        padding: '10px 16px',
        textTransform: 'initial'
    },
    saveBtn: {
        width: '100%',
        color: '#fff',
        fontFamily: 'DIN Next LT Arabic Regular',
        border: '1px solid #1B4FE4',
        backgroundColor: '#1B4FE4',
        borderRadius: '8px',
        padding: '10px 16px',
        textTransform: 'initial',
        "&:hover": {
            backgroundColor: '#1B4FE4',
            color: '#fff',
        }
    },
    crossStyle: {
        [theme.breakpoints.down(598)]: {
            width: "40px",
            height: "40px"
        },
    },
    endRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
}));

const ConfirmSaveDraft = ({
    open,
    handleCloseModal,
    handleDiscard,
    handleSave
}: ConfirmSaveDraftProps) => {
    const classes = useStyles();

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                p={2}
            >
                <Box
                    bgcolor="white"
                    p={4}
                    borderRadius="8px"
                    boxShadow={3}
                    maxWidth="500px"
                    width="100%"
                    display={"flex"}
                    flexDirection={"column"}
                    gridGap={20}
                >
                    <Box className={classes.endRight}>
                        <img src={cross2} onClick={handleCloseModal} className={classes.crossStyle} />
                    </Box>
                    <Typography className={classes.heading} gutterBottom>
                        Save as Draft?
                    </Typography>
                    <Typography className={classes.label} gutterBottom>
                    Drafts let you save your edits, so you can come back later.
                    </Typography>
                    <Box className={classes.flexBox}>
                            <Button onClick={handleDiscard} className={classes.discardBtn}>
                                Discard
                            </Button>
                            <Button onClick={() => handleSave(true)} className={classes.saveBtn}>
                                Save
                            </Button>
                        </Box>
                </Box>
            </Box>
        </Modal >
    );
};

export default ConfirmSaveDraft;
