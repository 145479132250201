import React from "react";

// Customizable Area Start
import { Box, TableCell, TableRow, Typography } from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { withTranslation } from "react-i18next";
import { backAr, backArR, defaultProfile } from "./assets";
// Customizable Area End

import FeedbackController, { Props, configJSON } from "./FeedbackController";
import { Pagination } from "@material-ui/lab";

export default class FeedbackAllReviews extends FeedbackController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  MainWrapper = styled(Box)(({ theme }) => ({
    zIndex: -1,
    position: "absolute",
    width: "calc(100vw - 367px)",
    [theme.breakpoints.up(900)]: {
      zIndex: 0,
      position: "inherit",
    },
    "& .container": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 54px)",
        padding: "5px 10px 10px 3rem",
        position: "absolute",
        top: "0",
        zIndex: 99,
      },
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      width: "calc(100vw - 367px - 2rem)",
      padding: "4rem 10px 10px 2rem",
      height: "calc(100vh - 3.5rem)",
      overflow: "scroll",
    },
    "& .icon": {
      width: 24,
      height: 24,
    },
    "& .buttonStyle": {
      textTransform: "none",
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "18px",
    },
    "& .stepFont": {
      color: "#64748B",
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
    "& .headingText": {
      [theme.breakpoints.down(750)]: {
        fontSize: "18px",
        lineHeight: "30px",
      },
      overflow: "hidden",
      color: "#1E293B",
      textOverflow: "ellipsis",
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "40px",
      letterSpacing: "-0.15px",
    },
    "& .paginationRoot": {
      "& .MuiPaginationItem-root": {
        borderRadius: 4,
        marginRight: "16px",
        fontFamily: "DIN Next LT Arabic Bold",
        fontWeight: 700,
      },
      "& .MuiPaginationItem-page:not(.Mui-selected)": {
        border: "1px solid #94A3B8",
        color: "#94A3B8",
      },
    },
    "& .reviewBox": {
      [theme.breakpoints.down(598)]: {
        gap: "8px",
      },
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "20px",
      width: "100%",
    },
    "& .imageStyleBox": {
      [theme.breakpoints.down(598)]: {},
      width: "44px",
      height: "44px",
      borderRadius: "50%",
    },
    "& .nameSS": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
      gap: "2px",
    },
    "& .nameBox": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .userName": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
      },
      color: "#0F172A",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .dateStyle": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
        fontFamily: "DIN Next LT Arabic Regular",
      },
      color: "#97999A",
      fontSize: "15px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .describeText": {
      [theme.breakpoints.down(598)]: {
        color: "#334155",
        fontSize: "14px",
        fontFamily: "DIN Next LT Arabic Regular",
        paddingTop: "12px",
      },
      color: "#64748B",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .commentBox": {
      padding: "16px 12px 16px 16px",
    },
    "& .headingFlexBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "20px",
    },
    "& .backIcon": {
      [theme.breakpoints.down(598)]: {
        width:"15px",
        height:"15px"
      },
    },
    "& .backIcon2": {
      [theme.breakpoints.down(598)]: {
        width:"20px",
        height:"20px"
      },
    }
  }));

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { paginationData } = this.state;
    return (
      <div style={{ display: "flex", width: "fit-content" }}>
        <Box>
          <NavigationMenu
            id=""
            navigation={this.props.navigation}
          ></NavigationMenu>
        </Box>
        <this.MainWrapper>
          <Box className="container">
            <Box
              className="headingFlexBox"
              style={{
                direction: this.handleCondition(
                  this.props.i18n?.language === "ar",
                  "rtl",
                  "ltr"
                ),
              }}
            >
              <Typography className="headingText">
                {this.props.i18n?.t("customerReviews")}
              </Typography>
            </Box>
            <Box className="commentBox">
              <Box
                className="reviewBox"
                style={{
                  direction: this.handleCondition(
                    this.props.i18n?.language === "ar",
                    "rtl",
                    "ltr"
                  ),
                }}
              >
                <img src={defaultProfile} className={"imageStyleBox"} />
                <Box className="nameSS">
                  <Box className="nameBox">
                    <Typography className="userName">okay</Typography>
                    <Typography className="dateStyle">22/01/2024</Typography>
                  </Box>
                  <Typography className="describeText">
                    Welcome to all reviews list...
                  </Typography>
                </Box>
              </Box>
              {paginationData.totalPages > 1 && (
                <TableRow
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <TableCell colSpan={5}>
                    <Box display="flex" justifyContent="flex-end">
                      <Pagination
                        data-test-id="pagination"
                        color="primary"
                        size="small"
                        className="paginationRoot"
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </Box>
          </Box>
        </this.MainWrapper>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

export const FeedbackAllReviewsWeb = withTranslation()(FeedbackAllReviews);
// Customizable Area End
