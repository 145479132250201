Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "interactivecalculator";
exports.labelBodyText = "interactivecalculator Body";
exports.bmrCalculatorHeading="BMR Calculator";
exports.bmiCalculatorHeading="BMI Calculator";
exports.caloriesCalculatorHeading="Calories Calculator";
exports.caloriesDay="Calories/Day";
exports.title2="2";
exports.bmr="BMR";
exports.bmi="BMI";
exports.calorie="Calorie";
exports.usUnit="US Units";
exports.mUnit="Metric Units";
exports.age="Age";
exports.gender="Gender";
exports.male="male";
exports.female="female";
exports.height="Height";
exports.weight="Weight";
exports.feet="feet";
exports.inche="inches";
exports.active="Active";
exports.pound="pounds";
exports.caloriesTab="Basal Metabolic Rate (BMR)";
exports.secondryCalories="Sedentary: little or no exercise";
exports.lightCalories="Light: exercise 1-3 times/week";
exports.moderateCalories="Moderate: exercise 4-5 times/week";
exports.activeCalories="Active: daily exercise or intense exercise 3-4 times/week";
exports.veryActiveCalories="Very Active: intense exercise 6-7 times/week";
exports.extraActiveCalories="Extra Active: very intense exercise daily, or physical job";
exports.calculator="Calculator";
exports.clear="Clear";
exports.reasonableAge="Please provide reasonable age";
exports.reasonableHeight="Please provide reasonable height";
exports.reasonableWidth="Please provide reasonable width";
exports.result="Result";
exports.cm="cm";
exports.kgm="kg/m";

exports.btnExampleTitle = "CLICK ME";
exports.calculatorEndPoint="bx_block_calculator/calculators";
exports.getResultsApiMethod = "POST";
exports.selectPeriod = [{ label: "Month", value: "month" },{ label: "Year", value: "year" }];
exports.selectCurrency = [
  { label: "USD", value: "United States Dollar" },
  { label: "EUR", value: "Euro" },
  { label: "GBP", value: "British Pound" },
  { label: "JPY", value: "Japanese Yen" },
  { label: "AUD", value: "Australian Dollar" },
  { label: "CAD", value: "Canadian Dollar" },
  { label: "CHF", value: "Swiss Franc" },
  { label: "CNY", value: "Chinese Yuan" },
  { label: "SEK", value: "Swedish Krona" },
  { label: "NZD", value: "New Zealand Dollar" },
  { label: "MXN", value: "Mexican Peso" },
  { label: "SGD", value: "Singapore Dollar" },
  { label: "HKD", value: "Hong Kong Dollar" },
  { label: "NOK", value: "Norwegian Krone" },
  { label: "KRW", value: "South Korean Won" },
  { label: "TRY", value: "Turkish Lira" },
  { label: "RUB", value: "Russian Ruble" },
  { label: "INR", value: "Indian Rupee" },
  { label: "BRL", value: "Brazilian Real" },
  { label: "ZAR", value: "South African Rand" },
  { label: "PHP", value: "Philippine Peso" },
  { label: "CZK", value: "Czech Koruna" },
  { label: "IDR", value: "Indonesian Rupiah" },
  { label: "MYR", value: "Malaysian Ringgit" },
  { label: "HUF", value: "Hungarian Forint" },
  { label: "ISK", value: "Icelandic Krona" },
  { label: "HRK", value: "Croatian Kuna" },
  { label: "BGN", value: "Bulgarian Lev" },
  { label: "RON", value: "Romanian Leu" },
  { label: "DKK", value: "Danish Krone" },
  { label: "THB", value: "Thai Baht" },
  { label: "PLN", value: "Polish Zloty" }
];



// Customizable Area End