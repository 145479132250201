export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const signupImg = require("../assets/signup.png");
export const logoImg = require("../assets/logo.png");
export const uploadIcon = require("../assets/uploadIcon.png");
export const PassIcon = require("../assets/passicon.svg");
export const googleIcon = require("../assets/googleicon.svg");
export const hidePassIcon = require("../assets/HidePassIcon.svg");
export const showPassIcon = require("../assets/ShowPassIcon.svg");
export const crossIcon = require("../assets/cross.svg");
export const pdfImage = require("../assets/pdfImage.png");
export const pngImage = require("../assets/png.png");
export const imgLogo = require("../assets/imgLogo.png");
export const buttonDown = require("../assets/dropDown.svg");
