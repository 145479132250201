import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Close, Edit, FullscreenExit } from '@material-ui/icons';
import { fullScreenIcon } from '../../blocks/customform/src/assets';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: theme.spacing(2),
      maxWidth: 600,
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2),
    '&:first-child': {
      paddingTop: 0,
    },
  },
  previewContainer: {
    position: 'relative',
    width: '100%',
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframeWrapper: {
    position: 'relative',
    width: '65%',
    height: '90%',
    border: '5px solid #000000',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  expandButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 9999,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
  },
  editButton: {
    width: '268px',
    color: '#1B4FE4',
    fontFamily: 'DIN Next LT Arabic Regular',
    border: '1px solid #1B4FE4',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'initial'
  },
  generateButton: {
    width: '268px',
    color: '#fff',
    fontFamily: 'DIN Next LT Arabic Regular',
    border: '1px solid #1B4FE4',
    backgroundColor: '#1B4FE4',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'initial',
    "&:hover": {
      backgroundColor: '#1B4FE4',
      color: '#fff',
    }
  },
  fullScreenBackdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullScreenContainer: {
    width: '90%',
    height: '90%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
  fullScreenHeader: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  heading: {
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "24px",
    color: "#334155",
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

interface PDFViewerModalProps {
  open: boolean;
  onClose: () => void;
  pdfUrl: string;
  onEdit: () => void;
  onGenerate: () => void;
}

export default function AccountLetterViewModal({ open, onClose, pdfUrl, onEdit, onGenerate }: PDFViewerModalProps) {
  const classes = useStyles();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleExpandClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const renderPDFViewer = (fullScreen: boolean) => (
    <div className={fullScreen ? classes.fullScreenContainer : classes.iframeWrapper}>
      <IconButton
        className={classes.expandButton}
        onClick={fullScreen ? handleCloseFullScreen : handleExpandClick}
        aria-label={fullScreen ? "exit fullscreen" : "enter fullscreen"}
      >
        {fullScreen ? <FullscreenExit /> : <img src={fullScreenIcon} />}
      </IconButton>
      <DocViewer
          documents={[{ uri: pdfUrl, fileType: 'pdf' }]}
          className={classes.iframe}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100%" }}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false
            }
          }}
        />
    </div>
  );

  return (
    <>
      <Dialog
        open={open && !isFullScreen}
        className={classes.dialog}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          {/* <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton> */}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography className={classes.heading}>Confirm Generation</Typography>
          <div className={classes.previewContainer}>
            {renderPDFViewer(false)}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            variant="outlined"
            startIcon={<Edit />}
            onClick={onEdit}
            className={classes.editButton}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            onClick={onGenerate}
            className={classes.generateButton}
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>

      {isFullScreen && (
        <div className={classes.fullScreenBackdrop}>
          {renderPDFViewer(true)}
        </div>
      )}
    </>
  );
}