import React, { useEffect, useRef } from 'react';
import { Box, Typography, Modal, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { cross2 } from '../../blocks/multipageforms/src/assets';
import { carefulIcon } from '../../blocks/customform/src/assets';

interface DeleteConfirmationProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (id: string) => void;
    itemName?: string;
    itemId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        [theme.breakpoints.down(598)]: {
            gap: "16px",
            width: "100%",
            padding: "0px",
        },
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    heading: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "24px",
        color: "#334155",
        lineHeight: '32px',
        [theme.breakpoints.down(598)]: { fontSize: "18px" }
    },
    label: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "18px",
        color: "#475569",
        lineHeight: '26px',
        textAlign: 'center'
    },
    modalContainer: {
        [theme.breakpoints.down(938)]: {
            width: "calc(55% - 48px)",
        },
        [theme.breakpoints.down(853)]: {
            width: "calc(60% - 48px)",
        },
        [theme.breakpoints.down(782)]: {
            width: "calc(65% - 48px)",
        },
        [theme.breakpoints.down(722)]: {
            width: "calc(70% - 48px)",
        },
        [theme.breakpoints.down(670)]: {
            width: "calc(75% - 48px)",
        },
        [theme.breakpoints.down(626)]: { width: "calc(80% - 48px)" },
        [theme.breakpoints.down(598)]: { width: "90%" },
        width: "calc(40% - 48px)",
    },
    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        width: '100%'
    },
    discardBtn: {
        width: '100%',
        color: '#1B4FE4',
        fontFamily: 'DIN Next LT Arabic Regular',
        border: '1px solid #1B4FE4',
        borderRadius: '8px',
        padding: '10px 16px',
        textTransform: 'initial'
    },
    saveBtn: {
        width: '100%',
        color: '#fff',
        fontFamily: 'DIN Next LT Arabic Regular',
        border: '1px solid #1B4FE4',
        backgroundColor: '#1B4FE4',
        borderRadius: '8px',
        padding: '10px 16px',
        textTransform: 'initial',
        "&:hover": {
            backgroundColor: '#1B4FE4',
            color: '#fff',
        }
    },
    crossStyle: {
        [theme.breakpoints.down(598)]: {
            width: "40px",
            height: "40px"
        },
    },
    endRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px',
        maxHeight: '30vh',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f1f1f1',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '3px',
          minHeight: '30px',
        },
        '&.no-scroll': {
          overflowY: 'hidden',
        },
      },
}));


const DeleteConfirmationPopup: React.FC<DeleteConfirmationProps> = ({
    open,
    onClose,
    onConfirm,
    itemName,
    itemId
}) => {
    const classes = useStyles();
    const centerFlexRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkScroll = () => {
            if (centerFlexRef.current) {
                const { scrollHeight, clientHeight } = centerFlexRef.current;
                if (scrollHeight > clientHeight) {
                    centerFlexRef.current.classList.remove('no-scroll');
                } else {
                    centerFlexRef.current.classList.add('no-scroll');
                }
            }
        };

        checkScroll();
        window.addEventListener('resize', checkScroll);

        return () => window.removeEventListener('resize', checkScroll);
    }, []);

    return (
        <Modal open={open}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                p={2}
            >
                <Box
                    bgcolor="white"
                    p={4}
                    borderRadius="8px"
                    boxShadow={3}
                    maxWidth="500px"
                    width="100%"
                    display={"flex"}
                    flexDirection={"column"}
                    gridGap={20}
                >
                    <Box className={classes.endRight}>
                        <img src={cross2} onClick={onClose} className={classes.crossStyle} alt="Close" />
                    </Box>
                    {/* @ts-ignore */}
                    <Box ref={centerFlexRef} className={`${classes.centerFlex} no-scroll`}>
                        <img src={carefulIcon} style={{ width: '80px', height: '80px', color: '#1B4FE4' }} alt="Careful" />
                        <Typography className={classes.heading} gutterBottom>
                            Be Careful
                        </Typography>
                        <Typography className={classes.label} gutterBottom>
                            Are you sure you want to delete this document?
                        </Typography>
                    </Box>
                    <Box className={classes.flexBox}>
                        <Button onClick={onClose} className={classes.discardBtn}>
                            No
                        </Button>
                        <Button onClick={() => onConfirm(itemId)} className={classes.saveBtn}>
                            Yes
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteConfirmationPopup;
