import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Typography,
  Select,
  MenuItem,
  Button,
  ThemeProvider,
  styled,
  createTheme,
  InputAdornment,
  DialogContent,
  Dialog,
  DialogActions,
  Divider,
  Backdrop,
  ClickAwayListener
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  imgLogo,
  signupImg,
  uploadIcon,
  PassIcon,
  hidePassIcon,
  showPassIcon,
  crossIcon,
  pdfImage,
  pngImage,
  buttonDown,
} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
export const configJSON = require("./config");
import SignupSuccessModal from "../../../components/src/SignupSuccessModal.web";
import Spinner from "./components/Spinner.web";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import PhoneInput from "react-phone-input-2";
import { withTranslation } from "react-i18next";
import LanguageSwitch from "../../../components/src/LanguageSwitch.web";
import { calendar } from "../../../blocks/analytics/src/assets";
import Calendar from "react-calendar";


// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    localStorage.setItem("privacy", JSON.stringify(false));
    this.formikRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start

  createSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter Last name"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Please enter your email"),
      id: Yup.string()
        .required("Please select Id proof")
        .notOneOf(["none"], "Please select Id proof"),
      filename: Yup.string().required("Please upload ID proof"),
      contact: Yup.string().required("Please enter your number"),
      service: Yup.string()
        .required("Please select service")
        .notOneOf(["none"], "Please select service"),
      country: Yup.string()
        .required("Please select country")
        .notOneOf(["none"], "Please select country"),
      password: Yup.string()
        .required("Please enter your password.")
        .min(8, "Your password is too short."),
      agree: Yup.boolean().oneOf(
        [true],
        "You have to agree with Privacy Policy and Terms and Conditions to Sign up"
      ),
    });
  };

  createSchemaAr = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email("ادخل بريد الكتروني صالح")
        .required("الرجاء ادخال بريدك الالكتروني"),
      lastName: Yup.string().required("الرجاء ادخال اسمك الاخير"),
      id: Yup.string()
        .required("الرجاء تحديد ملف بحجم لا يزيد عن 2 ميجابايت")
        .notOneOf(["none"], "الرجاء تحديد ملف بحجم لا يزيد عن 2 ميجابايت"),
      firstName: Yup.string().required("الرجاء ادخال اسمك الاول"),
      filename: Yup.string().required("يرجى تحميل إثبات الهوية"),
      service: Yup.string()
        .required("الرجاء تحديد الخدمة")
        .notOneOf(["none"], "الرجاء تحديد الخدمة"),
      contact: Yup.string().required("الرجاء ادخال رقم هاتفك"),
      country: Yup.string()
        .required("الرجاء تحديد بلدك")
        .notOneOf(["none"], "الرجاء تحديد بلدك"),
      agree: Yup.boolean().oneOf(
        [true],
        "الموافقة على سياسة الخصوصية وشروط الخدمة ضرورية للتسجيل"
      ),
      password: Yup.string()
        .required("الرجاء ادخال كلمة المرور الخاصة بك")
        .min(8, "كلمة المرور قصيرة جداً."),
    });
  };


  getErrorMessage = (touched: any, errors: any, value: any) => {
    return this.handleCondition(
      this.handleCondition(touched[value], errors[value], ""),
      <Typography style={webStyle2.validationStyle}>
        {errors[value]}
      </Typography>,
      ""
    );
  };

  MainWrapper = styled(Box)(({ theme }) => ({
    height:"100vh",
    width:"100%",
    "& .mainContainer": {
      [theme.breakpoints.down(598)]: {
        flexDirection: "column",
      },
    },
    "& .imgContainer": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
        paddingTop: "15vw",
        paddingBottom: "20px",
      },
    },
    "& .logo": {
      [theme.breakpoints.down(598)]: {
        display: "none",
      },
    },
    "& .imgStyle": {
      [theme.breakpoints.down(598)]: {
        padding: "35px 35px 0px 35px",
        gap: "0rem",
      },
      gap: "4rem",
    },
    "& .imgText": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
        fontWeight: 700,
      },
    },
    "& .formData": {
      [theme.breakpoints.down(598)]: {
        borderRadius: "2rem 2rem 0 0",
        position: "relative",
        top: "-2rem",
        backgroundColor: "white",
      },
    },
    "& .mainHeading": {
      [theme.breakpoints.down(598)]: {
        fontSize: "24px",
        color: "#122967",
        margin: "1rem",
        paddingTop: "10px",
      },
      color: "#000",
      paddingTop: "40px",
      fontSize: "30px",
    },
    "& .imgText1": {
      [theme.breakpoints.down(598)]: {
        fontSize: "18px",
      },
      fontSize: "36px",
    },
    "& .imgText2": {
      [theme.breakpoints.down(598)]: {
        fontSize: "1rem",
        color: "#94A3B8",
        paddingBottom: "2rem",
      },
      fontSize: "20px",
    },
    "& .container": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
      },
      width: "50%",
    },
    "& .nameInput": {
      [theme.breakpoints.down(598)]: {
        width: "93%",
      },
      width: "89%",
    },
    "& .nameInput3": {
      [theme.breakpoints.down(598)]: {
        width: "93%",
      },

      width: "89%",
    },

    "& .passInputText": {
      [theme.breakpoints.down(598)]: {
        width: "94%",
      },
      width: "calc(100% - 1.7rem)",
      borderRadius: "8px",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      color:"#94A3B8",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CBD5E1",
      justifyContent: "space-between",
      padding: "0.8rem",
    },
    "& .nameInput1": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
      },
      width: "89%",
    },
    "& .nameContainer": {
      [theme.breakpoints.down(598)]: {
        flexDirection: "column",
        gap: "1rem",
        width: "93%",
      },
      gap: "3rem",
      width: "89%",
    },
    "& .nameInputText": {
      [theme.breakpoints.down(598)]: {
        borderRadius: "12px",
      },
      borderRadius: "8px",
    },
    "& .phoneInputText": {
      [theme.breakpoints.down(598)]: {
        width: "94.5%",
      },
      width: "calc(100% - 1.7rem)",
      borderRadius: "8px",
      fontWeight: 400,
      border: "1px solid #CBD5E1",
      padding: "0.8rem",
      fontSize: "16px",
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "flex-start",
      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Bold",
      color:"#94A3B8"
    },
    "& .SubmitBtn": {
      [theme.breakpoints.down(598)]: {
        fontSize: "1rem",
      },
    },
    "& .orstyle": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
      },
    },
    "& .signWithGoogle": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
      },
    },
    "& .passwordPopBox": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
      },
      backgroundColor: "rgba(0, 0, 0, 0.32)",
      width: "50%",
    },
    "& .passText": {
      [theme.breakpoints.down(598)]: {
        fontWeight: 500,
      },
      fontWeight: 700,
    },
    "& .termAndConditionError": {
      [theme.breakpoints.down(598)]: {
        width: "89%",
      },
      width: "86%",
    },
    "& .passwordErrorColor": {
      [theme.breakpoints.down(598)]: {
        fontSize: "0.8rem",
      },
      fontSize: "1rem",
    },
    "& .agreeText": {
      [theme.breakpoints.down(598)]: {
        padding: "0",
      },
    },
    "& .langPosition": {
      position: 'absolute',
      top: '35px',
      left: '35px'
    },
    "& .tabletCss": {
      width: "calc(100% - 1.7rem)",
      borderRadius: "8px",
      fontWeight: 400,
      border: "1px solid #CBD5E1",
      padding: "0.8rem",
      fontSize: "16px",
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "flex-start",
      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Bold",
      color:"#94A3B8"
    },
    "& .resposiveforTablet":{
      position: 'absolute',
      top: '35px',
      left: '35px'
    },
    "& .dateInput": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      outline: "none",
      border: "none",
    },
    "& .dateBox": {
      [theme.breakpoints.down(598)]: {},
      width: "calc(100% - 1.7rem)",
      display: "flex",
      padding: "12px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      outline: "none",
    },
    "& .selectDate": {
      width: 0,
      border: "none",
    },
  }));

  renderCalender = () => {
    return (<CustomDatePicker>
      {this.state.calendarOpen && (
        <div className="reactCalendarBox" style={{ position: "absolute" }}>
          <div className="calBox">
            <Calendar
              minDate={new Date()}
              onChange={this.handleDateChange}
              data-testID="calendar"
              value={this.state.formattedDate}
            />
          </div>
        </div>
      )}
    </CustomDatePicker>)
  }

  idProof = [
    { label: this.props.i18n?.t('passport'), value: "Passport" },
    { label: this.props.i18n?.t('birthCertificate'), value: "Birth certificate" },
    { label: this.props.i18n?.t('drivingLicense'), value: "Driving license" },
    { label: this.props.i18n?.t('nationalId'), value: "National ID" },
  ];

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <this.MainWrapper>
        <ThemeProvider theme={theme}>
          <Box sx={webStyle2.mainContainer}
          style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}
          className="mainContainer">
          {this.state.pageLoading && <Spinner spinnerModal={this.state.pageLoading} />}
            <Box sx={webStyle2.imgContainer} className="imgContainer">
             <Box 
             style={{
              direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr'),
              margin: this.handleCondition(this.props.i18n?.language === 'ar', '0 30px 0 0', '0 0 0 30px')
            }}
            //  className="langPosition"
             >
             <LanguageSwitch
                isLandingPage={false}
                lang={this.state.currentLanguage}
                handleLanguageChange={this.handleLanguageChange}
              />
             </Box>
             
              <img src={imgLogo} style={webStyle2.logo} className="logo" />
              <Box sx={webStyle2.imgStyle} className="imgStyle">
                <img style={webStyle2.img} src={signupImg} />
                <Box sx={webStyle2.imgText} className="imgText">
                  <Typography style={webStyle2.imgText1} className="imgText1">
                  {this.props.i18n?.t('investYourMoney')}
                  </Typography>
                  <Typography style={webStyle2.imgText2} className="imgText2">
                  "{this.props.i18n?.t('calculateThemeTxt')}"
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={webStyle2.container} className="container">
              {this.handleCondition(
                this.state.popupForPassword,
                <Box sx={webStyle2.passwordPopBox} className="passwordPopBox" style={{
                  direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr'),
                  left: this.handleCondition(this.props.i18n?.language === 'ar', '0', 'null')
                }}>
                  <Box sx={webStyle2.passwordBoxx}>
                    <Box style={webStyle2.passBox}>
                      <img src={crossIcon} onClick={this.handlepasswordPopup} />
                      <Box sx={webStyle2.passBox2}>
                        <Typography style={webStyle2.passText} className="passText">
                          {this.props.i18n?.t('password_rules')}
                        </Typography>
                        <Typography style={webStyle2.imgPopUpText}>
                          &#x2022; {this.props.i18n?.t('capital_letter')}
                        </Typography>
                        <Typography style={webStyle2.imgPopUpText}>
                          &#x2022; {this.props.i18n?.t('lowercase_letter')}
                        </Typography>
                        <Typography style={webStyle2.imgPopUpText}>
                          &#x2022; {this.props.i18n?.t('number')}
                        </Typography>
                        <Typography style={webStyle2.imgPopUpText}>
                          &#x2022; {this.props.i18n?.t('min_length')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>,
                ""
              )}
              <Formik
                innerRef={this.formikRef}
                data-test-id="formik"
                enableReinitialize
                initialValues={this.state.initialValues}
                validationSchema={this.handleCondition(this.props.i18n?.language === 'ar', this.createSchemaAr, this.createSchema)}
                onSubmit={(values: {
                  firstName: string;
                  lastName: string;
                  email: string;
                  id: string;
                  contact: string;
                  service: string;
                  country: string;
                  password: string;
                  filename: string;
                  file: string;
                  agree: boolean;
                  phonecode: string;
                }) => {
                  this.handleCondition(
                    this.state.customError === "",
                    this.state.googleLoginStatus?this.handleGoogleSignup(values):this.handleSignupClick(values),
                    ""
                  );
                }}
              >
                {({
                  handleSubmit,
                  errors,
                  values,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <Box>
                    <form
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      style={webStyle2.formData}
                      className="formData"
                    >
                      <Typography
                        style={webStyle2.mainHeading}
                        className="mainHeading"
                      >
                        {this.handleCondition(this.state.googleLoginStatus,this.props.i18n?.t("update_account_heading"),this.props.i18n?.t("create_account_heading"))}
                        
                      </Typography>
                      {this.handleCondition(
                        errors.agree && touched.agree,
                        <Box
                          className="termAndConditionError"
                          style={webStyle2.termAndConditionError}
                        >
                          {this.getErrorMessage(touched, errors, "agree")}
                        </Box>,
                        <></>
                      )}
                      <Box
                        sx={webStyle2.nameContainer}
                        className="nameContainer"
                      >
                        <Box sx={webStyle2.nameInput} className="nameInput1">
                          <Box sx={webStyle2.labeltext}>
                            <Typography style={webStyle2.labeltext}>
                              {this.props.i18n?.t("firstName")}
                            </Typography>
                            <Typography style={webStyle2.redIcon}>*</Typography>
                          </Box>
                          <TextField
                            className="nameInputText"
                            style={{
                              ...webStyle2.nameInputText,
                              border: this.handleCondition(
                                this.getErrorMessage(
                                  touched,
                                  errors,
                                  "firstName"
                                ),
                                "1px solid #F87171",
                                "1px solid #CBD5E1"
                              ),
                            }}
                            placeholder={this.props.i18n?.t("enterFirstName")}
                            value={values.firstName}
                            data-test-id="firstName"
                            fullWidth
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={(event) =>
                              setFieldValue("firstName", event.target.value)
                            }
                            InputProps={{ disableUnderline: true }}
                          />
                          {this.getErrorMessage(touched, errors, "firstName")}
                        </Box>
                        <Box sx={webStyle2.nameInput} className="nameInput1">
                          <Box sx={webStyle2.labeltext}>
                            <Typography style={webStyle2.labeltext}>
                            {this.props.i18n?.t("lastName")}
                            </Typography>
                            <Typography style={webStyle2.redIcon}>*</Typography>
                          </Box>
                          <TextField
                            style={{
                              ...webStyle2.nameInputText,
                              border: this.handleCondition(
                                this.getErrorMessage(
                                  touched,
                                  errors,
                                  "lastName"
                                ),
                                "1px solid #F87171",
                                "1px solid #CBD5E1"
                              ),
                            }}
                            placeholder={this.props.i18n?.t("enterLastName")}
                            value={values.lastName}
                            fullWidth
                            name="lastName"
                            data-test-id="lastName"
                            onBlur={handleBlur}
                            onChange={(event) =>
                              setFieldValue("lastName", event.target.value)
                            }
                            InputProps={{ disableUnderline: true }}
                          />
                          {this.getErrorMessage(touched, errors, "lastName")}
                        </Box>
                      </Box>
                      <Box sx={webStyle2.nameInput} className="nameInput">
                        <Box sx={webStyle2.labeltext}>
                          <Typography style={webStyle2.labeltext}>
                          {this.props.i18n?.t("email")}
                          </Typography>
                          <Typography style={webStyle2.redIcon}>*</Typography>
                        </Box>
                        <TextField
                          placeholder={this.props.i18n?.t("enterEmail")}
                          name="email"
                          data-test-id="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={(event) =>
                            setFieldValue("email", event.target.value)
                          }
                          InputProps={{ disableUnderline: true }}
                          style={{
                            ...webStyle2.nameInputText,
                            border: this.handleCondition(
                              this.getErrorMessage(touched, errors, "email"),
                              "1px solid #F87171",
                              "1px solid #CBD5E1"
                            ),
                          }}
                          fullWidth
                        />
                        {this.getErrorMessage(touched, errors, "email")}
                      </Box>
                      <Box sx={webStyle2.nameInput} className="nameInput">
                        <Box sx={webStyle2.labeltext}>
                          <Typography style={webStyle2.labeltext}>
                          {this.props.i18n?.t("idProof")}
                          </Typography>
                          <Typography style={webStyle2.redIcon}>*</Typography>
                        </Box>
                        <Select
                          style={{
                            ...webStyle2.inputTextForSelect,
                            border: this.handleCondition(
                              this.getErrorMessage(touched, errors, "id"),
                              "1px solid #F87171",
                              "1px solid #CBD5E1"
                            ),
                            color: this.handleCondition(values.id === "none", "darkgrey", "#000000"),
                          }}
                          id="id"
                          name="id"
                          data-test-id="idProof"
                          fullWidth
                          value={values.id}
                          onBlur={handleBlur}
                          disableUnderline
                          onChange={(event) =>
                            setFieldValue("id", event.target.value)
                          }
                        >
                          <MenuItem value="none" disabled>
                          {this.props.i18n?.t("select_your_id")}
                          </MenuItem>
                          {this.idProof.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.getErrorMessage(touched, errors, "id")}
                        <Box style={{width: '100%', margin: '1rem 0'}} sx={webStyle2.nameInput} className="nameInput">
                          <Box sx={webStyle2.labeltext}>
                            <Typography id="Id proof exiry date" style={webStyle2.labeltext}>
                              {this.props.i18n?.t("idProofExpiryDate")}
                            </Typography>
                            <Typography style={webStyle2.redIcon}>*</Typography>
                          </Box>
                          <Box className="dateBox">
                          <input
                              type="text"
                              className="dateInput"
                              id= "id_expiry_date"
                              name= "id_expiry_date"
                              placeholder= "Id proof expiry date"
                              value={this.state.formattedDate}
                            />
                            <img
                              src={calendar}
                              onClick={this.openCalendar}
                              className="calendarIcon"
                            />
                            {this.renderCalender()}
                          </Box>
                        </Box>
                        <Box
                          role="button"
                          onClick={this.handleClick}
                          style={webStyle2.imgCon}
                        >
                          {this.handleCondition(
                            values.filename === "",
                            <Box style={webStyle2.imgBox}>
                              <input
                                type="file"
                                data-test-id="files"
                                name="filename"
                                accept=".pdf, image/*"
                                style={webStyle2.fileStyle}
                                multiple
                                ref={this.state.inputRef}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const selectedFile = event.target.files?.[0];
                                  if (selectedFile) {
                                    const fileSize = selectedFile.size;
                                    const maxSizeInBytes = 2 * 1024 * 1024;
                                    const fileExtension = selectedFile.name?.split('.').pop()?.toLowerCase();
                                    if (fileExtension === 'jpg' || fileExtension === 'png'|| fileExtension === 'pdf') {
                                      setFieldValue("file", selectedFile);
                                      if (fileSize < maxSizeInBytes) {
                                        setFieldValue("filename", selectedFile.name);
                                        this.setCustomError("");
                                      } else {
                                        this.setCustomError(this.props.i18n?.t('not_accepted_2_mb'));
                                      }
                                    } else {
                                      this.setCustomError(this.props.i18n?.t('only_jpg_pdf_png'));
                                    }
                                  }
                                }}
                              />
                              <img
                                src={uploadIcon}
                                style={webStyle2.fileupLoad}
                              />
                              <Typography style={webStyle2.uploadText1}>
                                {this.props.i18n?.t("upload_id_proof")}
                              </Typography>
                              <Typography style={webStyle2.uploadText2}>
                              {this.props.i18n?.t("accepted_proof")}
                              </Typography>
                            </Box>,
                            <Typography style={webStyle2.imgBox2}>
                              <Box style={webStyle2.pdfBox}>
                                {this.handleCondition(
                                  values.filename?.includes(".pdf"),
                                  <img
                                    src={pdfImage}
                                    style={webStyle2.pdfStyle}
                                  />,
                                  <img
                                    src={pngImage}
                                    style={webStyle2.pdfStyle}
                                  />
                                )}
                                <Typography>{values.filename || ""}</Typography>
                              </Box>
                              <img
                                src={crossIcon}
                                onClick={() => {
                                  setFieldValue("filename", "");
                                  setFieldValue("file", "");
                                }}
                              />
                            </Typography>
                          )}
                        </Box>
                        {this.handleCondition(this.state.customError !== "" , (
                          <Typography style={webStyle2.validationStyle}>
                            {this.state.customError}
                          </Typography>
                        ) , "")}
                        {this.getErrorMessage(touched, errors, "filename")}
                      </Box>
                      <Box style={webStyle2.nameInput} className="nameInput">
                        <Box style={webStyle2.labeltext}>
                          <Typography style={webStyle2.labeltext}>
                            {this.props.i18n?.t('phoneNo')}.
                          </Typography>
                          <Typography style={webStyle2.redIcon}>*</Typography>
                        </Box>
                        <Box
                          style={{
                            border: this.handleCondition(
                              this.getErrorMessage(touched, errors, 'contact'),
                              '1px solid #F87171',
                              '1px solid #ced4da'
                            )
                          }}
                          className="phoneInputText"
                        >
                            <Box>
                              <PhoneInput
                              data-test-id="countryId"
                              country={"om"}
                              value={this.state.phone}
                              enableSearch={true}
                              disableSearchIcon={true}
                              enableAreaCodes={false}
                              enableTerritories={false}
                              onChange={(phone) => {
                                this.handlePhoneChange(phone);
                                 setFieldValue("phonecode", phone)
                              }}
                              inputProps={{
                                readOnly: true,
                              }}
                              containerStyle={{
                                width: "100%",
                              }}
                              inputStyle={{
                                display: "none",
                              }}
                              buttonStyle={{
                                width: "15%",
                                height: "32px",
                                backgroundColor: "#EFF6FF",
                                border: "none",
                              }}
                              dropdownStyle={{
                                width: "45vw",
                              }}
                              searchStyle={{
                                border:"none",
                                padding:"12px",
                                width:"100%",
                                borderBottom:"1px solid black"
                              }}
                            />
                              <input
                                style={{...webStyle2.phoneInputStyle, color: this.handleCondition(values.contact , "#292524","#A2A2A2")}}
                                placeholder={this.props.i18n?.t('phoneNo')}
                                name="contact"
                                data-test-id="phoneNumber"
                                value={values.contact}
                                onBlur={handleBlur}
                                maxLength={11}
                                minLength={7}
                                onChange={(event) => {
                                  const numericValue = event.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setFieldValue("contact", numericValue);
                                }}
                                pattern="[0-9]*"
                              />
                            </Box>
                        </Box>

                        {this.getErrorMessage(touched, errors, "contact")}
                      </Box>
                      <Box style={webStyle2.nameInput} className="nameInput">
                        <Box style={webStyle2.labeltext}>
                          <Typography style={webStyle2.labeltext}>
                            {this.props.i18n?.t("investmentService")}
                          </Typography>
                          <Typography style={webStyle2.redIcon}>*</Typography>
                        </Box>
                        <Select
                          style={{
                            ...webStyle2.inputTextForSelect,
                            border: this.handleCondition(
                              this.getErrorMessage(touched, errors, "service"),
                              "1px solid #F87171",
                              "1px solid #ced4da"
                            ),
                            color: this.handleCondition(values.service === "none", "#94A3B8", "#000000"),
                          }}
                          name="service"
                          fullWidth
                          data-test-id="service"
                          value={values.service}
                          disableUnderline
                          onBlur={handleBlur}
                          onChange={(event) =>
                            setFieldValue("service", event.target.value)
                          }
                        >
                          <MenuItem value="none" disabled>
                          {this.props.i18n?.t("select_service")}
                          </MenuItem>
                          {investment.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.getErrorMessage(touched, errors, "service")}
                      </Box>
                      <ClickAwayListener onClickAway={this.openPopupClose}>
                        <Box style={webStyle2.nameInput} className="nameInput">
                          <Box style={webStyle2.labeltext}>
                            <Typography
                              id="demo-id"
                              style={webStyle2.labeltext}
                            >
                              {this.props.i18n?.t("country")}
                            </Typography>
                            <Typography style={webStyle2.redIcon}>*</Typography>
                          </Box>
                          <TextField
                            style={webStyle2.inputText}
                            placeholder={this.props.i18n?.t("select_country")}
                            variant="outlined"
                            data-test-id="country"
                            onClick={this.handleOpen}
                            value={values.country}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    onClick={this.handleOpen}
                                    src={buttonDown}
                                    alt="dropdown"
                                    style={{
                                      padding: "0",
                                      width: "1.5rem",
                                      left: 4,
                                      margin: "0",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Box style={{ position: "relative", width: "100%" }}>
                            {this.handleCondition(
                              this.state.open,
                              <Box
                                style={{
                                  zIndex: 2,
                                  width: "100%",
                                  border: "1px solid black",
                                  position: "absolute",
                                  backgroundColor: "white",
                                }}
                                data-test-id="stop"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <input
                                  style={{
                                    zIndex: 3,
                                    padding: "20px",
                                    width: "100%",
                                    top: 100,
                                  }}
                                  onChange={this.handleSearchChange}
                                  placeholder="Search Country"
                                  data-test-id="stop1"
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <Box
                                  style={{
                                    height: "fit-content",
                                    maxHeight: "300px",
                                    overflow: "scroll",
                                  }}
                                >
                                  {configJSON.countryList
                                .filter((item: { value: string }) =>
                                  item.value
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchData.toLowerCase()
                                    )
                                )
                                .map((item: {value: string, label: string}, index: number) => (
                                  <MenuItem
                                    key={index}
                                    data-test-id="changeCountry"
                                    value={item.value}
                                    onClick={() => {
                                      setFieldValue("country", item.label);
                                      this.setState({ open: false });
                                    }}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ))}
                                </Box>
                              </Box>,
                              ""
                            )}
                          </Box>
                          {this.getErrorMessage(touched, errors, "country")}
                        </Box>
                      </ClickAwayListener>
                      <Box style={webStyle2.nameInput} className="nameInput3">
                        <Box style={webStyle2.labeltext}>
                          <Typography style={webStyle2.labeltext}>
                          {this.props.i18n?.t("password")}
                          </Typography>
                          <img
                            onClick={this.handlepasswordPopup}
                            src={PassIcon}
                            style={webStyle2.passwordIconStyle}
                          />
                        </Box>
                        <Box
                          style={{
                            border: this.handleCondition(
                              this.getErrorMessage(touched, errors, "password"),
                              "1px solid #F87171",
                              "1px solid #ced4da"
                            ),
                          }}
                          className="passInputText"
                        >
                          <input
                            type={this.handleCondition(
                              this.state.showPassword,
                              "text",
                              "password"
                            )}
                            style={{...webStyle2.passwordInput, color: this.handleCondition(values.password , "#292524","#A2A2A2")}}
                            placeholder={this.props.i18n?.t("enterPassword")}
                            id="password"
                            name="password"
                            data-test-id="password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              const numericValue = event.target.value.replace(
                                /\s/g,
                                ""
                              );
                              this.calculateStrength(numericValue);
                              setFieldValue("password", numericValue);
                            }}
                          />
                          <img
                            src={this.handleCondition(
                              this.state.showPassword,
                              showPassIcon,
                              hidePassIcon
                            )}
                            style={{
                              filter: "invert(54%) sepia(10%) saturate(1500%) hue-rotate(180deg) brightness(90%) contrast(90%)",
                              cursor: "pointer"
                            }}
                            onClick={this.handleTogglePassword}
                          />
                        </Box>
                        {this.handleCondition(values.password , (
                          <Box style={webStyle2.passwordCalculator}>
                            <Box style={webStyle2.passwordCalBox}>
                              {this.state.passwordArr.map(
                                (
                                  obj1: { isConsist: string[]; text: string },
                                  index: number
                                ) => (
                                  <Box style={webStyle2.passwordContentBox}>
                                    <Box
                                      key={index}
                                      bgcolor={this.handleCondition(
                                        obj1.isConsist.includes(
                                          this.state.passwordErrorShow
                                        ),
                                        this.state.passwordErrorColor,
                                        "#E2E8F0"
                                      )}
                                      style={webStyle2.passwordContent}
                                    ></Box>
                                    {this.handleCondition(
                                      this.state.passwordErrorShow ===
                                        obj1.text,
                                      <Typography
                                        className="passwordErrorColor"
                                        style={{
                                          color: this.state.passwordErrorColor,
                                        }}
                                      >
                                        {this.state.passwordErrorShow}
                                      </Typography>,
                                      ""
                                    )}
                                  </Box>
                                )
                              )}
                            </Box>
                          </Box>
                        ) , null)}
                        {this.getErrorMessage(touched, errors, "password")}
                      </Box>
                      <Box style={webStyle2.checkboxtextWithError}>
                        <Box style={webStyle2.checkboxText}>
                          <input
                            style={webStyle2.checkboxColor}
                            data-test-id="checkbox"
                            name="agree"
                            checked={values.agree}
                            onChange={(event) =>
                              setFieldValue("agree", event.target.checked)
                            }
                            type="checkbox"
                          />
                          <p className="agreeText" style={webStyle2.agreeText}>
                            {this.props.i18n?.t('iAgreeWith')}&nbsp;
                            <span
                              data-test-id="PrivacyPolicy"
                              onClick={() =>
                                this.handleNavigation("PrivacyPolicy", values)
                              }
                              style={webStyle2.hightlightText}
                            >
                              {this.props.i18n?.t('privacyPolicy')}&nbsp;
                            </span>
                            {this.props.i18n?.t('and')}&nbsp;
                            <span
                              data-test-id="TermsConditions"
                              onClick={() =>
                                this.handleNavigation("TermsConditions", values)
                              }
                              style={webStyle2.hightlightText}
                            >
                              {this.props.i18n?.t('terms&condition')}
                            </span>
                          </p>
                        </Box>
                      </Box>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        style={webStyle2.SubmitBtn}
                        className="SubmitBtn"
                      >
                        {this.handleCondition(this.state.googleLoginStatus,this.props.i18n?.t('updateProfile'),this.props.i18n?.t('capitalizeSignUp'))}
                      </Button>
                      {!this.state.googleLoginStatus &&(
                        <>
                      <Box style={webStyle2.BoxLine}>
                        <Box style={webStyle2.line}></Box>
                        <Typography
                          style={webStyle2.orstyle}
                          className="orstyle"
                        >
                          {this.props.i18n?.t('or')}
                        </Typography>
                        <Box style={webStyle2.line}></Box>
                      </Box>
                      <GoogleOAuthProvider clientId={this.state.googleClientId}>
                      <Box style={{position:"relative",width:"90%"}}>
                        <Button
                        variant="contained"
                        fullWidth
                        ref={this.state.selectRef}
                        style={{
                          height: '44px',
                          padding: '10px 16px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '8px',
                          borderRadius: '8px',
                          border: '1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)',
                          background:'#FFFFFF',
                          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
                          textTransform:'none',

                          fontFamily: "DIN Next LT Arabic Regular",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "22px",
                          textAlign: "left",
                          color: "#000000",
                          width:"100%"
                        }}
                        >
                          <img alt="google" src={this.state.imgGoogleIcon} />
                          &nbsp;
                          {this.props.i18n?.t('signUpWithGoogle')}
                        </Button>
                        <Button
                         data-test-id="googleLogin"
                        style={{
                          left:0,
                          top:0,
                          position:"absolute",
                          width:"100%",
                          opacity:0
                        }}>
                          <GoogleLogin
                          onSuccess={this.handleLoginSuccess}
                          onError={this.handleLoginFailure}
                          useOneTap={true}
                          width={String(this.state.selectWidth)}
                        />
                        </Button>
                      </Box>
                  </GoogleOAuthProvider>
                      <Typography style={webStyle2.alreadyStyle}>
                      {this.props.i18n?.t('alreadyHaveAccount')}&nbsp;{" "}
                        <Typography
                          style={webStyle2.pointer}
                          onClick={this.handleSigninClick}
                        >
                          <Typography style={webStyle2.signIn}>
                          {this.props.i18n?.t('capitalizeSignIn')}
                          </Typography>
                        </Typography>
                      </Typography>
                     </>  )}
                    </form>
                  </Box>
                )}
              </Formik>
              <SignupSuccessModal
                open={this.state.signupModal}
                handleClose={this.handleSignupModalClose}
              />
            </Box>
            <StyledDialog open={this.state.openDialogue} onClose={this.handleCloseDialogue}  BackdropComponent={Backdrop}
                BackdropProps={{ invisible: true }}>
                <DialogContent className="dialogContent">
                  {this.state.showSuccessMessage &&(<>
                  <Typography className="DialogContentHeaderTypo">{this.props.i18n?.t('success')}</Typography>
                  <Typography className="DialogContentTypo">{this.props.i18n?.t('account_created_successfully')}</Typography>
                  </> )}
                  {this.state.showSuccessMessageProfile &&(<>
                  <Typography className="DialogContentHeaderTypo">{this.props.i18n?.t('success')}</Typography>
                  <Typography className="DialogContentTypo">{this.props.i18n?.t('profile_updated_successfully')}</Typography>
                  </> )}
                  {this.state.showSuccessMessageUpdate &&(<>
                  <Typography className="DialogContentHeaderTypo">{this.props.i18n?.t('updated')}</Typography>
                  <Typography className="DialogContentTypo">{this.props.i18n?.t('deatils_update_social_account')}</Typography>
                  </> )}
                </DialogContent>
                <Divider/>
                <DialogActions className="DialogAction">
                  <Typography   className="DialogActionTypo"><Button onClick={this.handleCloseDialogue}>{this.props.i18n?.t('ok')}</Button></Typography>
                </DialogActions>
            </StyledDialog>
          </Box>
          
        </ThemeProvider>
      </this.MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
      backgroundColor: 'white',
      borderRadius: '8px',
      [theme.breakpoints.down('xs')]:{
          borderRadius: '8px 8px 32px 8px',

      },
   "& .DialogContentHeaderTypo":{
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#1E293B",
    lineHeight: "18px",
    fontFamily: "DIN Next LT Arabic Bold",
    

   }, 
   "& .DialogContentTypo":{
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: 700,
    color: "#1E293B",
    lineHeight: "18px",
    fontFamily: "DIN Next LT Arabic Regular",
    marginTop:'10px'

    
   },
   "& .DialogAction":{
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    
   },
   "& .DialogActionTypo":{
    fontSize: "16px",
    fontWeight: 700,
    color: "blue",
    lineHeight: "18px",
    fontFamily: "DIN Next LT Arabic Regular",
    cursor:"pointer"
    
   },  
    
  },
}));


const investment = [{ label: "Investment", value: "Investment" }];

const CustomDatePicker = styled(Box)({
  borderRadius: '8px',
  position: 'relative' as 'relative',
  boxSizing: "border-box",
  "& .MuiInput-underline::after, .MuiInput-underline::before": {
    display: 'none'
  },
  "& .reactCalendarBox":{
    [theme.breakpoints.down(400)]: {
      left:0
     },
    width: "100%",
    top: 25,
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    flexDirection:"column" as "column",
    alignItems: "flex-end",
    right: 0,
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  "& .react-calendar": {
    [theme.breakpoints.down(450)]: {
      width:"275px"
    },
    width:"330px",
    border: "none",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#0F172A",
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button":{
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#94A3B8", 
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    color: '#0F172A',
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    textDecoration: 'none',
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    width: '50px',
    height: '50px',
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#F1F5F9',
    color: '#044352',
    borderRadius: '0px',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    background: '#F1F5F9', 
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    background: '#F1F5F9',
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    background: '#1B4FE4',
    color: '#F8FAFC',
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
},
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .action-button-div": {
    [theme.breakpoints.down(450)]: {
      width:"calc(275px - 30px)"
    },
    width:"calc(330px - 30px)",
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    gap: '20px',
    backgroundColor: "#FFFFFF"
  },
  "& .action-button-div button": {
    width: '140px',
    height: '50px',
    borderRadius: '7.21px',
    fontFamily: "DIN Next LT Arabic Regular",
    textTransform: 'capitalize',
    fontSize: '14.42px',
  },
  "& .action-button-div .cancel-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
  "& .action-button-div .save-btn.active": {
    background: '#1B4FE4',
    color: '#FFFFFF'
  },
  "& .action-button-div .save-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
});

const countryCodeList = [
  { label: "+93", value: "93" },
  { label: "+355", value: "355" },
  { label: "+213", value: "213" },
  { label: "+376", value: "376" },
  { label: "+244", value: "244" },
  { label: "+1-268", value: "1-268" },
  { label: "+54", value: "54" },
  { label: "+374", value: "374" },
  { label: "+61", value: "61" },
  { label: "+43", value: "43" },
  { label: "+994", value: "994" },
  { label: "+1-242", value: "1-242" },
  { label: "+973", value: "973" },
  { label: "+880", value: "880" },
  { label: "+1-246", value: "1-246" },
  { label: "+375", value: "375" },
  { label: "+32", value: "32" },
  { label: "+501", value: "501" },
  { label: "+229", value: "229" },
  { label: "+975", value: "975" },
  { label: "+591", value: "591" },
  { label: "+387", value: "387" },
  { label: "+267", value: "267" },
  { label: "+55", value: "55" },
  { label: "+673", value: "673" },
  { label: "+359", value: "359" },
  { label: "+226", value: "226" },
  { label: "+257", value: "257" },
  { label: "+238", value: "238" },
  { label: "+855", value: "855" },
  { label: "+237", value: "237" },
  { label: "+1", value: "1" },
  { label: "+236", value: "236" },
  { label: "+235", value: "235" },
  { label: "+56", value: "56" },
  { label: "+86", value: "86" },
  { label: "+57", value: "57" },
  { label: "+269", value: "269" },
  { label: "+242", value: "242" },
  { label: "+506", value: "506" },
  { label: "+385", value: "385" },
  { label: "+53", value: "53" },
  { label: "+357", value: "357" },
  { label: "+420", value: "420" },
  { label: "+243", value: "243" },
  { label: "+45", value: "45" },
  { label: "+253", value: "253" },
  { label: "+1-767", value: "1-767" },
  { label: "+1-809", value: "1-809" },
  { label: "+670", value: "670" },
  { label: "+593", value: "593" },
  { label: "+20", value: "20" },
  { label: "+503", value: "503" },
  { label: "+240", value: "240" },
  { label: "+291", value: "291" },
  { label: "+372", value: "372" },
  { label: "+268", value: "268" },
  { label: "+251", value: "251" },
  { label: "+679", value: "679" },
  { label: "+358", value: "358" },
  { label: "+33", value: "33" },
  { label: "+241", value: "241" },
  { label: "+220", value: "220" },
  { label: "+995", value: "995" },
  { label: "+49", value: "49" },
  { label: "+233", value: "233" },
  { label: "+30", value: "30" },
  { label: "+1-473", value: "1-473" },
  { label: "+502", value: "502" },
  { label: "+224", value: "224" },
  { label: "+245", value: "245" },
  { label: "+592", value: "592" },
  { label: "+509", value: "509" },
  { label: "+504", value: "504" },
  { label: "+36", value: "36" },
  { label: "+354", value: "354" },
  { label: "+91", value: "91" },
  { label: "+62", value: "62" },
  { label: "+98", value: "98" },
  { label: "+964", value: "964" },
  { label: "+353", value: "353" },
  { label: "+972", value: "972" },
  { label: "+39", value: "39" },
  { label: "+225", value: "225" },
  { label: "+1-876", value: "1-876" },
  { label: "+81", value: "81" },
  { label: "+962", value: "962" },
  { label: "+7", value: "7" },
  { label: "+254", value: "254" },
  { label: "+686", value: "686" },
  { label: "+383", value: "383" },
  { label: "+965", value: "965" },
  { label: "+996", value: "996" },
  { label: "+856", value: "856" },
  { label: "+371", value: "371" },
  { label: "+961", value: "961" },
  { label: "+266", value: "266" },
  { label: "+231", value: "231" },
  { label: "+218", value: "218" },
  { label: "+423", value: "423" },
  { label: "+370", value: "370" },
  { label: "+352", value: "352" },
  { label: "+261", value: "261" },
  { label: "+265", value: "265" },
  { label: "+60", value: "60" },
  { label: "+960", value: "960" },
  { label: "+223", value: "223" },
  { label: "+356", value: "356" },
  { label: "+692", value: "692" },
  { label: "+222", value: "222" },
  { label: "+230", value: "230" },
  { label: "+52", value: "52" },
  { label: "+691", value: "691" },
  { label: "+373", value: "373" },
  { label: "+377", value: "377" },
  { label: "+976", value: "976" },
  { label: "+382", value: "382" },
  { label: "+212", value: "212" },
  { label: "+258", value: "258" },
  { label: "+95", value: "95" },
  { label: "+264", value: "264" },
  { label: "+674", value: "674" },
  { label: "+977", value: "977" },
  { label: "+31", value: "31" },
  { label: "+64", value: "64" },
  { label: "+505", value: "505" },
  { label: "+227", value: "227" },
  { label: "+234", value: "234" },
  { label: "+850", value: "850" },
  { label: "+389", value: "389" },
  { label: "+47", value: "47" },
  { label: "+968", value: "968" },
  { label: "+92", value: "92" },
  { label: "+680", value: "680" },
  { label: "+970", value: "970" },
  { label: "+507", value: "507" },
  { label: "+675", value: "675" },
  { label: "+595", value: "595" },
  { label: "+51", value: "51" },
  { label: "+63", value: "63" },
  { label: "+48", value: "48" },
  { label: "+351", value: "351" },
  { label: "+974", value: "974" },
  { label: "+40", value: "40" },
  { label: "+7", value: "7" },
  { label: "+250", value: "250" },
  { label: "+1-869", value: "1-869" },
  { label: "+1-758", value: "1-758" },
  { label: "+1-784", value: "1-784" },
  { label: "+685", value: "685" },
  { label: "+378", value: "378" },
  { label: "+239", value: "239" },
  { label: "+966", value: "966" },
  { label: "+221", value: "221" },
  { label: "+381", value: "381" },
  { label: "+248", value: "248" },
  { label: "+232", value: "232" },
  { label: "+65", value: "65" },
  { label: "+421", value: "421" },
  { label: "+386", value: "386" },
  { label: "+677", value: "677" },
  { label: "+252", value: "252" },
  { label: "+27", value: "27" },
  { label: "+82", value: "82" },
  { label: "+211", value: "211" },
  { label: "+34", value: "34" },
  { label: "+94", value: "94" },
  { label: "+249", value: "249" },
  { label: "+597", value: "597" },
  { label: "+46", value: "46" },
  { label: "+41", value: "41" },
  { label: "+963", value: "963" },
  { label: "+886", value: "886" },
  { label: "+992", value: "992" },
  { label: "+255", value: "255" },
  { label: "+66", value: "66" },
  { label: "+228", value: "228" },
  { label: "+676", value: "676" },
  { label: "+1-868", value: "1-868" },
  { label: "+216", value: "216" },
  { label: "+90", value: "90" },
  { label: "+993", value: "993" },
  { label: "+688", value: "688" },
  { label: "+256", value: "256" },
  { label: "+380", value: "380" },
  { label: "+971", value: "971" },
  { label: "+44", value: "44" },
  { label: "+1", value: "1" },
  { label: "+598", value: "598" },
  { label: "+998", value: "998" },
  { label: "+678", value: "678" },
  { label: "+379", value: "379" },
  { label: "+58", value: "58" },
  { label: "+84", value: "84" },
  { label: "+967", value: "967" },
  { label: "+260", value: "260" },
  { label: "+263", value: "263" },
];

const webStyle2 = {
  mainContainer: {
    display: "flex",
  },
  pointer: { cursor: "pointer" },
  imgContainer: {
    height: "auto",
    width: "50%",
    backgroundColor: "#122A6A",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "4rem",
    paddingBottom: "4rem",
    paddingTop: "4rem",
  },
  imgStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "flex-start",
  },
  signIn: {
    color: "#1B4FE4",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  imgCon: { width: "100%" },
  alreadyStyle: {
    display: "flex",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  passwordCalculator: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    gap: "8px",
  },
  img: { width: "60%", marginBottom: "3rem" },
  passwordCalBox: {
    backgroundColor: "",
    display: "flex",
    gap: "5px",
    width: "100%",
  },
  passwordContent: { width: "100%", height: "10px", borderRadius: "10px" },
  passwordContentBox: {
    width: "37%",
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  imgBox: {
    border: "1px dashed #B0BACD",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    padding: "1rem 0",
    marginTop: "1rem",
  },
  imgBox2: {
    border: "1px solid #B0BACD",
    color: "#0F172A",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "2rem",
    padding: "10px 8px 10px 8px",
    background: "#CBD5E1",
    height: "35px",
  },
  pdfStyle: { width: "24px", height: "24px" },
  passwordInput: { border: "none", outline: "none", fontSize: "16px",
  fontFamily: "DIN Next LT Arabic Regular", background: "none"},
  pdfBox: { display: "flex", alignItems: "center", gap: "10px" },
  imgText: {
    color: "#F4F4EA",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    gap: "1rem",
  },
  imgText2: {
    textAlign: "center" as "center",
    fontWeight: 500,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  imgText1: {
    textAlign: "center" as "center",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  signUp: {
    fontWeight: 400,
    fontSize: "14px",
    width: "89%",
    border: "1px solid #E2E8F0",
    boxShadow: "0px 2px 8px 0px #00000014",
  },
  signupText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
  signWithGoogle: {
    textTransform: "none" as "none",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  imgPopUpText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#1E293B",
    lineHeight: "18px",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  logo: { width: "20rem" },
  fileStyle: { display: "none" },
  container: {
    height: "auto",
    alignItems: "center",
    position: "relative" as const
  },
  uploadText1: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#1E293B",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  fileupLoad: { width: "50px" },
  uploadText2: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  BoxLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "89%",
  },
  line: {
    flex: "1",
    height: "1px",
    backgroundColor: "#CBD5E1",
  },
  validationStyle: {
    marginTop: "2px",
    fontSize: "14px",
    color: "#f94b4b",
  },
  orstyle: {
    color: "#64748B",
    textAlign: "center" as "center",
    padding: "10px",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  mainHeading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "ceter",
    textAlign: "center" as "center",
    fontWeight: 700,
    width: "90%",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  nameContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  formData: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  nameInput: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "6px",
  },
  passwordIconStyle: { width: "1rem", height: "1rem" },
  labeltext: {
    display: "flex",
    color: "#334155",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5rem",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  passwordPopBox: {
    position: "fixed" as "fixed",
    top: 0,
    right: 0,
    height: "100%",
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    alignItems: "center",
  },
  passwordBoxx: {
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "#FFF",
    top: 0,
    zIndex: "31",
    padding: "24px",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  passBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
    alignItems: "flex-end",
  },
  passBox2: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  passText: {
    fontSize: "20px",
    color: "#0F172A",
    lineHeight: "28px",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  redIcon: { color: "red" },
  phoneSelectStyle: {
    backgroundColor: "#EFF6FF",
    width: "4.75rem",
  },
  phoneInputStyle: {
    border: "none",
    outline: "none",
    padding: "0.5rem 3rem",
    fontSize:"16px",
  },
  inputText: {
    borderRadius: "8px",
    fontWeight: 400,
    fontSize: "16px",
    padding: "0",
  },
  inputTextForSelect: {
    borderRadius: "8px",
    fontWeight: 400,
    fontSize: "16px",
    padding: "0.8rem",
    width: "100%",
  },
  nameInputText: {
    width: "calc(100% - 1.7rem)",
    borderRadius: "8px",
    fontWeight: 400,
    border: "1px solid #CBD5E1",
    padding: "0.8rem",
    fontSize: "16px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  checkboxtextWithError: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "89%",
    gap: "1rem",
  },
  agreeText: {
    gap: "0.2rem",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  checkboxText: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    gap: "1rem",
  },
  checkboxColor: { color: "#1B4FE4", width: "20px", height: "20px" },
  hightlightText: {
    fontWeight: 700,
    color: "#1948CB",
    fontFamily: "DIN Next LT Arabic Bold",
    cursor: "pointer",
    textDecoration: "none",
  },
  SubmitBtn: {
    width: "89%",
    backgroundColor: "#1B4FE4",
    color: "white",
    fill: "red",
    textTransform: "none" as "none",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  termAndConditionError: {
    borderRadius: "4px",
    borderLeft: "4px solid #DC2626",
    padding: "1.6% 1.4%",
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: "14px",
  },
};

export const EmailAccountRegistrationWeb = withTranslation()(EmailAccountRegistration);
// Customizable Area End
