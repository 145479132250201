Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.newsLetterEndPoint = "bx_block_navmenu/newsletter_subscribers";

exports.btnExampleTitle = "CLICK ME";
exports.landingpageGetApiEndpint = "bx_block_landingpage2/about_us";
// Customizable Area End