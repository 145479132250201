import React, { useState } from 'react';
import {
    Button,
    Menu,
    MenuItem,
    makeStyles,
    Theme,
    createStyles,
    PopoverOrigin
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useSortStates, { SortOption } from './SortHook.web';
import { useTranslation } from 'react-i18next';

interface SortDropdownProps {
    handleSortingChange: (sortBy: string, order: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: '#1B4FE4',
            textTransform: 'initial',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            whiteSpace: 'nowrap'

        },
        menu: {
            marginTop: theme.spacing(1),
        },
        menuItem: {
            '&.MuiMenuItem-root': {
                fontFamily: "DIN Next LT Arabic Regular",
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                '&.Mui-selected': {
                    backgroundColor: '#1B4FE4 !important',
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#1B4FE4 !important',
                    },
                },
                '&:hover': {
                    backgroundColor: 'rgba(27, 79, 228, 0.08)',
                },
            },
        },
        rtlMenu: {
            direction: 'rtl',
        },
        rtlMenuItem: {
            textAlign: 'right',
        },
    })
);

const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
};


const SortDropdown: React.FC<SortDropdownProps> = ({ handleSortingChange }) => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { handleSort } = useSortStates();
    const [selectedOption, setSelectedOption] = useState<SortOption | ''>('');

    const isArabic = i18n.language === 'ar';

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option: SortOption) => {
        const { newSortBy, newOrder } = handleSort(option);
        setSelectedOption(option);
        handleSortingChange(newSortBy, newOrder);
        handleClose();
    };

    const sortOptions: SortOption[] = [
        t('sortOptions.request_id_asc'),
        t('sortOptions.request_id_desc'),
        t('sortOptions.request_date_new_old'),
        t('sortOptions.request_date_old_new'),
        t('sortOptions.transaction_amount_high_low'),
        t('sortOptions.transaction_amount_low_high'),
        t('sortOptions.transaction_type_deposit'),
        t('sortOptions.transaction_type_withdrawal'),
        t('sortOptions.status_completed'),
        t('sortOptions.status_pending'),
        t('sortOptions.status_rejected')
    ];

    return (
        <div>
            <Button
                className={classes.button}
                aria-controls="sort-menu"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={!isArabic && <KeyboardArrowDownIcon style={{ color: '#1B4FE4' }} />}
                startIcon={isArabic && <KeyboardArrowDownIcon style={{ color: '#1B4FE4' }} />}
            >
                {t('sort_by')}
            </Button>
            <Menu
                id="sort-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                className={`${classes.menu} ${isArabic ? classes.rtlMenu : ''}`}
                getContentAnchorEl={null}
            >
                {sortOptions.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={() => handleMenuItemClick(option)}
                        selected={selectedOption === option}
                        classes={{
                            root: `${classes.menuItem} ${isArabic ? classes.rtlMenuItem : ''}`,
                            selected: classes.menuItem,
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default SortDropdown;

