export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editButtonIcon = require("../assets/editButtonIcon.svg");
export const verifyIcon = require("../assets/verifyIcon.svg");
export const editIcon = require("../assets/editIcon.svg");
export const uploadIcon = require("../assets/uploadIcon.png");
export const crossIcon = require("../assets/cross.svg");
export const pdfImage = require("../assets/pdfImage.png");
export const pngImage = require("../assets/png.png");
export const flagGerman = require("../assets/flagGerman.svg");
export const flagUK = require("../assets/flagUK.svg");
export const flagSaudi = require("../assets/flagSaudi.svg");
export const uploadIconSmall = require("../assets/uploadIconSmall.svg");
export const crossIconButton = require("../assets/crossIcon.svg")
export const arrow = require("../assets/arrow.svg");
export const arrowSide = require("../assets/arrowSide.svg");
export const logg = require("../assets/logg.svg");
export const leftA = require("../assets/leftA.png")

