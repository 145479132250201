import React from "react";

// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { dashboard, logout, threeLine, leftIcon, defaultProfile, dashboardGray, rightArrow } from "./assets";
import LogoutModal from "../../../components/src/LogoutModal.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .container": {
      [theme.breakpoints.down(900)]: {
        display: "flex",
        width: "250px",
        padding: "4rem 1rem 7rem 3rem",
        background: "#122967",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "fixed",
      },
      [theme.breakpoints.down(361)]: {
        display: "flex",
        width: "220px",
        padding: "4rem 1rem 7rem 2rem",
        background: "#122967",
        alignItems: "center",
        justifyContent: "flex-start",
      },
      background: "#122967",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      width: "300px",
      height: "calc(100vh - 9rem)",
      overflow: "scroll",
      padding: "4rem 1rem 5rem 2.5rem",
      "&::-webkit-scrollbar": {
        display: "none",
    },
    },
    "& .profileDetails": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "5rem",
      width: "100%",
    },
    "& .profileImage": {
      [theme.breakpoints.down(900)]: {},
      borderRadius: "50%",
      width: "20%",
      height: "40%",
    },
    "& .profileItems": {
      cursor:"pointer",
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "1rem",
      width: "100%",
    },
    "& .profileData": {
      [theme.breakpoints.down(900)]: {
        gap: "3px",
      },
      display: "flex",
      flexDirection: "column",
      color: "#FFFFFF",
      gap: "6px",
    },
    "& .profileName": {
      [theme.breakpoints.down(900)]: {
        fontSize: "18px",
      },
      fontSize: "20px",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .profileInfo": {
      [theme.breakpoints.down(900)]: {
        fontSize: "1rem",
        fontFamily: "DIN Next LT Arabic regular",
      },
      fontFamily: "DIN Next LT Arabic regular",
      fontSize: "1rem",
      fontWeight: 400,
    },
    "& .profileListItems": {
      [theme.breakpoints.down(900)]: {
        gap: "1rem",
        padding: "5px 0",
      },
      display: "flex",
      gap: "1rem",
      height: "3.6rem",
      alignItems: "center",
      padding: "10px 0",
    },
    "& .profileDashboard": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      gap: "20px",
      width: "80%",
      height: "3.6rem",
      color: "#FFFFFF",
      alignItems: "center",
    },
    "& .profileItemsName": {
      [theme.breakpoints.down(900)]: {},
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "18px",
      fontWeight: 700,
      cursor: 'pointer'
    },
    "& .profileMidList": {
      [theme.breakpoints.down(900)]: {},
      width: "100%",
    },
    "& .dash": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      gap: "20px",
    },
    "& .profileImageBox": {
      [theme.breakpoints.down(900)]: {
        width: "52px",
        height: "52px",
      },
      width: "58px",
      height: "58px",
    },
    "& .DashBoard": {
      [theme.breakpoints.down(900)]: {},
      fontSize: "18px",
      fontFamily: "DIN Next LT Arabic Bold",
      cursor: 'pointer'
    },
    "& .SideLogout": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      flexDirection: "row",
      gap: "1rem",
      color: "#94A3B8",
      padding: "16px 0",
      paddingTop: "6rem",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .log": {
      [theme.breakpoints.down(900)]: {
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Bold",
      },
      fontSize: "18px",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
      cursor: 'pointer'
    },
    "& .threeIcon": {
      [theme.breakpoints.down(900)]: {
        display: "flex",
        margin: "10px",
        cursor: "pointer",
      },
      display: "none",
    },
    "& .analytics": {
      [theme.breakpoints.down(900)]: {
        paddingBottom: "0.5rem",
      },
      color: "#94A3B8",
      paddingBottom: "1rem",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .profileImageStyle": {
      [theme.breakpoints.down(900)]: {},
      overflow: "none",
      width: "100%",
      height: "100%",
      borderRadius: "100%",
    },
    "& .web": {
      [theme.breakpoints.down(900)]: {
        display: "none",
      },
    },
    "& .mobile": {
      [theme.breakpoints.up(900)]: {
        display: "none",
      },
    },
    "& .leftIcon": {
      [theme.breakpoints.down(900)]: {
        display: "block",
        cursor: "pointer",
      },
      display: "none",
    },
    "& .forMobile":{
      display:"none"
    }
  }));

  list = () => (
    <Box className="container">
    <Box className="profileDetails" style={{direction: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}}>
      <Box className="profileItems" data-test-id="navigateToUserProfileBlock">
        <Box className="profileImageBox" onClick={this.navigateToUserProfileBlock}>
          <img src={this.handleCondition(this.state.userImage , this.state.userImage , defaultProfile)} className="profileImageStyle" />
        </Box>
        <Box className="profileData" onClick={this.navigateToUserProfileBlock}>
          <Typography  className="profileName">{this.props.userFirstName && this.props.userLastName?this.props.userFirstName+" "+this.props.userLastName:this.state.userName}</Typography>
        </Box>
        <Box className="leftIcon">
          <img src={this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar' , rightArrow , leftIcon)} onClick={this.toggleDrawer} />
        </Box>
      </Box>
      <Box className="dash" style={{color: this.getActiveColor("/Dashboard")}}>
        <img
          src={this.handleCondition(this.state.currentRoute === "/Dashboard" , dashboard , dashboardGray)}
          data-test-id="dashLine"
          onClick={this.dashboardNavigation}
        />
        <Typography onClick={this.dashboardNavigation} className="DashBoard">{this.handleCondition(this.props.i18n?.t('dashboard') === undefined , "Dashboard" , this.props.i18n?.t('dashboard'))}</Typography>
      </Box>
      <Box className="profileMidList">
        <Typography className="analytics">{this.props.i18n?.t('ANALYTICS') === undefined ? "Analytics" : this.props.i18n?.t('ANALYTICS')}</Typography>
        {this.state.sideData.map(
          (item: { iconActive: string; iconInactive: string; defaulttext: string; name: string; link:() => void; pathName: string}, index: number) => (
            <Box key={index} className="profileListItems" style={{color: this.getActiveColor(item.pathName)}}>
              <img src={this.handleCondition(item.pathName === this.state.currentRoute , item.iconActive , item.iconInactive)} onClick={item.link} />
              <Typography data-test-id="link" onClick={item.link} className="profileItemsName">
                {this.handleCondition(this.props.i18n?.t(item.name) === undefined , item.defaulttext , this.props.i18n?.t(item.name))}
              </Typography>
            </Box>
          )
        )}
      </Box>
    </Box>
    <Box
        style={{direction: this.handleCondition(this.props.i18n?.language === 'ar' , 'rtl' , 'ltr')}}
      className="SideLogout">
      <img src={logout} />
      <Typography data-test-id="logoutBtn" onClick={this.openLogoutModal} className="log">{this.handleCondition(this.props.i18n?.t('Logout') === undefined , "Logout" , this.props.i18n?.t('Logout'))}</Typography>
      <Typography className="forMobile">Logout</Typography>
    </Box>
    <LogoutModal 
    open={this.state.logoutModal}
    handleClose={this.closeLogoutModal}
    handleLogout={this.logOutAndNavigate}
    />
  </Box>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <this.MainWrapper>
        <ThemeProvider theme={theme}>
          {!this.state.webDrawer && (
            <img
              src={threeLine}
              className="threeIcon"
              onClick={this.toggleDrawer}
            />
          )}
          {this.handleCondtion(
            this.state.webDrawer,
            <div className="mobile">{this.list()}</div>,
            <></>
          )}
          <div className="web">{this.list()}</div>
        </ThemeProvider>
      </this.MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const NavigationMenuWeb = withTranslation()(NavigationMenu)
// Customizable Area End
