// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import WithdrawFormsControllerWeb, {
  Props,
} from "./WithdrawFormsController.web";
import {
  backIcon,
  calendar,
  cross2,
  crossIcon,
  dropDown,
  exIcon,
  smlIcon,
} from "./assets";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
// Customizable Area End

export default class WithdrawForms extends WithdrawFormsControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  MainWrapper = styled(Box)(({ theme }) => ({
    "& .mainContainer": {
      [theme.breakpoints.down(598)]: {
        padding: "40px 20px",
        width: "calc(100% - 40px)",
      },
      width: "calc(100% - 120px)",
      height: "100%",
      padding: "40px 60px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "40px",
    },
    "& .backIcon": {
      [theme.breakpoints.down(598)]: {
        left: "0px",
      },
      width: "20px",
      position: "relative",
      left: "82px",
    },
    "& .formContainer": {
      [theme.breakpoints.down(598)]: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: "0px",
        gap: "16px",
      },
      width: "calc(100% - 140px)",
      padding: "0 70px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "48px",
    },
    "& .heading": {
      [theme.breakpoints.down(598)]: {
        fontSize: "20px",
        color: "#1E293B"
      },
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "30px",
      color: "#000000"
    },
    "& .headingBox": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
      },
      width: "60%"
    },
    "& .subHeading": {
      [theme.breakpoints.down(598)]: {
        fontSize: "16px",
        color: "#334155"
      },
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "18px",
      color: "#000000"
    },
    "& .noteBox": {
      [theme.breakpoints.down(598)]: {
        width: "calc(100% - 32px)",
      },
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "8px",
      padding: "12px 16px",
      backgroundColor: "#F1F5F9",
      borderLeft: "4px solid #143288",
      width: "55%",
      borderRadius: "4px",
    },
    "& .note": {
      [theme.breakpoints.down(598)]: {},
      fontSize: "14px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .formBox": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
    },
    "& .sendBefore": {
      [theme.breakpoints.down(598)]: {
        width: "95%"
      },
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#334155",
      width: "30%",
      padding: "16px",
      backgroundColor: "#F1F5F9",
      borderRadius: "8px",
      textAlign: "center",
      boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.03)",
      marginTop: "40px",
      textTransform: "none",
    },
    "& .labelInputBox2": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "4px",
    },
    "& .sendAfter": {
      [theme.breakpoints.down(598)]: {
        width: "95%"
      },
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#FFFFFF",
      width: "30%",
      padding: "16px",
      backgroundColor: "#1B4FE4",
      borderRadius: "8px",
      textAlign: "center",
      boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.03)",
      marginTop: "40px",
      textTransform: "none",
    },
    "& .label": {
      [theme.breakpoints.down(598)]: {},
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "16px",
      color: "#334155",
    },
    "& .star": {
      [theme.breakpoints.down(598)]: {},
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "16px",
      color: "#DC2626",
      marginLeft: "5px",
    },
    "& .inputBox": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "8px",
    },
    "& .inputBox2": {
      [theme.breakpoints.down(960)]: {
        width: "100%"
      },
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "8px",
      width: "calc(50% - 18px)"
    },
    "& .selectInput": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "8px",
      border: "1px solid #CBD5E1",
      padding: "0 8px",
      borderRadius: "8px",
      width: "calc(100% - 16px)",
    },
    "& .selectInput2": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "flex-start",
      gap: "8px",
      border: "1px solid #CBD5E1",
      padding: "0px 8px",
      borderRadius: "8px",
      width: "calc(100% - 16px)",
    },
    "& .phonecode": {
      [theme.breakpoints.down(598)]: {},
      width: "70px",
      backgroundColor: "#EFF6FF",
      cursor: "cursor",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .textCon": {
      [theme.breakpoints.down(598)]: {},
      width: "80%",
      border: "none",
      padding: "12px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      outline: "none"
    },
    "& .nameInputText": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      padding: "12px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      outline: "none",
    },
    "& .dateBox": {
      [theme.breakpoints.down(598)]: {},
      width: "calc(100% - 24px)",
      display: "flex",
      padding: "12px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      outline: "none",
    },
    "& .dateInput": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      outline: "none",
      border: "none",
    },
    "& .selectDate": {
      width: 0,
      border: "none",
    },
    "& .omrContainer": {
      [theme.breakpoints.down(598)]: {},
      padding: "12px",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      position: "absolute",
      zIndex: 2,
      boxShadow: "0px 2px 4px 0px #00000026",
      border: "2px solid #F5F5F5",
      height: "200px",
      minHeight: "40px",
      overflow: "scroll"
    },
    "& .backBox": {
      [theme.breakpoints.down(938)]: {        width: "calc(55% - 48px)"     },
      [theme.breakpoints.down(853)]: { width: "calc(60% - 48px)" },
      [theme.breakpoints.down(782)]: {     width: "calc(65% - 48px)"   },
      [theme.breakpoints.down(722)]: {

          width: "calc(70% - 48px)"},
      [theme.breakpoints.down(670)]: {
          width: "calc(75% - 48px)"},
      [theme.breakpoints.down(626)]: {width: "calc(80% - 48px)",
      },[theme.breakpoints.down(598)]: {width: "90%"},width: "calc(40% - 48px)",
    },
    "& .successContainer": {
      [theme.breakpoints.down(938)]: {
         width: "calc(55% - 48px)",
      },
      [theme.breakpoints.down(853)]: {
           width: "calc(60% - 48px)"},
      [theme.breakpoints.down(782)]: {  width: "calc(65% - 48px)" },
      [theme.breakpoints.down(722)]: {        width: "calc(70% - 48px)"
      },
      [theme.breakpoints.down(670)]: {        width: "calc(75% - 48px)",      },
            [theme.breakpoints.down(626)]: {
        width: "calc(80% - 48px)",      },
      [theme.breakpoints.down(598)]: {
        width: "90%",      },
      width: "calc(40% - 48px)",
    },
    "& .viewStatus": {
      [theme.breakpoints.down(598)]: {
        width: "50%",
      },
      width: "30%",
    },
    "& .accountInfo": {
      [theme.breakpoints.down(598)]: {
        width: "calc(100% - 26px)",
      },
      backgroundColor: "#F1F5F9",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "8px",
      borderRadius: "4px",
      padding: "8px 20px 8px 6px",
      marginTop:"4px"
    },
    "& .nameInfo": {
      [theme.breakpoints.down(598)]: {},
      fontSize: "12px",
      fontFamily: "DIN Next LT Arabic Regular",
      color: "#334155",
    },
    "& .exampleDate": {
      [theme.breakpoints.down(598)]: {},
      fontSize: "10px",
      fontFamily: "DIN Next LT Arabic Regular",
      color: "#94A3B8",
    },
    "& .textBox": {
      [theme.breakpoints.down(598)]: {
        padding: "16px 24px",
      },
      padding: "40px 40px 15px 40px",
    },
    "& .mobileText": {
      [theme.breakpoints.up(598)]: {
        display: "none",
      },
      [theme.breakpoints.down(598)]: {
        display: "flex",
        justifyContent: "space-between",
        gap: "5px",
      },
    },
    "& .sureForMobile": {
      [theme.breakpoints.down(598)]: {
        fontSize: "18px",
        fontFamily: "DIN Next LT Arabic Bold",
        color: "#334155",
      },
    },
    "& .crossStyle": {
      [theme.breakpoints.down(598)]: {
        width: "40px",
        height: "40px",
      },
    },
    "& .surefor": {
      [theme.breakpoints.down(598)]: {
        display: "none",
      },
      paddingBottom:"16px"
    },
    "& .backfor": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
        fontFamily: "DIN Next LT Arabic Regular",
      },
      fontSize: "18px",
      fontFamily: "DIN Next LT Arabic Regular",
      color: "#475569",
    },
    "& .viewBox": {
      [theme.breakpoints.down(598)]: {
        width: "calc(90% - 20px)",
        padding: "20px 0 20px 20px",
      },
      padding: "20px",
      width: "calc(100% - 40px)",
    },
    "& .buttonBox": {
      [theme.breakpoints.down(598)]: {
        width: "calc(90% - 40px)",
      },
      width: "calc(100% - 40px)",
    },
    "& .calendarIcon": {
      position: "relative"
    },
    "& .crossIcon": {
      [theme.breakpoints.down(598)]: {
        display: "none",
      },
    },
    "& .successBox": {
      [theme.breakpoints.down(598)]: {
        padding: "16px 24px 15px 25px",
        gap: "8px",
      },
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "10px 40px 15px 40px",
    },
    "& .preferred-date": {
      [theme.breakpoints.down(960)]: {
        order: 2,
      },
    },
    "& .swift-code": {
      [theme.breakpoints.down(960)]: {
        order: 1,
      },
    },
  }));
  // Customizable Area End
  render() {
    // Customizable Area Start
    const minDate = new Date(
      new Date()
      .setDate(new Date().getDate() + 7))
    .toISOString()
    .split("T")[0]
    return (
      <this.MainWrapper>
        <Box className="mainContainer">
          <img
            src={backIcon}
            className="backIcon"
            onClick={this.handleOpenPopup}
          />
          {this.state.backPopup && (
            <Box style={webStyle2.boxContainer}>
              <Box style={webStyle2.backBox} className="backBox">
                <Box style={webStyle2.textBox} className="textBox">
                  <Box className="mobileText">
                    <Typography className="sureForMobile">
                      {" "}
                      Are you sure want to go back?
                    </Typography>
                    <img
                      src={cross2}
                      onClick={this.handleOpenPopup}
                      className="crossStyle"
                    />
                  </Box>
                  <Typography style={webStyle2.surefor} className="surefor">
                    Are you sure want to go back?
                  </Typography>
                  <Typography className="backfor">
                    Are you sure you want to go back? Changes you made so far
                    will not be saved.
                  </Typography>
                </Box>
                <Box style={webStyle2.buttonBox} className="buttonBox">
                  <button
                    style={webStyle2.cancel}
                    onClick={this.handleOpenPopup}
                  >
                    Cancel
                  </button>
                  <button 
                   onClick={this.handleBackClick}
                  style={webStyle2.back}
                  >
                    <Typography
                      style={webStyle2.linkColor}
                    >
                      Back
                    </Typography>
                  </button>
                </Box>
              </Box>
            </Box>
          )}
          {this.state.sendRequest && (
            <Box style={webStyle2.boxContainer}>
              <Box sx={webStyle2.successContainer} className="successContainer">
                <Box style={webStyle2.iconBox}>
                </Box>
                <Box className="successBox">
                  <Box className="mobileText">
                    <Typography className="sureForMobile">
                      {" "}
                      Successfully sent the request to admin
                    </Typography>
                    <img
                      src={cross2}
                      onClick={this.handleSendPopup}
                      className="crossStyle"
                    />
                  </Box>
                  <Typography style={webStyle2.surefor} className="surefor">
                    {" "}
                    Successfully sent the request to admin
                  </Typography>
                  <Typography className="backfor">
                    Your request been sent to admin once its approved the amount
                    will be withdraw from your account .
                  </Typography>
                </Box>
                <Box style={webStyle2.viewBox} className="viewBox">
                  <button onClick={this.handleBackClick} style={webStyle2.viewStatus} className="viewStatus">
                    View Status
                  </button>
                </Box>
              </Box>
            </Box>
          )}
          <Box className="formContainer">
            <Box className="headingBox">
              <Typography className="heading">
                One-Time Withdraw Order Form
              </Typography>
              <Typography className="subHeading">
                This form is dedicated for requesting a one-time withdrawal
                request for Al MaimoonWealth Management Investors.
              </Typography>
            </Box>
            <Box className="noteBox">
              <img src={exIcon} />
              <Typography className="note">
                Please note the transfer will take an average of 7-10 banking
                days to reach your bankaccount
              </Typography>
            </Box>
            <Box className="formBox">
              <Formik
                data-test-id="formik"
                validationSchema={this.withdrawSchema}
                initialValues={{
                  withdraw: "",
                  withdrawConfirmation: "",
                  bankName: "",
                  swiftCode: "",
                  accountName: "",
                  accountNum: "",
                  bankAddress: "",
                  phonecode: "OMR",
                  phonecodeConfirm: "OMR",
                  country: "United States",
                }}
                onSubmit={(values: {
                  withdraw: string,
                  withdrawConfirmation: string,
                  bankName: string,
                  swiftCode: string,
                  accountName: string,
                  accountNum: string,
                  bankAddress: string,
                  phonecode: string,
                  phonecodeConfirm: string,
                  country: string,
                }) => this.WithdrawFormPost(values)}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  touched,
                  errors,
                  values,
                }) => (
                  <form className="form" onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={5} sm={12}>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Box className="inputBox">
                          <Typography className="label">
                            Withdraw Value
                            <span className="star">*</span>
                          </Typography>
                          <Box className="selectInput"
                           style={{
                            border: this.handleCondition(
                              this.getErrorMessage(touched, errors, 'withdraw'),
                              '1px solid #F87171',""
                            )
                          }}
                          >
                            <TextField
                              className="phonecode"
                              data-test-id="withdrawDropdown"
                              onClick={this.withdrawDropdown}
                              value={values.phonecode}
                              InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img
                                      src={dropDown}
                                      alt="dropdown"
                                      onClick={handleChange}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <input
                              data-test-id="withdraw"
                              placeholder="Enter your withdraw value"
                              className="textCon"
                              id="withdraw"
                              name="withdraw"
                              value={values.withdraw}
                              onBlur={handleBlur}
                              onChange={(event) => {
                                const numOnly = event.target.value.replace(/\D/g, '')
                                setFieldValue("withdraw", numOnly)
                                this.setState({sendAfterDisabled: false})
                              }}
                            />
                          </Box>
                        </Box>
                        {this.state.withdrawalDropDown && (
                          <Box className="omrContainer">
                            {this.state.withdrawalOptions.map(
                              (
                                item: { label: string; value: string },
                                index: number
                              ) => (
                                <MenuItem
                                  data-test-id={`testCode${index}`}
                                  key={index}
                                  onClick={() => {
                                    setFieldValue("phonecode", item.label);
                                    this.setState({
                                      withdrawalDropDown: false,
                                    });
                                this.setState({sendAfterDisabled: false});
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              )
                            )}
                          </Box>
                        )}
                        {this.getErrorMessage(touched, errors, "withdraw")}
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Box className="inputBox">
                          <Typography className="label">
                            Withdraw Value Confirmation
                            <span className="star">*</span>
                          </Typography>
                          <Box className="selectInput"
                           style={{
                            border: this.handleCondition(
                              this.getErrorMessage(touched, errors, 'withdrawConfirmation'),
                              '1px solid #F87171',""
                            )
                          }}
                           >
                            <TextField
                              name="phonecodeConfirm"
                              className="phonecode"
                              fullWidth
                              value={values.phonecode}
                              onClick={this.withdrawDropdown2}
                              data-test-id="withdrawDropdown2"
                              InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img
                                      src={dropDown}
                                      alt="dropdown"
                                      onClick={handleChange}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <input
                              data-test-id="withdrawConfirmation"
                              placeholder="Enter your withdraw value confirmation"
                              className="textCon"
                              value={values.withdrawConfirmation}
                              name="withdrawConfirmation"
                              onBlur={handleBlur}
                              onChange={(event) => {
                                const numOnly = event.target.value.replace(/\D/g, '')
                                setFieldValue("withdrawConfirmation", numOnly)
                                this.setState({sendAfterDisabled: false})
                              }}
                            />
                          </Box>
                        </Box>
                        {this.state.withdrawalDropDown2 && (
                          <Box className="omrContainer">
                            {this.state.withdrawalOptions.map(
                              (
                                item: { label: string; value: string },
                                index: number
                              ) => (
                                <MenuItem
                                  data-test-id={`phonecodeConfirm${index}`}
                                  key={index}
                                  onClick={() => {
                                    setFieldValue(
                                      "phonecodeConfirm",
                                      item.value
                                    );
                                    this.setState({
                                      withdrawalDropDown2: false,
                                    });
                                this.setState({sendAfterDisabled: false})
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              )
                            )}
                          </Box>
                        )}
                        {this.getErrorMessage(
                          touched,
                          errors,
                          "withdrawConfirmation"
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Box className="inputBox">
                          <Typography className="label">
                            Bank Name
                            <span className="star">*</span>
                          </Typography>
                          <Box className="selectInput2"
                          style={{
                            border: this.handleCondition(
                              this.getErrorMessage(touched, errors, 'bankName'),
                              '1px solid #F87171',""
                            )
                          }}
                           >
                            <PhoneInput
                              data-test-id="countryId"
                              country={"us"}
                              enableAreaCodes={false}
                              enableTerritories={false}
                              onChange={this.handlePhoneChange}
                              inputProps={{
                                readOnly: true,
                              }}
                              containerStyle={{
                                width: "10%",
                              }}
                              inputStyle={{
                                display: "none",
                              }}
                              buttonStyle={{
                                width: "100%",
                                height: "32px",
                                backgroundColor: "#EFF6FF",
                                border: "none",
                                marginTop: "10px",
                              }}
                              dropdownStyle={{
                                width: "200px",
                              }}
                            />
                            <input
                              placeholder="Enter bank name"
                              className="textCon"
                              data-test-id="bankName"
                              name="bankName"
                              onBlur={handleBlur}
                              value={values.bankName}
                              onChange={(event) => {
                                const alpha = event.target.value.replace(/\d/g, '')
                                setFieldValue("bankName", alpha)
                                this.setState({sendAfterDisabled: false})
                              }}
                            />
                          </Box>
                        </Box>
                        {this.getErrorMessage(touched, errors, "bankName")}
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6} className="swift-code">
                            <Box className="inputBox">
                              <Typography className="label">
                                  Swift Code / IBAN
                                <span className="star">*</span>
                              </Typography>
                              <input
                               style={{
                                border: this.handleCondition(
                                  this.getErrorMessage(touched, errors, 'swiftCode'),
                                  '1px solid #F87171',""
                                )
                              }}
                              data-test-id="eventId"
                                id="swiftCode"
                                value={values.swiftCode}
                                type={"text"}
                                className="nameInputText"
                                placeholder={"Enter your swift code / IBAN"}
                                name={"swiftCode"}
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  handleChange(event);
                                  this.setState({sendAfterDisabled: false})
                                }}
                              />
                            </Box>
                            {this.getErrorMessage(touched, errors, "swiftCode")}
                          </Grid>
                          <Grid item xs={12} md={6} lg={6} xl={6}>
                            <Box className="inputBox">
                              <Typography className="label">
                              Name in Bank Account
                                <span className="star">*</span>
                              </Typography>
                              <input
                              style={{
                                border: this.handleCondition(
                                  this.getErrorMessage(touched, errors, 'accountName'),
                                  '1px solid #F87171',""
                                )
                              }}
                                type={"text"}
                                id="accountName"
                                data-test-id="accountName"
                                value={values.accountName}
                                className="nameInputText"
                                placeholder={"Enter your name in bank account"}
                                name={"accountName"}
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  const alpha = event.target.value.replace(/\d/g, '')
                                  setFieldValue("accountName", alpha)
                                  this.setState({sendAfterDisabled: false})
                                }}
                              />
                            </Box>
                            {this.getErrorMessage(touched, errors, "accountName")}
                          <Box className="accountInfo">
                                <img src={smlIcon} />
                                <Typography className="nameInfo">
                                  Exactly the English name as per the bank
                                  account register
                                </Typography>
                              </Box>
                          </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6} className="preferred-date">
                      <Box className="inputBox">
                        <Typography className="label">
                          Preferred Date
                          <span className="star">*</span>
                        </Typography>
                        <Box className="dateBox">
                          <input
                            type="text"
                            className="dateInput"
                            id="formattedDate"
                            name="formattedDate"
                            placeholder="Preferred date"
                            value={this.state.formattedDate}
                          />
                          <input
                            type="date"
                            min={minDate}
                            className="selectDate"
                            onChange={this.handleDateChange}
                            ref={this.state.inputRef}
                          />
                          <img
                            src={calendar}
                            onClick={this.handleCalendarOpen}
                            className="calendarIcon"
                          />
                        </Box>
                              <Typography className="exampleDate">
                                Example: 07-06-2024
                              </Typography>
                            </Box>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} xl={6}>
                            <Box className="inputBox2">
                              <Typography className="label">
                              Bank Account Number
                                <span className="star">*</span>
                              </Typography>
                              <input
                              style={{
                                border: this.handleCondition(
                                  this.getErrorMessage(touched, errors, 'accountNum'),
                                  '1px solid #F87171',""
                                )
                              }}
                                id="accountNum"
                                data-test-id="accountNum"
                                value={values.accountNum}
                                type={"text"}
                                className="nameInputText"
                                placeholder={"Enter your bank account number"}
                                name={"accountNum"}
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  const numOnly = event.target.value.replace(/\D/g, '')
                                  setFieldValue("accountNum", numOnly)
                                  this.setState({sendAfterDisabled: false})
                                }}
                              />
                            </Box>
                            {this.getErrorMessage(touched, errors, "accountNum")}
                          </Grid>
                          <Grid item xs={12} md={6} lg={6} xl={6}>
                            <Box className="inputBox">
                              <Typography className="label">
                                Bank Address
                                <span className="star">*</span>
                              </Typography>
                              <input
                              style={{
                                border: this.handleCondition(
                                  this.getErrorMessage(touched, errors, 'bankAddress'),
                                  '1px solid #F87171',""
                                )
                              }}
                                type={"text"}
                                data-test-id="bankAddress"
                                id="bankAddress"
                                value={values.bankAddress}
                                className="nameInputText"
                                placeholder={"Enter your bank address"}
                                name={"bankAddress"}
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  const alpha = event.target.value.replace(/\d/g, '')
                                  setFieldValue("bankAddress", alpha)
                                  this.setState({sendAfterDisabled: false})
                                }}
                              />
                            </Box>
                            {this.getErrorMessage(touched, errors, "bankAddress")}
                          </Grid>
                    </Grid>
                    <Box className="labelInputBox2">
                      <Button
                      data-test-id="setData"
                        type="submit"
                        className={this.handleCondition(
                          !Object.values(values).some(
                            (value) => value.length === 0
                          )
                            , "sendAfter"
                            , "sendBefore"
                        )}
                        disabled={Object.values(values).some((value) => value.length === 0)}
                      >
                        {this.state.sendAfterDisabled ? 'Please wait...' : 'Send Request'}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </this.MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle2 = {
  boxContainer: {
    width: "100%",
    position: "fixed" as "fixed",
    top: 0,
    right: 0,
    height: "100%",
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.6)",
  },
  backBox: {
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "#FFF",
    top: 0,
    zIndex: 31,
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  successContainer: {
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    top: 0,
    zIndex: "31",
    boxShadow:
      "0px 8px 32px 0px #0000000F,box-shadow: 0px 4px 8px 0px #00000008,box-shadow: 0px 25px 50px 0px #00000017",
  },
  textBox: {
    padding: "40px 40px 15px 40px",
  },
  successBox: {
    padding: "10px 40px 15px 40px",
  },
  iconBox: {
    width: "95%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingTop: "20px",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as "center",
    gap: "15px",
    padding: "20px",
    borderTop: "1px solid #E2E8F0",
  },
  surefor: {
    fontSize: "24px",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  backfor: {
    fontSize: "18px",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  viewBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    gap: "15px",
    padding: "20px",
    width: "calc(100% - 40px)",
    borderTop: "1px solid #E2E8F0",
  },
  viewStatus: {
    padding: "16px 0 16px 0",
    paddingTop: "16px",
    borderRadius: "8px",
    border: "1.5px solid #1B4FE4",
    background: "#1B4FE4",
    color: "#FFFFFF",
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "16px",
    // whiteSpace: "nowrap"
  },
  cancel: {
    padding: "16px 0 16px 0",
    borderRadius: "8px",
    border: "1.5px solid #1B4FE4",
    background: "#FFFFFF",
    color: "#1B4FE4",
    fontSize: "16px",
    fontFamily: "DIN Next LT Arabic Bold",
    width: "50%",
  },
  back: {
    padding: "16px 0 16px 0",
    paddingTop: "16px",
    borderRadius: "8px",
    border: "1.5px solid #1B4FE4",
    background: "#1B4FE4",
    color: "#FFFFFF",
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "16px",
    width: "50%",
  },
  linkColor: { color: "#FFFFFF", textDecoration: "none" },
};
// Customizable Area End
