Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.deactivateAccApiEndPoint = "bx_block_profile/profiles";
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.getUserDetailsApi = "account_block/accounts/get_profile"
exports.userDetailsUpdateApi = "account_block/accounts/update_profile"
exports.userProfileImageUpdateApi = "account_block/accounts/update_profile_image"
exports.updateLanguageEndpoint = "bx_block_language_options/languages/update_language"
exports.currencyListEndpoint = "bx_block_settings/settings/currency_listing"
exports.selectedCurrencyEndpoint = "bx_block_settings/settings/user_selected_currency"
exports.updateCurrencyEndpoint = "bx_block_settings/settings/update_currency"
exports.faqEndpoint = "bx_block_landingpage2/about_us"
exports.countryList = [
  { value: "afghanistan",
   label: "Afghanistan" },
  { value: "albania",
   label: "Albania" },
  { value: "algeria",
  label: "Algeria" },
  { value: "andorra",
   label: "Andorra" },
  { value: "angola",
   labe: "Angola" },
  { 
   value: "antigua and barbuda", label: "Antigua and Barbuda", },
  { value: "argentina", label: "Argentina" },
  { value: "armenia", label: "Armenia", },
  {  value: "australia", label: "Australia"},
  { label: "Austria",
   value: "austria" },
  { label: "Azerbaijan", value: "azerbaijan" },
  { value: "bahamas", label: "Bahamas" },
  { value: "bahrain",
   label: "Bahrain" },
  { label: "Bangladesh",
   value: "bangladesh" },
  { value: "barbados", label: "Barbados" },
  { value: "belarus", label: "Belarus"
    },
  { label: "Belgium",
   value: "belgium" },
  { label: "Belize",
   value: "belize" },
  { value: "benin", label: "Benin" },
  { label: "Bhutan",
   value: "bhutan" },
  { value: "bolivia", label: "Bolivia", },
  { label: "Bosnia and Herzegovina",
   value: "bosnia and herzegovina" },
  { value: "botswana",label: "Botswana", },
  { label: "Brazil",
   value: "brazil" },
  {value: "brunei" , label: "Brunei",
   },
  { label: "Bulgaria",
   value: "bulgaria" },
  { label: "Burkina Faso", value: "burkina faso" },
  { label: "Burundi", value: "burundi" },
  { label: "Cabo Verde", value: "cabo verde" },
  { label: "Cambodia",
   value: "cambodia" },
  { label: "Cameroon", value: "cameroon" },
  { label: "Canada",
   value: "canada" },
  { label: "Central African Republic",
   value: "central african republic" },
  { label: "Chad", value: "chad" },
  { label: "Chile", value: "chile" },
  { label: "China", value: "china" },
  { label: "Colombia",
   value: "colombia" },
  { label: "Comoros",
   value: "comoros" },
  { value: "congo (congo-brazzaville)",
    label: "Congo (Congo-Brazzaville)" },
  {value: "costa rica",
   label: "Costa Rica",
    },
  { label: "Croatia",
   value: "croatia" },
  { value: "djibouti", 
  label: "Djibouti" },
  { value: "dominica", 
  label: "Dominica" },
  { value: "dominican republic", label: "Dominican Republic" },
  { value: "east timor (timor-leste)", label: "East Timor (Timor-Leste)" },
  { value: "ecuador", label: "Ecuador" },
  { value: "egypt",
   label: "Egypt" },
  { value: "el salvador", label: "El Salvador" },
  { value: "equatorial guinea", label: "Equatorial Guinea" },
  { value: "eritrea", label: "Eritrea" },
  { value: "estonia", label: "Estonia" },
  { value: 'eswatini (fmr. "swaziland")',
  label: 'Eswatini (fmr. "Swaziland")'},
  { label: "Cuba", value: "cuba" },

  { label: "Cyprus", 
  value: "cyprus" },
  { label: "Czech Republic",
   value: "czech republic" },
  { value: "democratic republic of the congo",
    label: "Democratic Republic of the Congo" },
  { value: "denmark" ,
     label: "Denmark",  },
  { value: "ethiopia" ,
    label: "Ethiopia" },
  { value: "fiji" ,
    label: "Fiji"},
  { value: "finland" ,
    label: "Finland" },
  { value: "france" ,
    label: "France"},
  { value: "gabon",
     label: "Gabon" },
  { value: "gambia",
    label: "Gambia" },
  { 
    value: "georgia",
    label: "Georgia"},

  { value: "germany", 
    label: "Germany"},
  { value: "ghana",
    label: "Ghana" },

  { value: "greece", label: "Greece", },
  { value: "grenada", label: "Grenada",  },
  { value: "guatemala", label: "Guatemala", },

  { value: "guinea",  label: "Guinea",  },
  { label: "Guinea-Bissau", value: "guinea-bissau" },
  { label: "Guyana",
   value: "guyana" },
  { label: "Kenya",
   value: "kenya" },

  { label: "Kiribati",
   value: "kiribati" },
  { label: "Kosovo", 
  value: "kosovo" },
  { label: "Kuwait", 
  value: "kuwait" },
  { label: "Kyrgyzstan",
   value: "kyrgyzstan" },

  { label: "Laos", 
  value: "laos" },
  { label: "Latvia", 
  value: "latvia" },
  { 
   value: "lebanon",
    label: "Lebanon" },
  { label: "Lesotho",
   value: "lesotho" },
  { label: "Liberia",
   value: "liberia" },

  { value: "haiti", label: "Haiti" },
  { 
    value: "honduras",
    label: "Honduras", 
 },

  {
    value: "hungary",
     label: "Hungary",
   },

  { value: "iceland",
    label: "Iceland" },
  { value: "india",
    label: "India",},

  { value: "indonesia",
    label: "Indonesia", },
  { value: "iran",
    label: "Iran",
   },
  {  value: "iraq", label: "Iraq", },
  { value: "ireland", label: "Ireland" },

  { value: "israel",  label: "Israel", },

  { label: "Italy", value: "italy" },
  { value: "ivory coast", label: "Ivory Coast" },
  { label: "Jamaica", value: "jamaica" },

  { label: "Japan", value: "japan" },
  {  value: "jordan", label: "Jordan" },
  { label: "Kazakhstan", value: "kazakhstan" },

  { label: "Libya", value: "libya" },
  { label: "Liechtenstein", value: "liechtenstein" },
  { value: "lithuania", label: "Lithuania" },

  { label: "Luxembourg", value: "luxembourg" },
  { value: "madagascar", label: "Madagascar" },

  { value: "malawi", label: "Malawi" },
  { label: "Malaysia", value: "malaysia" },
  { value: "maldives", label: "Maldives" },
  { label: "Mali", value: "mali" },
  { label: "Malta", value: "malta" },
  { label: "Marshall Islands",
   value: "marshall islands" },
  { label: "Mauritania", value: "mauritania" },

  { value: "mauritius", label: "Mauritius" },
  { label: "Mexico", value: "mexico" },
  { label: "Micronesia", value: "micronesia" },

  { 
   value: "moldova", label: "Moldova" },
  { label: "Monaco", value: "monaco" },
  { value: "mongolia", label: "Mongolia" },

  { label: "Montenegro",
   value: "montenegro" },
  { label: "Morocco", 
  value: "morocco" },

  { value: "mozambique", label: "Mozambique" },
  { value: "myanmar (formerly burma)", label: "Myanmar (formerly Burma)", },

  {  value: "namibia", label: "Namibia" },
  {  value: "nauru", label: "Nauru" },

  { value: "nepal",  label: "Nepal" },
  { value: "netherlands",  label: "Netherlands", },

  {  value: "new zealand", label: "New Zealand" },
  {  value: "nicaragua", label: "Nicaragua", },
  {  value: "niger", label: "Niger", },

  { label: "Nigeria", value: "nigeria" },
  {  value: "north korea", label: "North Korea", },

  { value: "north macedonia", label: "North Macedonia" },
  { value: "norway",  label: "Norway", },
  {  value: "oman", label: "Oman", },

  {  value: "pakistan", label: "Pakistan" },
  { value: "palau",  label: "Palau" },
  { value: "palestine state", label: "Palestine State" },

  {  value: "panama", label: "Panama" },
  { value: "papua new guinea", label: "Papua New Guinea" },
  { value: "paraguay",  label: "Paraguay" },

  { value: "peru", label: "Peru" },
  { value: "philippines",  label: "Philippines" },
  {  value: "poland", label: "Poland" },

  { label: "Portugal", value: "portugal" },
  {  value: "qatar", label: "Qatar" },

  {  value: "romania", label: "Romania" },
  {  value: "russia", label: "Russia", },

  { value: "rwanda", label: "Rwanda",},
  { value: "saint kitts and nevis",
    label: "Saint Kitts and Nevis",
   },
  { value: "saint lucia",
    label: "Saint Lucia",  },
  { value: "saint vincent and the grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "samoa", label: "Samoa" },
  { value: "san marino",
    label: "San Marino", },

  { value: "sao tome and principe",
    label: "Sao Tome and Principe", },
  { value: "saudi arabia",
    label: "Saudi Arabia",
   },
  { value: "senegal",
    label: "Senegal" },
  { value: "serbia",
    label: "Serbia", 
 },
 
  { value: "slovakia",
    label: "Slovakia" },
    { value: "seychelles",
    label: "Seychelles" }, { value: "sierra leone",
     label: "Sierra Leone" }, { value: "singapore",
     label: "Singapore" },
  { value: "slovenia",
    label: "Slovenia" },  { value: "solomon islands",
    label: "Solomon Islands" },
  { value: "somalia",
    label: "Somalia"}, {value: "south africa", 
    label: "South Africa"},

  { value: "south korea",
    label: "South Korea" },{ value: "south sudan",label: "South Sudan" },
  { value: "spain",
    label: "Spain" },  { value: "sri lanka",
    label: "Sri Lanka" },
  { value: "sudan",
    label: "Sudan" },
  { value: "suriname",
    label: "Suriname" },  { value: "sweden", 
     label: "Sweden" },
  {value: "switzerland",
     label: "Switzerland" },
  { value: "syria" ,
    label: "Syria" }, { value: "taiwan", 
    label: "Taiwan" },
  { value: "tajikistan",
    label: "Tajikistan"},
  { value: "tanzania",
   label: "Tanzania" },
  { value: "thailand",
   label: "Thailand" },
  { value: "togo",
   label: "Togo" },

  { value: "tonga",
   label: "Tonga" },
  {
    value: "trinidad and tobago", 
     label: "Trinidad and Tobago",
  },
  { value: "tunisia",
   label: "Tunisia" },

  { label: "Turkey",
   value: "turkey" },
  { value: "turkmenistan", label: "Turkmenistan", },
  { value: "tuvalu",  label: "Tuvalu", },

  { label: "Uganda",
   value: "uganda" },
  { label: "Ukraine",
   value: "ukraine" },
  { value: "united arab emirates", label: "United Arab Emirates", },
  { value: "united kingdom", label: "United Kingdom" },
  { value: "united states of america",
    label: "United States of America",
  },
  {  value: "uruguay", label: "Uruguay" },
  { value: "uzbekistan", label: "Uzbekistan",
    },
  { label: "Vanuatu", value: "vanuatu" },

  {  value: "vatican city", label: "Vatican City", },
  { label: "Venezuela", value: "venezuela" },

  {  value: "vietnam", label: "Vietnam", },
  {  value: "yemen" , label: "Yemen",},
  { value: "zambia",  label: "Zambia", },

  { value: "zimbabwe",  label: "Zimbabwe", },
];
exports.idProof = [
  { label: "Passport", value: "Passport" },
  { label: "Birth certificate", value: "Birth certificate" },
  { label: "Driving license", value: "Driving license" },
  { label: "National ID", value: "National ID" },
];
exports.idProofAr = [
  { label: "جواز السفر", value: "Passport" },
  { label: "شهادة الميلاد", value: "Birth certificate" },
  { label: "رخصة القيادة", value: "Driving license" },
  { label: "الهوية الوطنية", value: "National ID" },
];
exports.countryCodeList = [
  { label: "+93", value: "+93" },
  { label: "+355", value: "+355" },
  { label: "+213", value: "+213" },
  { label: "+376", value: "+376" },
  { label: "+244", value: "+244" },
  { label: "+1-268", value: "+1-268" },
  { label: "+54", value: "+54" },
  { label: "+374", value: "+374" },
  { label: "+61", value: "+61" },
  { label: "+43", value: "+43" },
  { label: "+994", value: "+994" },
  { label: "+1-242", value: "+1-242" },
  { label: "+973", value: "+973" },
  { label: "+880", value: "+880" },
  { label: "+1-246", value: "+1-246" },
  { label: "+375", value: "+375" },
  { label: "+32", value: "+32" },
  { label: "+501", value: "+501" },
  { label: "+229", value: "+229" },
  { label: "+975", value: "+975" },
  { label: "+591", value: "+591" },
  { label: "+387", value: "+387" },
  { label: "+267", value: "+267" },
  { label: "+55", value: "+55" },
  { label: "+673", value: "+673" },
  { label: "+359", value: "+359" },
  { label: "+226", value: "+226" },
  { label: "+257", value: "+257" },
  { label: "+238", value: "+238" },
  { label: "+855", value: "+855" },
  { label: "+237", value: "+237" },
  { label: "+1", value: "+1" },
  { label: "+236", value: "+236" },
  { label: "+235", value: "+235" },
  { label: "+56", value: "+56" },
  { label: "+86", value: "+86" },
  { label: "+57", value: "+57" },
  { label: "+269", value: "+269" },
  { label: "+242", value: "+242" },
  { label: "+506", value: "+506" },
  { label: "+385", value: "+385" },
  { label: "+53", value: "+53" },
  { label: "+357", value: "+357" },
  { label: "+420", value: "+420" },
  { label: "+243", value: "+243" },
  { label: "+45", value: "+45" },
  { label: "+253", value: "+253" },
  { label: "+1-767", value: "+1-767" },
  { label: "+1-809", value: "+1-809" },
  { label: "+670", value: "+670" },
  { label: "+593", value: "+593" },
  { label: "+20", value: "+20" },
  { label: "+503", value: "+503" },
  { label: "+240", value: "+240" },
  { label: "+291", value: "+291" },
  { label: "+372", value: "+372" },
  { label: "+268", value: "+268" },
  { label: "+251", value: "+251" },
  { label: "+679", value: "+679" },
  { label: "+358", value: "+358" },
  { label: "+33", value: "+33" },
  { label: "+241", value: "+241" },
  { label: "+220", value: "+220" },
  { label: "+995", value: "+995" },
  { label: "+49", value: "+49" },
  { label: "+233", value: "+233" },
  { label: "+30", value: "+30" },
  { label: "+1-473", value: "+1-473" },
  { label: "+502", value: "+502" },
  { label: "+224", value: "+224" },
  { label: "+245", value: "+245" },
  { label: "+592", value: "+592" },
  { label: "+509", value: "+509" },
  { label: "+504", value: "+504" },
  { label: "+36", value: "+36" },
  { label: "+354", value: "+354" },
  { label: "+91", value: "+91" },
  { label: "+62", value: "+62" },
  { label: "+98", value: "+98" },
  { label: "+964", value: "+964" },
  { label: "+353", value: "+353" },
  { label: "+972", value: "+972" },
  { label: "+39", value: "+39" },
  { label: "+225", value: "+225" },
  { label: "+1-876", value: "+1-876" },
  { label: "+81", value: "+81" },
  { label: "+962", value: "+962" },
  { label: "+7", value: "+7" },
  { label: "+254", value: "+254" },
  { label: "+686", value: "+686" },
  { label: "+383", value: "+383" },
  { label: "+965", value: "+965" },
  { label: "+996", value: "+996" },
  { label: "+856", value: "+856" },
  { label: "+371", value: "+371" },
  { label: "+961", value: "+961" },
  { label: "+266", value: "+266" },
  { label: "+231", value: "+231" },
  { label: "+218", value: "+218" },
  { label: "+423", value: "+423" },
  { label: "+370", value: "+370" },
  { label: "+352", value: "+352" },
  { label: "+261", value: "+261" },
  { label: "+265", value: "+265" },
  { label: "+60", value: "+60" },
  { label: "+960", value: "+960" },
  { label: "+223", value: "+223" },
  { label: "+356", value: "+356" },
  { label: "+692", value: "+692" },
  { label: "+222", value: "+222" },
  { label: "+230", value: "+230" },
  { label: "+52", value: "+52" },
  { label: "+691", value: "+691" },
  { label: "+373", value: "+373" },
  { label: "+377", value: "+377" },
  { label: "+976", value: "+976" },
  { label: "+382", value: "+382" },
  { label: "+212", value: "+212" },
  { label: "+258", value: "+258" },
  { label: "+95", value: "+95" },
  { label: "+264", value: "+264" },
  { label: "+674", value: "+674" },
  { label: "+977", value: "+977" },
  { label: "+31", value: "+31" },
  { label: "+64", value: "+64" },
  { label: "+505", value: "+505" },
  { label: "+227", value: "+227" },
  { label: "+234", value: "+234" },
  { label: "+850", value: "+850" },
  { label: "+389", value: "+389" },
  { label: "+47", value: "+47" },
  { label: "+968", value: "+968" },
  { label: "+92", value: "+92" },
  { label: "+680", value: "+680" },
  { label: "+970", value: "+970" },
  { label: "+507", value: "+507" },
  { label: "+675", value: "+675" },
  { label: "+595", value: "+595" },
  { label: "+51", value: "+51" },
  { label: "+63", value: "+63" },
  { label: "+48", value: "+48" },
  { label: "+351", value: "+351" },
  { label: "+974", value: "+974" },
  { label: "+40", value: "+40" },
  { label: "+7", value: "+7" },
  { label: "+250", value: "+250" },
  { label: "+1-869", value: "+1-869" },
  { label: "+1-758", value: "+1-758" },
  { label: "+1-784", value: "+1-784" },
  { label: "+685", value: "+685" },
  { label: "+378", value: "+378" },
  { label: "+239", value: "+239" },
  { label: "+966", value: "+966" },
  { label: "+221", value: "+221" },
  { label: "+381", value: "+381" },
  { label: "+248", value: "+248" },
  { label: "+232", value: "+232" },
  { label: "+65", value: "+65" },
  { label: "+421", value: "+421" },
  { label: "+386", value: "+386" },
  { label: "+677", value: "+677" },
  { label: "+252", value: "+252" },
  { label: "+27", value: "+27" },
  { label: "+82", value: "+82" },
  { label: "+211", value: "+211" },
  { label: "+34", value: "+34" },
  { label: "+94", value: "+94" },
  { label: "+249", value: "+249" },
  { label: "+597", value: "+597" },
  { label: "+46", value: "+46" },
  { label: "+41", value: "+41" },
  { label: "+963", value: "+963" },
  { label: "+886", value: "+886" },
  { label: "+992", value: "+992" },
  { label: "+255", value: "+255" },
  { label: "+66", value: "+66" },
  { label: "+228", value: "+228" },
  { label: "+676", value: "+676" },
  { label: "+1-868", value: "+1-868" },
  { label: "+216", value: "+216" },
  { label: "+90", value: "+90" },
  { label: "+993", value: "+993" },
  { label: "+688", value: "+688" },
  { label: "+256", value: "+256" },
  { label: "+380", value: "+380" },
  { label: "+971", value: "+971" },
  { label: "+44", value: "+44" },
  { label: "+1", value: "+1" },
  { label: "+598", value: "+598" },
  { label: "+998", value: "+998" },
  { label: "+678", value: "+678" },
  { label: "+379", value: "+379" },
  { label: "+58", value: "+58" },
  { label: "+84", value: "+84" },
  { label: "+967", value: "+967" },
  { label: "+260", value: "+260" },
  { label: "+263", value: "+263" },
];
exports.investment = [{ label: "Cash management", value: "Investment" }];
exports.investmentAr = [{ label: "إدارة النقد", value: "Investment" }];
// Customizable Area End

