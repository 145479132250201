import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ApiRequest from "../../../components/src/ApiRequest.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { IPaginationData, TStatus } from "../../../blocks/multipageforms/src/MultipageFormsController.web";
import { formatDateToMonDayYear } from "../../../components/src/utils.web";
interface ITransactionStatement {
  requestId: string;
  requestDate: string;
  transactionAmount: string;
  transactionType: string;
  status: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  googleChartData: any;
  tranStatsList: ITransactionStatement[];
  sortBy: string;
  order: string;
  perPage: string;
  paginationData: IPaginationData,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      tranStatsList: [],
      sortBy: '',
      order: '',
      perPage: '9',
      paginationData: {
        currentPage: 1,
        nextPage: 0,
        prevPage: 0,
        totalPages: 0,
        currentCount: 0,
        totalCount: 0
      },
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallIds = {
      [this.apiCallIdTranStatsList]: this.getTranStatsListResponse,

      // Add more API call IDs and handlers as needed
    };
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apierror = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](apiResponse, apierror);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getTranStatsListRequest();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start

  // Api Call Ids
  apiCallIdTranStatsList: string = ""

  // Request functions
  getTranStatsListRequest = async () => {
    const {sortBy, order, paginationData, perPage} = this.state;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const params = new URLSearchParams();

    if (sortBy) params.append("sort_by", sortBy);
    if (order) params.append("order", order);
    if (paginationData.currentPage) params.append("page", paginationData?.currentPage.toString());
    if (perPage) params.append("per_page", perPage);

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.transactionStatementsListEndpoint}?${params.toString()}`,
      method: "GET",
    });

    this.apiCallIdTranStatsList = requestMessage.messageId;
    this.setState({tranStatsList: []})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  dayRavenue = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatisticAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dayAudience = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.audienceFollowersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Response functions
  getTranStatsListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const rows: ITransactionStatement[] = responseJson.data.map((item: any) => {
        const attributes = item.attributes;
        const formattedValue = (!attributes.request_id || attributes.request_id === "null") ? "N/A" : attributes.request_id
        return {
          requestDate: formatDateToMonDayYear(attributes.request_date),
          requestId: formattedValue,
          transactionAmount: `${attributes.currency_code} ${attributes.received_money.toFixed(2)}`,
          transactionType: attributes.transaction_type,
          status: attributes.status === 'accepted' ? 'completed' : attributes.status,
        };
      });
  
      const metaResponse = responseJson.meta;
  
      const pagination: IPaginationData = {
        currentPage: metaResponse?.current_page || 1,
        nextPage: metaResponse?.next_page || null,
        prevPage: metaResponse?.prev_page || null,
        totalPages: metaResponse?.total_pages || 1,
        currentCount: metaResponse?.current_count,
        totalCount: metaResponse?.total_count,
      };
  
      this.setState({ tranStatsList: rows, paginationData: pagination });
    }
  };

  // Internal functions
  stepColor = (status: TStatus) => {
    const colors: {[key: string]: string} = {
      pending: "#F59E0B",
      approved: '#10B981',
      completed: '#10B981',
      rejected: '#EF4444',
      submitted: '#10B981',
      'fill form': '#1B4FE4',
      'not started': '#F1F5F9'
    }

    return colors[status] || '#F1F5F9';
  }

  handleTransactionTypeCondition = (type: string, match: string, notMatch: string) => {
    return type === 'Deposit' ? match : notMatch;
  }

  handlePaginatioChange = (event: any, next: number) => {
    this.setState((prevState) => ({
      paginationData: {
        ...prevState.paginationData,
        currentPage: next
      },
      tranStatsList: [],
    }), () => this.getTranStatsListRequest())
  }

  handleSortingChange = (sortBy: string, order: string) => {
    this.setState({
      sortBy,
      order
    }, () => this.getTranStatsListRequest())
  }

  navigateTo = (endpoint: string) => {
    const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
    goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
    goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContractForm);
  }

  getSortText = (sortBy: string, order: string): string => {
    const statusMapping: { [key: string]: string } = {
      "accepted": 'completed_transactions',
      "rejected": 'rejected_transactions',
      "pending": 'pending_transactions'
    };
  
    if (sortBy === 'status' && order in statusMapping) {
      return statusMapping[order];
    }
  
    return 'recent_transactions';
  };
  

  // Customizable Area End
}
