export const logo = require("../assets/logo.png");
export const mobileImg = require("../assets/mobileImg.png");
export const aboutoneImg = require("../assets/about1.jpeg");
export const abouttwoImg = require("../assets/about2.jpeg");
export const aboutthreeImg = require("../assets/about3.jpeg");
export const leftIcon = require("../assets/about.svg");
export const rightIcon = require("../assets/about1.svg");
export const mailIcon = require("../assets/mail.svg");
export const callIcon = require("../assets/call.svg");
export const downIcon = require("../assets/downIcon.svg");
export const cardImg = require("../assets/cardImg.png");
export const copywrite = require("../assets/copywrite.svg");
export const facebook = require("../assets/facebook.svg");
export const twitter = require("../assets/twitter.svg");
export const instagram = require("../assets/instagram.svg");
export const youtube = require("../assets/youtube.svg");
export const logoWhite = require("../assets/logoWhite.png");
export const crossIcon = require("../assets/crossIcon.svg");
export const whitebg = require("../assets/WhiteLogo.png");
export const upIcon = require("../assets/button_icon.svg");
export const arIcon = require("../assets/button_.svg");