// Customizable Area Start
import React from "react";
import {
  createTheme,
  styled,
  Box,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import FeedbackController from "./FeedbackController";
import { crossIcon, defaultProfile, leftArrow, rightArrow } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import FBsuccessPopup from "./FBsuccessPopup.web";
import { withTranslation } from "react-i18next";
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  feedbackNav: any;
}
export default class Feedback extends FeedbackController {
  // Customizable Area Start
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .container": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      height: "calc(100% - 25px)",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0.32)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "25px",
    },
    "& .box": {
      [theme.breakpoints.down(598)]: {
        width: "calc(80% - 24px)",
        padding: "16px 24px",
      },
      width: "calc(80% - 96px)",
      height: "fit-content",
      maxHeight: "90%",
      overflow:"auto",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "16px 48px",
      "&::-webkit-scrollbar": {
        display: "none",
        },
    },
    "& .box2": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "12px",
      flexDirection: "column",
    },
    "& .shareBox": {
      display: "flex",
      gap: "12px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "100%",
    },
    "& .shareText": {
      [theme.breakpoints.down(598)]: {
        fontSize: "18px",
      },
      color: "#334155",
      fontSize: "30px",
      fontFamily: "DIN Next LT Arabic Bold",
      textAlign: "center",
    },
    "& .iconStyle": {
      width: "40px",
      height: "40px",
      fontWeight: 700,
    },
    "& .commentText": {
      [theme.breakpoints.down(598)]: {
        fontSize: "18px",
      },
      color: "#1E293B",
      fontSize: "20px",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .describeBox": {
      [theme.breakpoints.down(598)]: {
        fontSize: "16px",
      },
      width: "100%",
      height: "200px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      padding: "10px 16px",
      color: "#334155",
      fontSize: "18px",
      fontFamily: "DIN Next LT Arabic Regular",
      resize: "none",
    },
    "& .buttonBoxForFeedback": {
      [theme.breakpoints.down(598)]: {
        paddingTop: "16px",
      },
      width: "100%",
      display: "flex",
      gap: "16px",
    },
    "& .cancenStyle": {
      [theme.breakpoints.down(598)]: {
        fontSize: "16px",
      },
      color: "#1B4FE4",
      width: "50%",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      borderRadius: "8px",
      padding: "10px 16px",
      border: "1px solid #1B4FE4",
      background: "none",
    },
    "& .shareButton": {
      [theme.breakpoints.down(598)]: {
        display: "none",
      },
      width: "50%",
      color: "#FFFFFF",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      borderRadius: "8px",
      padding: "10px 16px",
      backgroundColor: "#1B4FE4",
      border: "none",
    },
    "& .shareButton2": {
      [theme.breakpoints.up(598)]: {
        display: "none",
      },
      [theme.breakpoints.down(598)]: {
        width: "50%",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Bold",
        borderRadius: "8px",
        padding: "10px 16px",
        backgroundColor: "#1B4FE4",
        border: "none",
      },
    },
    "& .reviewButtonBox": {
      [theme.breakpoints.down(598)]: {
        padding: "10px 0 10px 0",
      },
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    "& .userText": {
      [theme.breakpoints.down(598)]: {
        fontSize: "16px",
      },
      fontSize: "18px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#334155",
    },
    "& .seeText": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
      },
      color: "#1B4FE4",
      fontSize: "18px",
      fontFamily: "DIN Next LT Arabic Bold",
    },

    "& .nameBox": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .userName": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
      },
      color: "#0F172A",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .dateStyle": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
        fontFamily: "DIN Next LT Arabic Regular",
      },
      color: "#97999A",
      fontSize: "15px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .imageStyle": {
      [theme.breakpoints.down(598)]: {},
      width: "44px",
      height: "44px",
      borderRadius: "50%",
    },
    "& .allReveiws": {
      [theme.breakpoints.down(598)]: {
        gap: "8px",
      },
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "20px",
      width: "100%",
    },
    "& .nameSS": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
      gap: "2px",
    },
    "& .crossBox": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    "& .reviewBox": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "24px",
    },
    "& .allReviewsBox": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    "& .experienceBox": {
      [theme.breakpoints.down(598)]: {
        paddingTop: "15px",
      },
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "25px",
    },
    "& .seeBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      cursor:"pointer"
    },
    "& .describeText": {
      [theme.breakpoints.down(598)]: {
        color: "#334155",
        fontSize: "14px",
        fontFamily: "DIN Next LT Arabic Regular",
        paddingTop: "12px",
      },
      color: "#64748B",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
  }));

  render() {
    return (
      <this.MainWrapper>
        <ThemeProvider theme={theme}>
          {this.state.sendRequestPop && (
            <Box
              onClick={this.sendRequestPopup}
            >
              <FBsuccessPopup id="" navigation={""} i18n={this.props.i18n}></FBsuccessPopup>
            </Box>
          )}
          {this.state.successPopup && (
            <Box className="container">
              <Box className="box">
                <Box className="box2" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                  <Box className="crossBox">
                    <img
                      src={crossIcon}
                      className="iconStyle"
                      onClick={this.props.feedbackNav}
                    />
                  </Box>
                  <Box className="shareBox">
                    <Box className="experienceBox">
                      <Typography className="shareText">
                        {this.props.i18n?.t('shareExp')}
                      </Typography>
                    </Box>
                    <Typography className="commentText">{this.props.i18n?.t('comment')}</Typography>
                    <textarea
                      value={this.state.comment}
                      placeholder={this.props.i18n?.t('enterYourComment')}
                      className="describeBox"
                      data-test-id="descriptionArea"
                      onChange={(event) =>
                        this.setState({ comment: event.target.value })
                      }
                    ></textarea>
                    <Box className="buttonBoxForFeedback">
                      <button
                        className="cancenStyle"
                        onClick={this.props.feedbackNav}
                      >
                        {this.props.i18n?.t('cancelFeedback')}
                      </button>
                      <button
                        className="shareButton"
                        onClick={this.handleFeedbackPostApi}
                      >
                        {this.props.i18n?.t('shareExperience')}
                      </button>
                      <button
                        className="shareButton2"
                        onClick={this.handleFeedbackPostApi}
                      >
                        {this.props.i18n?.t('shareExperience')}
                      </button>
                    </Box>
                  </Box>
                </Box>
                <Box className="reviewBox" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                  <Box className="reviewButtonBox">
                    <Typography className="userText">{this.props.i18n?.t('userReviews')}</Typography>
                    <Box className="seeBox" onClick={this.props.reviewListNav}>
                      <Typography className="seeText">
                        {this.props.i18n?.t('seeAllReviews')}
                      </Typography>
                      <img src={this.props.i18n?.language === 'ar' ? leftArrow : rightArrow}/>
                    </Box>
                  </Box>
                  <Box className="allReviewsBox">
                    {this.state.reviewsList.map((item: any , index: number) => {
                      if(index === 0 || index === 1) {
                      return (
                        <Box className="allReveiws" key={index}>
                          <img
                            src={
                              `${item?.attributes?.account?.data?.attributes?.profile_image?.url || defaultProfile}`
                            }
                            className={"imageStyle"}
                          />
                          <Box className="nameSS">
                            <Box className="nameBox">
                              <Typography className="userName">
                                {`${item?.attributes?.account?.data?.attributes?.first_name || ""} ${
                                  item?.attributes?.account?.data?.attributes?.last_name || ""
                                }`}
                              </Typography>
                              <Typography className="dateStyle" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'ltr', 'ltr')}}>
                                {this.displayDate(item?.attributes?.created_at)}
                              </Typography>
                            </Box>
                            <Typography className="describeText">
                              {item?.attributes?.comment}
                            </Typography>
                          </Box>
                        </Box>
                      );
                      }
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </ThemeProvider>
      </this.MainWrapper>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const FeedbackWeb = withTranslation()(Feedback)
// Customizable Area End
