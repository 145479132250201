import React from "react";

// Customizable Area Start
import { Box, TextField, 
  Typography, Button, Card, 
  TableContainer, 
  Table, TableHead, TableCell, 
  TableRow, TableBody, 
  Divider} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { capitalizeFirstLetters } from "../../../components/src/utils.web";
import { Pagination } from "@material-ui/lab";
import { searchIcon } from "../../../blocks/multipageforms/src/assets";
import { TStatus } from "../../../blocks/multipageforms/src/MultipageFormsController.web";
import SortDropdown from "../../../components/src/Sort.web";
import { withTranslation } from "react-i18next";

interface ITransactionStatement {
  requestId: string;
  requestDate: string;
  transactionAmount: string;
  transactionType: string;
  status: string;
}
// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  MainWrapper = styled(Box)(({ theme }) => ({
    zIndex:-1,
    position:"absolute",
    width: "calc(100vw - 367px)",
    [theme.breakpoints.up(900)]:{
      zIndex:0,
      position:"inherit"
    },
    "& .container": {
        [theme.breakpoints.down(900)]: {
          width: "calc(100vw - 54px)",
          padding: "5px 10px 10px 3rem",
          position: "absolute",
          top: "0",
          zIndex: 99,
        },
        [theme.breakpoints.down(750)]: {

        },
        width: "calc(100vw - 367px - 2rem)",
        padding: "4rem 10px 10px 2rem",
        height: "calc(100vh - 3.5rem)",
        overflow: "scroll",
      },
    "& .statusContentFlexBox": {
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
        [theme.breakpoints.down(750)]: {
            flexDirection: 'row'
        }
    },
    "& .iconLineFlexBox": {
        display: "flex",
        alignItems: "center",
        gap: '20px',
        marginBottom: '24px',
        [theme.breakpoints.down(750)]: {
            flexDirection: 'column'
        }
    },
    "& .horizontalDivider": {
        [theme.breakpoints.down(750)]: {
            height: '40px',
            width: '6px'
        },
        height: '6px',
        borderRadius: '5px',
        width: '100%',
        margin: '20px 0p',
    },
    "& .iconButton": {
        padding: theme.spacing(1),
    },
    "& .icon": {
        width: 24,
        height: 24,
    },
    "& .buttonStyle": {
        textTransform: 'none',
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '18px'
    },
    "& .stepFont": {
        color: '#64748B',
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px'
    },
    "& .titleFont": {
        color: '#1E293B',
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        marginBottom: '16px'
    },
    "& .headingText": {
        [theme.breakpoints.down(750)]: {
            fontSize: '18px',
            lineHeight: '30px',
        },
        overflow: 'hidden',
        color: '#1E293B',
        textOverflow: 'ellipsis',
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: '30px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '40px',
        letterSpacing: '-0.15px',
    },
    "& .searchBarContainer": {

        [theme.breakpoints.down(750)]: {
            display: "none",
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid #64748B',
    },
    "& .searchBarInput": {
        width: '100%',
    },
    "& .mobileSearch": {
        [theme.breakpoints.down(750)]: {
          width: "30px",
          height: "30px",
        },
        [theme.breakpoints.up(750)]: {
          display: "none",
        },
      },
    "& .customButton": {
        [theme.breakpoints.down(900)]: {
            padding: "14px 8px"
        },
        [theme.breakpoints.down(750)]: {
            padding: '7px 5px !important',
            "& .MuiButton-startIcon": {
                marginRight: '0'
            }
        },
        display: 'flex',
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background: '#1B4FE4',
    },
    "& .buttonText": {
        [theme.breakpoints.down(1451)]: {
            fontSize: "14px",
        },
        [theme.breakpoints.down(1370)]: {
            fontSize: "13px",
        },
        [theme.breakpoints.down(1262)]: {
            fontSize: "12px",
        },
        [theme.breakpoints.down(900)]: {
            fontSize: "12px",
        },
        [theme.breakpoints.down(750)]: {
            display: 'none',
          },
        color: '#FFF',
        textTransform: "none",
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        flexWrap: "nowrap",
    },
    "& .newRequestText": {
        color: '#334155',
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '26px',
        marginBottom: '24px',
    },
    "& .transactionIdText": {
        color: '#334155',
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        marginBottom: '24px'
    },
    "& .trasactionValueText": {
        color: "#1E293B",
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '26px',
    },
    "& .table": {
        minWidth: 650,
    },
    "& .depositHistoryText": {
        [theme.breakpoints.down(750)]:{
            fontSize: '18px'
        },
        color: '#1E293B',
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '32px',  
        letterSpacing: '-0.12px',
        marginBottom: '8px',
        direction: this.props.i18n.language === 'ar' ? 'rtl' : 'ltr'
    },
    "& .paginationRoot": {
        '& .MuiPaginationItem-root': {
            borderRadius: 4,
            marginRight: '16px',
            fontFamily: 'DIN Next LT Arabic Bold',
            fontWeight: 700,
        },
        '& .MuiPaginationItem-page:not(.Mui-selected)': {
            border: '1px solid #94A3B8', 
            color: '#94A3B8', 
        },
    },
    "& .headingStyle": {
        [theme.breakpoints.down(750)]: {
            fontSize: '16px'
        },
        color: '#1E293B',
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '26px',
      },
    "& .tableContentText": {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
    },
    "& .tableBtn": {
        textTransform: 'none',
        width: '120px',
        padding: '12px 16px',
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
    },
    "& .amountText": {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
    },
    "& .headingFlexBox": {
        display: 'flex',
        justifyContent: 'space-between',
        width: "100%",
        marginBottom: '78',
        flexDirection: this.props.i18n.language === 'ar' ? 'row-reverse' : 'row'
    },
    "& .depositHistoryContainer": {
        marginTop: '96px',
    },
    "& .tableDeskTopView": {
        display: 'block',
        [theme.breakpoints.down(750)]: {
            display: 'none'
        },
    },
    "& .tableMobileView": {
        display: 'none',
        [theme.breakpoints.down(750)]: {
            display: 'block'
        }
    },
    "& .addIconStyle": {
        marginTop: '3px', color: "#fff",
        [theme.breakpoints.down(750)]: {
            fontSize: '24px'
        }
    },
    "& .responsiveCardContainer": {
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
    },
    "& .responsiveCard": {
        flexBasis: '600px',
        flexGrow: 1,
        minWidth: '300px',
        width: '100%',
        padding: 3,
        borderRadius: 1,
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            flexDirection: 'column',
          },
    },
    "& .cardContent": {
        flexGrow: 1,
        padding: '8px 12px'
    },
    "& .stageCard": {
        flex: '1 1 300px',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            flexDirection: 'column',
            width: '100%'
          },
    },
    "& .stageCardContent": {
        padding: '24px',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            flexDirection: 'column',
          },
    },
    "& .boxContainer": {
          [theme.breakpoints.down(1183)]: {
            top: 200,
          },
          [theme.breakpoints.down(975)]: {
            top: 220,
          },
          [theme.breakpoints.down(900)]: {
            top: 65,
            position: "fixed" as "fixed",
          },
        // width: "100%",
        position: "absolute" as "absolute",
        top: 180,
        right: 35,
        // height: "100%",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center" as "center",
        alignItems: "center",
        borderRadius:"8px"
        // background: "rgba(0, 0, 0, 0.6)",
      },
      "& .backBox": {
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
        backgroundColor: "#FFF",
        top: 0,
        zIndex: 31,
        boxShadow:
          "0px 25px 50px 0px rgba(0, 0, 0, 0.15), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
    
      },
      "& .popupBox": {
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        top: 0,
        boxShadow: "0px 2px 4px 0px #00000026",
        width: "fit-content",
        position:"relative" as "relative"
      },
      "& .profitData": {
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Light",
        padding: "12px 16px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "#3C3E49",
        textTransform:"none"
      },
      "& .active": {
        backgroundColor: "#1B4FE4",
        color: "#F8FAFC",
        fontSize:"16px",
        fontFamily:"DIN Next LT Arabic Regular"
      },
      "& .profitDatas2":{
        backgroundColor:"#1B4FE4",
        width:"100%",
        textTransform:"none",
        color:"#FFFFFF",
        fontSize:"16px",
        fontFamily:"DIN Next LT Arabic Regular"
      },
      "& .flexSpaceBetween": {
        [theme.breakpoints.down(750)]: {
          alignItems: 'flex-start'
        },
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'flex-end',
        margin: '15px 0',
        flexDirection: this.props.i18n.language === 'ar' ? 'row-reverse' : 'row'
      },
      "& .requestButton": {
        [theme.breakpoints.down(900)]: {
          padding: "14px 8px",
        },
        [theme.breakpoints.down(750)]: {
          display: "none",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        backgroundColor: "#1B4FE4",
        gap: "8px",
        padding: "12px 8px",
        borderRadius: "8px",
        flexWrap: "nowrap",
      },
      "& .buttonOne": {
        [theme.breakpoints.down(1451)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(1370)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(1262)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(900)]: {
          fontSize: "12px",
        },
        color: "#FFFFFF",
        fontWeight: 700,
        backgroundColor: "#1B4FE4",
        fontSize: "16px",
        border: "none",
        fontFamily: "DIN Next LT Arabic Bold",
        flexWrap: "nowrap",
      },
      "& .plusStyle": {
        [theme.breakpoints.down(1276)]: { width: "23px", height: "23px" },
        [theme.breakpoints.down(1217)]: { width: "20px", height: "20px" },
        [theme.breakpoints.down(1120)]: { width: "17px", height: "17px" },
        [theme.breakpoints.down(1021)]: { width: "15px", height: "15px" },
        [theme.breakpoints.down(900)]: {},
      },
      "& .ButtonPlus": {
        [theme.breakpoints.down(750)]: {
          width: "40px",
          height: "40px",
        },
        [theme.breakpoints.up(750)]: {
          display: "none",
        },
      },
      "& .sortingPlaceholder": {
        [theme.breakpoints.down(750)]: {
          fontSize: '16px',
        },
        color: '#94A3B8',
        textOverflow: 'ellipsis',
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '26px',
      }
}));

renderMobileCard(row: ITransactionStatement, index: number) {
  const { i18n } = this.props;
  const color = this.stepColor(row.status as TStatus);
  const labels = ['request_date', 'request_id', 'transaction_amount', 'transaction_type', 'status'];

  return (
    <Card elevation={0} key={index} className="responsiveCard">
      <Box
        className="cardContent"
        style={{
          backgroundColor: index % 2 === 0 ? '#F1F5F9' : 'none',
        }}
      >
        {labels.map((label, idx) => (
          <Box
            key={idx}
            sx={{
              display: 'flex',
              flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2
            }}
          >
            <Typography className="headingStyle">{i18n.t(label)}</Typography>
            <Typography className="tableContentText">
              {this.renderMobileCardContent(label, row, color)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  );
}

renderMobileCardContent(label: string, row: ITransactionStatement, color: string) {
  switch (label) {
    case 'request_date':
      return row.requestDate;
    case 'request_id':
      return row.requestId;
    case 'transaction_amount':
      return (
        <Typography
          style={{ color: this.handleTransactionTypeCondition(row.transactionType, 'green', 'red') }}
          className="amountText"
        >
          {this.handleTransactionTypeCondition(row.transactionType, '+', '-')} {row.transactionAmount}
        </Typography>
      );
    case 'transaction_type':
      return (
        <Typography
          style={{ color: this.handleTransactionTypeCondition(row.transactionType, 'green', 'red') }}
          className="amountText"
        >
          {row.transactionType}
        </Typography>
      );
    case 'status':
      return (
        <Button
          style={{ color, backgroundColor: `${color}30` }}
          className="tableBtn"
        >
          {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
        </Button>
      );
  }
}

renderPagination() {
  const { paginationData } = this.state;
  const { i18n } = this.props;

  if (paginationData.totalPages <= 1) return null;

  return (
    <Box marginTop={2} display="flex" justifyContent={i18n.language === 'ar' ? 'flex-start' : "flex-end"}>
      <Pagination
        data-test-id="pagination"
        count={paginationData.totalPages}
        page={paginationData.currentPage}
        onChange={(event, page) => this.handlePaginatioChange(event, page)}
        color="primary"
        size="small"
        siblingCount={0}
        boundaryCount={1}
        className="paginationRoot"
      />
    </Box>
  );
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ display: "flex", width: "fit-content" }}>
                <Box>
                    <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
                </Box>
                <this.MainWrapper>
                    <Box className="container">
                        <Box className="headingFlexBox">
                            <Typography className="headingText">{this.props.i18n.t('transaction_statement_heading')}</Typography>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', alignItems: 'center' }}>
                                    <Box className="searchBarContainer">
                                        <TextField
                                            className="searchBarInput"
                                            placeholder="Type to search"
                                            InputProps={{
                                                startAdornment: (<img src={searchIcon} alt={'icon'} className="icon" />),
                                                style: {
                                                    padding: '5px',
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Box>
                                    <img src={searchIcon} className="mobileSearch" />
                            </Box>
                        </Box>
                         <Box className="depositHistoryContainer">
                            <Divider />
                            <Box className="flexSpaceBetween">
                              <Box>
                              <Typography className="depositHistoryText">{this.props.i18n.t(this.getSortText(this.state.sortBy, this.state.order))}</Typography>
                              <Typography className="sortingPlaceholder">{this.state.sortBy === 'status' ? '' : this.props.i18n.t('sorting_description')}</Typography>
                              </Box>
                              <SortDropdown handleSortingChange={this.handleSortingChange}/>
                            </Box>
                            <TableContainer className="tableDeskTopView">
                                <Table className="table" aria-label="simple table">
                                    <TableHead style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                                      {
                                        this.props.i18n.language === 'ar' ? (
                                          <TableRow>
                                            <TableCell className="headingStyle" align="center">{this.props.i18n.t('status')}</TableCell>
                                            <TableCell className="headingStyle" align="center">{this.props.i18n.t('transaction_type')}</TableCell>
                                            <TableCell className="headingStyle" align="center">{this.props.i18n.t('transaction_amount')}</TableCell>
                                            <TableCell className="headingStyle" align="center">{this.props.i18n.t('request_id')}</TableCell>
                                            <TableCell className="headingStyle">{this.props.i18n.t('request_date')}</TableCell>
                                          </TableRow>
                                        ) : (
                                          <TableRow>
                                            <TableCell className="headingStyle">{this.props.i18n.t('request_date')}</TableCell>
                                            <TableCell className="headingStyle" align="center">{this.props.i18n.t('request_id')}</TableCell>
                                            <TableCell className="headingStyle" align="center">{this.props.i18n.t('transaction_amount')}</TableCell>
                                            <TableCell className="headingStyle" align="center">{this.props.i18n.t('transaction_type')}</TableCell>
                                            <TableCell className="headingStyle" align="center">{this.props.i18n.t('status')}</TableCell>
                                          </TableRow>
                                        )
                                      }
                                    </TableHead>
                                    <TableBody>
                    {this.state.tranStatsList.length === 0 && <Typography style={{ width: '100%', textAlign: 'center' }} className="tableContentText">{this.props.i18n.t('noDataFound')}</Typography>}
                    {this.state.tranStatsList?.map((row) => {
                      const color = this.stepColor(row?.status as TStatus);
                      const transactionTypeColor = this.handleTransactionTypeCondition(row.transactionType, 'green', 'red');
                      const transactionAmount = `${this.handleTransactionTypeCondition(row.transactionType, '+', '-')} ${row.transactionAmount}`;

                      return (
                        <TableRow key={row.requestId}>
                          {this.props.i18n.language === 'ar' ? (
                            <>
                              <TableCell className="stepFont" align="center">
                                <Button style={{ color, backgroundColor: `${color}30` }} className="tableBtn">
                                  {capitalizeFirstLetters(row.status)}
                                </Button>
                              </TableCell>
                              <TableCell className="amountText" align="center" style={{ color: transactionTypeColor }}>
                                {row.transactionType}
                              </TableCell>
                              <TableCell className="amountText" align="center" style={{ color: transactionTypeColor }}>
                                {transactionAmount}
                              </TableCell>
                              <TableCell className="tableContentText" align="center">
                                {row.requestId}
                              </TableCell>
                              <TableCell className="tableContentText" component="th" scope="row">
                                {row.requestDate}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell className="tableContentText" component="th" scope="row">
                                {row.requestDate}
                              </TableCell>
                              <TableCell className="tableContentText" align="center">
                                {row.requestId}
                              </TableCell>
                              <TableCell className="amountText" align="center" style={{ color: transactionTypeColor }}>
                                {transactionAmount}
                              </TableCell>
                              <TableCell className="amountText" align="center" style={{ color: transactionTypeColor }}>
                                {row.transactionType}
                              </TableCell>
                              <TableCell className="stepFont" align="center">
                                <Button style={{ color, backgroundColor: `${color}30` }} className="tableBtn">
                                  {capitalizeFirstLetters(row.status)}
                                </Button>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                                        
                                    </TableBody>
                                </Table>
                                {this.renderPagination()}
                                <Divider  style={{margin: '15px 0'}}/>
                            </TableContainer>
                            <Box className="tableMobileView">
                                <Box
                                    className="responsiveCardContainer"
                                >
                                  {this.state.tranStatsList.length === 0 ? (
                                    <Typography style={{ width: '100%', textAlign: 'center' }} className="tableContentText">
                                      {this.props.i18n.t('noDataFound')}
                                    </Typography>
                                  ) : (
                                    this.state.tranStatsList.map(this.renderMobileCard.bind(this))
                                  )}
                                </Box>
                                {this.renderPagination()}
                            </Box>
                        </Box>                        

                    </Box>
                </this.MainWrapper>
            </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

export const TransactionStatementWeb = withTranslation()(VisualAnalytics)
// Customizable Area End
