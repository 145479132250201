import React, { createRef, useRef } from 'react';
import { Box, Typography, Modal, TextField, FormControl, Select, MenuItem, Button, Checkbox } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { calendar, cross2 } from '../../blocks/multipageforms/src/assets';
import { formatDateForAcntLtr } from './utils.web';
import {  Error } from "@material-ui/icons"

interface AccountLetterFormProps {
    open: boolean;
    accountId: string;
    accountType: string;
    dateOpened: string;
    checkedRememberMe: boolean;
    errors: {
        accountId: boolean;
        accountType: boolean;
        dateOpened: boolean;
    };
    handleFormInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => void;
    handleRememberMe: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (boolean: boolean) => void;
    handleSaveDraft: () => void;
    handleClose: () => void;
    continueDraftId: string;
}


const useStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        [theme.breakpoints.down(598)]: {
            gap: "16px",
            width: "100%",
            padding: "0px",
        },
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        overflowY: "auto",
        maxHeight: "55vh"
    },
    labelInputBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "4px",
    },
    heading: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "24px",
        color: "#334155",
        textAlign: 'center',
        [theme.breakpoints.down(598)]: {
            fontSize: "18px",
        },
    },
    label: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "16px",
        color: "#334155",
    },
    nameInputText: {
        [theme.breakpoints.down(598)]: {
            width: "100%",
        },
        borderRadius: "8px",
        width: "100%",
        fontFamily: "DIN Next LT Arabic Light",
        fontWeight: 400,
        fontSize: "16px",
        "& .MuiInputBase-input.Mui-disabled": {
            color: '#000',
        },
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "inherit !important",
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: 0
        }
    },
    dateInput: {
        width: "100%",
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Regular",
        outline: "none",
        border: "none",
    },
    dateBox: {
        width: "calc(100% - 24px)",
        display: "flex",
        padding: "11px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Regular",
        outline: "none",
    },
    boxContainer: {
        width: "100%",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        height: "100vh",
        zIndex: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.6)",
        overflow: "auto",
    },
    modalContainer: {
        [theme.breakpoints.down(938)]: {
            width: "calc(55% - 48px)",
        },
        [theme.breakpoints.down(853)]: {
            width: "calc(60% - 48px)",
        },
        [theme.breakpoints.down(782)]: {
            width: "calc(65% - 48px)",
        },
        [theme.breakpoints.down(722)]: {
            width: "calc(70% - 48px)",
        },
        [theme.breakpoints.down(670)]: {
            width: "calc(75% - 48px)",
        },
        [theme.breakpoints.down(626)]: { width: "calc(80% - 48px)" },
        [theme.breakpoints.down(598)]: { 
            width: "80%",
            maxHeight: "90vh",
        },
        width: "100%",
        maxWidth: '500px',
        maxHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3)
    },
    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        width: '100%'
    },
    onlyFlex: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '15px'
    },
    saveBtn: {
        width: '100%',
        color: '#1B4FE4',
        fontFamily: 'DIN Next LT Arabic Regular',
        border: '1px solid #1B4FE4',
        borderRadius: '8px',
        padding: '10px 16px',
        textTransform: 'initial'
    },
    submitBtn: {
        width: '100%',
        color: '#fff',
        fontFamily: 'DIN Next LT Arabic Regular',
        border: '1px solid #1B4FE4',
        backgroundColor: '#1B4FE4',
        borderRadius: '8px',
        padding: '10px 16px',
        textTransform: 'initial',
        "&:hover": {
            backgroundColor: '#1B4FE4',
            color: '#fff',
        }
    },
    crossStyle: {
        [theme.breakpoints.down(598)]: {
            width: "40px",
            height: "40px"
        },
    },
    endRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    errorContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
      },
      errorIcon: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
        fontSize: '24px',
      },
    errorTxt: {
        color: '#f44336',
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "14px",
    },
    selectDate: {
        width: 0,
        border: "none",
      },
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        marginLeft: '-9px',
        border:'1px solid #64748B',
        borderRadius: 6,
        width: 20,
        height: 20,
        [theme.breakpoints.down(776)]:{
          width: 15,
          height: 15,
  
        },
        [theme.breakpoints.down('xs')]:{
          width: 20,
          height: 20,
  
        },
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#FFFFFF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
      },
      checkedIcon: {
        backgroundColor: '#1B4FE4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 20,
          height: 20,
          [theme.breakpoints.down(776)]:{
            width: 15,
            height: 15,
    
          },
          [theme.breakpoints.down('xs')]:{
            width: 20,
            height: 20,
    
          },
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#106ba3',
        },
      },
      bal: {}
      
}));

interface HTMLInputElementWithPicker extends HTMLInputElement {
    showPicker: () => void;
  }

const AccountLetterForm = ({
    open,
    accountId,
    accountType,
    dateOpened,
    checkedRememberMe, 
    errors,
    handleFormInputChange,
    handleRememberMe,
    handleSaveDraft,
    handleSubmit,
    handleClose,
    continueDraftId = ''
}: AccountLetterFormProps) => {
    const classes = useStyles();
    const dateInputRef = useRef<HTMLInputElementWithPicker>(null);

    const handleCalendarOpen = () => {
        if (dateInputRef.current) {
            dateInputRef.current.showPicker();
        }
    };

    const saveOrCancelFunc = continueDraftId ? handleClose : handleSaveDraft;

    return (
        <Modal open={open}>
            <Box
                className={classes.boxContainer}
            >
                <Box
                    bgcolor="white"
                    borderRadius="8px"
                    boxShadow={3}
                    width="100%"
                    className={classes.modalContainer}
                >
                    <Box className={classes.endRight}>
                        <img src={cross2} onClick={handleClose} className={classes.crossStyle} />
                    </Box>
                    <Typography className={classes.heading} gutterBottom>
                        Generate My Account Confirmation Letter
                    </Typography>
                    <Box className={classes.formContainer}>
                        <Box className={classes.labelInputBox} mb={2}>
                            <Typography className={classes.label}>
                                Account Unique ID
                            </Typography>
                            <TextField
                                // error={errors.accountId}
                                // helperText={errors.accountId ? 'Please enter your account unique id' : ''}
                                placeholder="Account Unique ID"
                                name="accountId"
                                value={accountId}
                                onChange={handleFormInputChange}
                                fullWidth
                                className={classes.nameInputText}
                                variant="outlined"
                            />
                            {
                                errors.accountId && (
                                    <div className={classes.errorContainer}>
                                        <Error className={classes.errorIcon} />
                                        <Typography className={classes.errorTxt}>
                                            Please enter your account unique id
                                        </Typography>
                                    </div>
                                )
                            }
                        </Box>
                        <Box className={`${classes.nameInputText} ${classes.labelInputBox}`} mb={2}>
                            <Typography className={classes.label}>
                                Account Type
                            </Typography>
                            <FormControl fullWidth 
                            // error={errors.accountType}
                            >
                                <Select
                                    name="accountType"
                                    value={accountType || "none"}
                                    className={classes.nameInputText}
                                    variant="outlined"
                                    onChange={handleFormInputChange}
                                >
                                    <MenuItem value="none" disabled>Account Type</MenuItem>
                                    <MenuItem value="individual">Individual</MenuItem>
                                    <MenuItem value="business">Business</MenuItem>
                                </Select>
                                {
                                    errors.accountType && (
                                        <div className={classes.errorContainer}>
                                            <Error className={classes.errorIcon} />
                                            <Typography className={classes.errorTxt}>
                                                Please choose your account type
                                            </Typography>
                                        </div>
                                    )
                                }
                            </FormControl>
                        </Box>
                        <Box className={classes.labelInputBox} mb={2}>
                            <Typography className={classes.label}>
                                Date
                            </Typography>
                            <Box className={classes.dateBox} display="flex" alignItems="center">
                                <input
                                    type="text"
                                    className={classes.dateInput}
                                    id="formattedDate"
                                    name="dateOpened"
                                    placeholder={`${formatDateForAcntLtr(new Date())}`}
                                    value={dateOpened}
                                    onChange={handleFormInputChange}
                                />
                                <input
                                    type="date"
                                    name="dateOpened"
                                    // min={new Date().toISOString().split('T')[0]}
                                    className={classes.selectDate}
                                    onChange={handleFormInputChange}
                                    ref={dateInputRef}
                                />
                                <img
                                    src={calendar}
                                    onClick={handleCalendarOpen}
                                    className="calendarIcon"
                                    alt="calendar"
                                />
                            </Box>
                            {
                                errors.dateOpened && (
                                    <div className={classes.errorContainer}>
                                        <Error className={classes.errorIcon} />
                                        <Typography className={classes.errorTxt}>
                                            {dateOpened ? 'Please enter a valid date in DD-MM-YYYY format' : 'Please choose the date'}
                                        </Typography>
                                    </div>
                                )
                            }
                        </Box>
                        <Box className={classes.onlyFlex}>
                            <Checkbox
                                checked={checkedRememberMe}
                                disableRipple
                                style={{paddingLeft: '0'}}
                                color="primary"
                                onChange={handleRememberMe}
                            />
                            <Typography className={classes.label}>
                                Remember Me
                            </Typography>
                        </Box>
                        <Box className={classes.flexBox}>
                            <Button onClick={saveOrCancelFunc} className={classes.saveBtn}>
                                {continueDraftId ? 'Cancel' : 'Save'}
                            </Button>
                            <Button onClick={() => handleSubmit(false)} className={classes.submitBtn}>
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal >
    );
};

export default AccountLetterForm;
