export const searchIcon = require("../assets/searchIcon.svg");
export const bellIcon = require("../assets/Bellicon.png");
export const bell = require("../assets/Bell.png");
export const PlusIcon = require("../assets/PlusIcon.svg");
export const lineImg = require("../assets/wawe.svg");
export const wave2 = require("../assets/wave2.svg");
export const wave3 = require("../assets/wave3.svg");
export const wave6 = require("../assets/wave6.svg");
export const arrow = require("../assets/arrow.png");
export const drop = require("../assets/drop.svg");
export const fourline = require("../assets/fourline.svg");
export const activity = require("../assets/activity.svg");
export const calendar = require("../assets/calendar.svg");
export const lineOne = require("../assets/line1.svg");
export const lineTwo = require("../assets/line2.svg");
export const lineThree = require("../assets/line3.svg");
export const lineFour = require("../assets/line4.svg");
export const summarySvg = require("../assets/123.svg");
export const plusButton = require("../assets/PlusButton.svg");
export const searchButton = require("../assets/SearchB.svg");
export const threeDots = require("../assets/threeDot.svg");
export const message = require("../assets/message.png");
export const downloadIcon = require("../assets/download.png");
export const optionIcon = require("../assets/optionIcon.png");
export const rejected = require("../assets/rejectedIcon.png");
export const pending = require("../assets/pendingIcon.png");
export const deleteIcon = require("../assets/delete.png");
export const markAsRead = require("../assets/marked.png")

