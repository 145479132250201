// Customizable Area Start
import React from "react";
import { 
    Box, Typography, 
    Button, Card, 
    TextField, IconButton, 
    TableContainer, Table,
    TableHead, TableRow,
    TableCell, TableBody
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
    createTheme,
    styled
} from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import MultipageFormsControllerWeb, {
    Props,
    TStatus,
} from "./MultipageFormsController.web";
import { capitalizeFirstLetters } from "../../../components/src/utils.web";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { searchIcon } from "./assets";
// Customizable Area End

export default class DepositRequest extends MultipageFormsControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
    MainWrapper = styled(Box)(({ theme }) => ({
        zIndex:-1,
        position:"absolute",
        [theme.breakpoints.up(900)]:{
          zIndex:0,
          position:"inherit",
        },
        "& .container": {
            [theme.breakpoints.down(900)]: {
                width: "calc(100vw - 4rem)",
                padding: "5px 10px 10px 3rem",
              position: "absolute",
              top: "0",
              zIndex: 99,
            },
            [theme.breakpoints.down(750)]: {

            },
            width: "calc(100vw - 315px - 5rem)",
            padding: "4rem 10px 10px 2rem",
            height:"calc(100vh - 4rem - 10px)",
            overflowY:"scroll",
            "&::-webkit-scrollbar": {
                display: "none",
                },
          },
        "& .statusContentFlexBox": {
            display: 'flex',
            gap: 10,
            flexDirection: 'column',
            [theme.breakpoints.down(750)]: {
                flexDirection: 'row'
            }
        },
        "& .iconLineFlexBox": {
            display: "flex",
            alignItems: "center",
            gap: '20px',
            marginBottom: '24px',
            [theme.breakpoints.down(750)]: {
                flexDirection: 'column'
            }
        },
        "& .horizontalDivider": {
            [theme.breakpoints.down(750)]: {
                height: '40px',
                width: '6px'
            },
            height: '6px',
            borderRadius: '5px',
            width: '100%',
            margin: '20px 0p',
        },
        "& .iconButton": {
            padding: theme.spacing(1),
        },
        "& .icon": {
            width: 24,
            height: 24,
        },
        "& .buttonStyle": {
            textTransform: 'none',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '18px'
        },
        "& .stepFont": {
            color: '#64748B',
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px'
        },
        "& .titleFont": {
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            marginBottom: '16px'
        },
        "& .headingText": {
            [theme.breakpoints.down(750)]: {
                fontSize: '18px',
                lineHeight: '30px',
            },
            overflow: 'hidden',
            color: '#1E293B',
            textOverflow: 'ellipsis',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '40px',
            letterSpacing: '-0.15px',
        },
        "& .searchBarContainer": {

            [theme.breakpoints.down(750)]: {
                display: "none",
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '8px',
            borderRadius: '8px',
            border: '1px solid #64748B',
        },
        "& .searchBarInput": {
            width: '100%',
        },
        "& .mobileSearch": {
            [theme.breakpoints.down(750)]: {
              width: "30px",
              height: "30px",
            },
            [theme.breakpoints.up(750)]: {
              display: "none",
            },
          },
        "& .customButton": {
            [theme.breakpoints.down(900)]: {
                padding: "14px 8px"
            },
            [theme.breakpoints.down(750)]: {
                padding: '7px 5px !important',
                "& .MuiButton-startIcon": {
                    marginRight: '0'
                }
            },
            display: 'flex',
            padding: '10px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '8px',
            background: '#1B4FE4',
        },
        "& .buttonText": {
            [theme.breakpoints.down(1451)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(1370)]: {
                fontSize: "13px",
            },
            [theme.breakpoints.down(1262)]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down(900)]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down(750)]: {
                display: 'none',
              },
            color: '#FFF',
            textTransform: "none",
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            flexWrap: "nowrap",
        },
        "& .newRequestText": {
            color: '#334155',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '26px',
            marginBottom: '24px',
        },
        "& .transactionIdText": {
            color: '#334155',
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            marginBottom: '24px'
        },
        "& .table": {
            minWidth: 650,
        },
        "& .depositHistoryText": {
            [theme.breakpoints.down(750)]:{
                fontSize: '18px'
            },
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '32px',  
            letterSpacing: '-0.12px',
            marginBottom: '8px'
        },
        "& .paginationRoot": {
            '& .MuiPaginationItem-root': {
                borderRadius: 4,
                marginRight: '16px'
            },
            '& .MuiPaginationItem-page:not(.Mui-selected)': {
                border: '1px solid #94A3B8', 
                color: '#94A3B8', 
            },
        },
        "& .headingStyle": {
            [theme.breakpoints.down(750)]: {
                fontSize: '16px'
            },
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '26px',
          },
        "& .tableContentText": {
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        "& .tableBtn": {
            textTransform: 'none',
            width: '120px',
            padding: '12px 16px',
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        "& .amountText": {
            color: '#F59E0B',
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        "& .headingFlexBox": {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '78',
        },
        "& .depositHistoryContainer": {
            marginTop: '96px',
        },
        "& .tableDeskTopView": {
            display: 'block',
            [theme.breakpoints.down(750)]: {
                display: 'none'
            },
        },
        "& .tableMobileView": {
            display: 'none',
            [theme.breakpoints.down(750)]: {
                display: 'block'
            }
        },
        "& .addIconStyle": {
            marginTop: '3px', color: "#fff",
            [theme.breakpoints.down(750)]: {
                fontSize: '24px'
            }
        },
        "& .responsiveCardContainer": {
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
        },
        "& .responsiveCard": {
            flexBasis: '600px',
            flexGrow: 1,
            minWidth: '220px',
            width: '100%',
            padding: 3,
            borderRadius: 1,
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
              },
        },
        "& .cardContent": {
            flexGrow: 1,
            padding: '8px 12px'
        },
        "& .stageCard": {
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
                width: '100%'
              },
        },
        "& .stageCardContent": {
            flexBasis: '400px',
            flexGrow: 1,
            minWidth: '160px',
            padding: '24px',
            borderRadius: '8px',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
              },
        },
        "& .trasactionValueText": {
            color: "#1E293B",
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '26px',
        },



    }));
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { depositStages, depositRequestList, paginationData, showButton } = this.state;
        return (
            <div style={{ display: "flex", width: "fit-content" }}>
                <Box>
                    <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
                </Box>
                <this.MainWrapper>
                    <Box className="container">
                        <Box className="headingFlexBox">
                            <Typography className="headingText">Deposit Request</Typography>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', alignItems: 'center' }}>
                                    <Box className="searchBarContainer">
                                        <TextField
                                            className="searchBarInput"
                                            placeholder="Type to search"
                                            InputProps={{
                                                startAdornment: (<img src={searchIcon} alt={'icon'} className="icon" />),
                                                style: {
                                                    padding: '5px',
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Box>
                                    <img src={searchIcon} className="mobileSearch" />
                                
                                {
                                    showButton && (
                                        <Button
                                            data-test-id="showAddBtn"
                                            onClick={() => this.addNewDepositRequest()}
                                            startIcon={<AddCircleOutlineIcon
                                                className="addIconStyle" />}
                                            className="customButton">
                                            <span className="buttonText">New Deposit Request</span>
                                        </Button>
                                    )
                                }
                            </Box>
                        </Box>
                        {
                            depositStages.length > 0 && (
                                <Box>
                                    <Typography className="newRequestText">New Request Progress</Typography>
                                    <Typography className="transactionIdText">Request ID:<span className="trasactionValueText">{this.state.depositRequestId}</span></Typography>
                                    <Box className="responsiveCardContainer">
                                        {
                                            depositStages.map((item, index) => {
                                                const customStyle: any = {

                                                };
                                                const customBorderStyle: any = {}
                                                let isPrevStageCompleted = this.isPrevStageCompleted(index);
                                                let color = !isPrevStageCompleted ? '#64748B' : this.stepColor(item.status);
                                                let border = item.status !== item.successStatus && isPrevStageCompleted ? `2px solid ${color}` : 'none'
                                                const isButtonEnabled = item.status === "fill form" && isPrevStageCompleted;
                                                const navigationMap: { [key: string]: string } = {
                                                    'Step 1': 'CustomForm',
                                                    'Step 3': 'DepositForm'
                                                };

                                                const navigationLink = navigationMap[item.title];
                                                if (!isPrevStageCompleted) {
                                                    customBorderStyle.border = '1px solid gray'
                                                    customBorderStyle.background = "#fff"
                                                }
                                                if (isButtonEnabled) {
                                                    customStyle.color = '#fff'
                                                    customStyle.background = color
                                                }
                                                return (
                                                    <Card style={{ border }} className="stageCard" elevation={0}>
                                                        <Box className="stageCardContent">
                                                            <Box className="statusContentFlexBox">
                                                                <Box className="iconLineFlexBox">
                                                                    <IconButton style={{ backgroundColor: color, ...customBorderStyle }} className="iconButton">
                                                                        <img src={this.getIconForStages(index)} alt={'icon'} className="icon" />
                                                                    </IconButton>
                                                                    {index < 3 && <Box style={{ backgroundColor: color }} className="horizontalDivider" />}
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="stepFont">{item.title}</Typography>
                                                                    <Typography className="titleFont">{item.description}</Typography>
                                                                    <Button
                                                                        data-test-id="formFillBtn"
                                                                        onClick={isButtonEnabled ? () => this.navigateTo(navigationLink) : undefined}
                                                                        style={{ color: color, backgroundColor: `${color}30`, ...customStyle }}
                                                                        className="buttonStyle"
                                                                    >
                                                                        {capitalizeFirstLetters(item.status)}
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Box>
                                </Box>
                            )
                        }
                        <Box className="depositHistoryContainer">
                            <Typography className="depositHistoryText">Deposit Request History</Typography>
                            <TableContainer className="tableDeskTopView">
                                <Table className="table" aria-label="simple table">
                                    <TableHead style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <TableRow>
                                            <TableCell className="headingStyle">Request Date</TableCell>
                                            <TableCell className="headingStyle" align="center">Request ID</TableCell>
                                            <TableCell className="headingStyle" align="center">Money Deposited</TableCell>
                                            <TableCell className="headingStyle" align="center">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {depositRequestList.length === 0 && <Typography style={{width: '100%', textAlign: 'center'}} className="tableContentText">No data found</Typography>}
                                        {depositRequestList?.map((row) => {
                                            const color = this.stepColor(row?.status as TStatus)
                                            return (
                                           
                                                <TableRow key={row.transactionId}>
                                                    <TableCell className="tableContentText" component="th" scope="row">
                                                        {row.date}
                                                    </TableCell>
                                                    <TableCell className="tableContentText" align="center">{row.transactionId}</TableCell>
                                                    <TableCell className="amountText" align="center">{row.amount}</TableCell>
                                                    <TableCell className="stepFont" align="center">
                                                    <Button style={{color, backgroundColor: `${color}30`}} className="tableBtn">{capitalizeFirstLetters(row.status)}</Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        {
                                            paginationData.totalPages > 1 && (
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Pagination
                                                                data-test-id="pagination"
                                                                count={paginationData.totalPages}
                                                                page={paginationData.currentPage}
                                                                onChange={(event, page) => this.handlePaginatioChange(event, page)}
                                                                color="primary"
                                                                size="small"
                                                                className="paginationRoot"
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box className="tableMobileView">
                                <Box
                                    className="responsiveCardContainer"
                                >
                                    {depositRequestList.length === 0 && <Typography style={{width: '100%', textAlign: 'center'}} className="tableContentText">No data found</Typography>}
                                    {depositRequestList?.map((row, index) => {
                                        const color = this.stepColor(row?.status as TStatus)
                                        return (
                                            <Card elevation={0} key={index} className="responsiveCard">
                                                <Box
                                                    className="cardContent"
                                                    style={{
                                                        backgroundColor: (index % 2 === 0) ? '#F1F5F9': 'none',
                                                    }}
                                                >
                                                    {['Request Date', 'Request ID', 'Money Deposited', 'Status'].map((label, idx) => (
                                                        <Box
                                                            key={idx}
                                                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                                                        >
                                                            <Typography className="headingStyle">{label}</Typography>
                                                            <Typography className="tableContentText">
                                                                {label === 'Request Date' && row.date}
                                                                {label === 'Request ID' && row.transactionId}
                                                                {label === 'Money Deposited' && (<Typography className="amountText">{row.amount}</Typography>)}
                                                                {label === 'Status' && (
                                                                    <Button
                                                                        style={{ color, backgroundColor: `${color}30` }}
                                                                        className="tableBtn"
                                                                    >
                                                                        {capitalizeFirstLetters(row.status)}
                                                                    </Button>
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Card>
                                        );
                                    })}
                                </Box>
                                {
                                    paginationData.totalPages > 1 && (
                                        <Box marginTop={2} display="flex" justifyContent="flex-end">
                                            <Pagination
                                                data-test-id="pagination"
                                                count={paginationData.totalPages}
                                                page={paginationData.currentPage}
                                                onChange={(event, page) => this.handlePaginatioChange(event, page)}
                                                color="primary"
                                                size="small"
                                                className="paginationRoot"
                                            />
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                        

                    </Box>
                </this.MainWrapper>
            </div>
        )
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End
