"use client"

import React from 'react'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { 
  Dialog, 
  DialogContent, 
  makeStyles, 
  Theme, 
  createStyles 
} from "@material-ui/core"

interface IFileOrFolder {
  url?: string
  type?: string
  name?: string
}

interface FilePreviewProps {
  file: IFileOrFolder | null
  isOpen: boolean
  onClose: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        maxWidth: '80vw',
        width: '100%',
        maxHeight: '90vh',
        height: '100%',
      },
    },
    content: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      padding: 0,
    },
    '@global': {
      '.react-doc-viewer': {
        '& .react-doc-viewer-toolbar': {
          justifyContent: 'flex-end !important',
          backgroundColor: 'rgba(255, 255, 255, 0.9) !important',
          backdropFilter: 'blur(5px)',
          '& > *:not(:nth-child(n+6):nth-child(-n+8))': {
            display: 'none !important',
          },
        },
      },
    },
  })
)

export default function FilePreview({ file, isOpen, onClose }: FilePreviewProps) {
  const classes = useStyles()

  if (!file?.url) return null

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      className={classes.dialog}
      maxWidth={false}
    >
      <DialogContent className={classes.content}>
        <DocViewer
          documents={[{ uri: file.url, fileType: file.type }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100%" }}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false
            }
          }}
        />
      </DialogContent>
    </Dialog>
  )
}