import React, { useRef } from 'react';
import { Popover, Button, Typography, makeStyles, Theme } from '@material-ui/core';
import { ICurrency } from '../../blocks/user-profile-basic/src/UserProfileBasicController';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

interface CurrencyPopupProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  currencyList: ICurrency[];
  selectedCurrency: ICurrency | null;
  onClose: () => void;
  onSelectCurrency: (currencyCode: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    padding: 0,
    width: '200px',
    maxWidth: '90vw',
    [theme.breakpoints.down('xs')]: {
      width: '180px',
    },
  },
  popoverContent: {
    maxHeight: '300px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    justifyContent: 'flex-start',
    width: '100%',
    textAlign: 'left',
    textTransform: 'none',
    transition: 'background-color 0.3s, color 0.3s',
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  selectedButton: {
    backgroundColor: '#1B4FE4',
    borderRadius: 'inherit',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& .currencySymbolCircle': {
      backgroundColor: 'black',
      borderColor: 'white',
    },
    '& .currencySymbol': {
      color: 'white',
    },
  },
  currencySymbolCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: '2px solid black',
    marginRight: theme.spacing(2),
    overflow: 'hidden',
  },
  currencySymbol: {
    color: 'black',
    fontSize: '14px',
    lineHeight: 1,
    textAlign: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  currencyName: {
    width: 'calc(100% - 44px)', // 28px circle + 16px margin
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CurrencyPopup: React.FC<CurrencyPopupProps> = ({
  open,
  anchorEl,
  currencyList,
  selectedCurrency,
  onClose,
  onSelectCurrency,
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const isArabic = i18n?.language === 'ar';
  const popoverContentRef = useRef<HTMLDivElement>(null);

  const getFontSize = (symbol: string) => {
    if (symbol.length <= 2) return '14px';
    if (symbol.length === 3) return '12px';
    return '10px';
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isArabic ? 'right' : 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: isArabic ? 'right' : 'left',
      }}
      classes={{
        paper: classes.popover,
      }}
      marginThreshold={16}
    >
      <div className={classes.popoverContent} ref={popoverContentRef as any}>
        {currencyList.length > 0 ? (
          currencyList.map((currency, index) => (
            <Button
              key={index}
              onClick={() => {
                onSelectCurrency(currency.currency_code);
                if (popoverContentRef.current) {
                  popoverContentRef.current.scrollTop = 0;
                }
              }}
              className={`${classes.button} ${
                selectedCurrency?.currency_name === currency.currency_name ? classes.selectedButton : ''
              }`}
            >
              <Box className={classes.currencySymbolCircle}>
                <Typography variant="body2" component="span" className={classes.currencySymbol} style={{ fontSize: getFontSize(currency.currency_symbol) }}>
                  {currency.currency_symbol}
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.currencyName}>
                {currency.currency_name}
              </Typography>
            </Button>
          ))
        ) : (
          <Typography variant="body2" style={{ padding: 8 }}>
            No data found
          </Typography>
        )}
      </div>
    </Popover>
  );
};

export default CurrencyPopup;